import moment from "moment";
import {
  FIELD_VALIDATION_ERRORS,
  emptyRichTextHTML,
} from "constants/constants";
import isEmail from "validator/lib/isEmail";
import libPhoneNumber from "google-libphonenumber";
import isNumeric from "validator/lib/isNumeric";
import difference from "lodash.difference";

export type ValidationResult =
  | { valid: true }
  | { valid: false; errMessage: string };

export enum ValidatorType {
  Required = "required",
  RequiredRichText = "requiredRichText",
  ValidEmail = "validEmail",
  ValidPhoneNumber = "validPhoneNumber",
  NullablePhoneNumber = "nullablePhoneNumber",
  ValidPassword = "validPassword",
  ValidConfirmPassword = "validConfirmPassword",
  ValidDate = "validDate",
  ValidOptionalNumber = "validOptionalNumber",
  Contains = "contains",
  ValidText = "validText",
  ValidDateMaxDateToday = "validDateMaxDateToday",
  ValidDateMinDateToday = "validDateMinDateToday",
  PositiveNumber = "positiveNumber",
  PositiveNumberOrZero = "positiveNumberOrZero",
  PositiveNumberOrZeroOrEmptyValue = "positiveNumberOrZeroOrEmptyValue",
  NonEmptyArray = "nonEmptyArray",
  ValidPastDate = "validPastDate",
  ValidOption = "validOption",
  ValidOptionOrEmpty = "validOptionOrEmpty",
}

export type DataValidators = {
  [key: string]: {
    validators: ValidatorType[];
    getData?: () => any;
  };
};

export const isPresent = (
  data: string | boolean | number | object | null | undefined
): ValidationResult => {
  if (typeof data === "boolean") {
    return { valid: true };
  }

  if (typeof data === "number") {
    return { valid: true };
  }

  if (!data) {
    return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.RequiredField };
  }
  return { valid: true };
};

export const isRichTextPresent = (data: string): ValidationResult => {
  if (typeof data !== "string" || !data) {
    return {
      valid: false,
      errMessage: FIELD_VALIDATION_ERRORS.InvalidField,
    };
  }

  return data === emptyRichTextHTML
    ? {
        valid: false,
        errMessage: FIELD_VALIDATION_ERRORS.InvalidField,
      }
    : { valid: true };
};

export const isValidEmail = (email: string): ValidationResult => {
  if (isEmail(email)) {
    return { valid: true };
  }
  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

export const isValidPassword = (password: string): ValidationResult => {
  if (password.length >= 8) {
    return { valid: true };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

export const isValidDate = (date: string): ValidationResult => {
  const dateObj = moment(date, "YYYY-MM-DD");
  if (dateObj.isValid()) {
    return { valid: true };
  }
  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

export const isValidPastDate = (date: string): ValidationResult => {
  const dateValidationResult = isValidDate(date);
  const invalidResult = {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.InvalidPastDate,
  };

  if (!dateValidationResult.valid) {
    return dateValidationResult;
  } else {
    const dateObj = moment(date, "YYYY-MM-DD").toDate().getTime();
    if (dateObj >= Date.now()) {
      return invalidResult;
    }
    return { valid: true };
  }
};

export const isValidDateMaxDateToday = (date: string): ValidationResult => {
  const dateObj = moment(date, "YYYY-MM-DD");
  const today = moment(new Date());
  const errMsg = {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.InvalidField,
  };

  if (dateObj.isValid()) {
    return dateObj.unix() <= today.unix() ? { valid: true } : errMsg;
  }
  return errMsg;
};

export const isValidDateMinDateToday = (date: string): ValidationResult => {
  const dateObj = moment(date, "YYYY-MM-DD");
  const today = moment(new Date());
  const errMsg = {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.InvalidField,
  };

  if (dateObj.isValid()) {
    return dateObj.diff(today, "days") >= 0
      ? { valid: true }
      : {
          valid: false,
          errMessage: FIELD_VALIDATION_ERRORS.InvalidFutureDate,
        };
  }
  return errMsg;
};

export const isValidOptionalNumber = (
  value?: string | number | null
): ValidationResult => {
  if (!value) {
    return { valid: true };
  }

  const isValid = isNumeric(value.toString());
  if (isValid) {
    return { valid: true };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.NotANumber };
};

export const isValidConfirmPassword = (
  password: string,
  confirmPassword: string
): ValidationResult => {
  if (password === confirmPassword) {
    return { valid: true };
  }
  return {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.PasswordsNotMatching,
  };
};

export const isValidOption = (
  optionId: string,
  optionIds: string[]
): ValidationResult => {
  if (optionIds.includes(optionId)) {
    return { valid: true };
  }
  return {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.OptionNotValid,
  };
};

export const isValidOptionOrEmpty = (
  optionId: string,
  optionIds: string[]
): ValidationResult => {
  if (optionId === null || optionId === undefined) {
    return { valid: true };
  }

  return isValidOption(optionId, optionIds);
};

export const isValidPhoneNumber = (phoneNr: string): ValidationResult => {
  const phoneNumberUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

  try {
    if (phoneNumberUtil.isValidNumber(phoneNumberUtil.parse(phoneNr))) {
      return { valid: true };
    }
  } catch (error) {
    const errMsg = (error as Error).message;
    return { valid: false, errMessage: errMsg };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

export const isNullablePhoneNumber = (
  phoneNr?: string | null
): ValidationResult => {
  if (!phoneNr) {
    return { valid: true };
  }

  return isValidPhoneNumber(phoneNr);
};

export const isValidPositiveNumber = (
  data: string | boolean | number | object | null | undefined
): ValidationResult => {
  if (typeof data === "number") {
    if (data > 0) {
      return { valid: true };
    }
  } else if (typeof data === "string") {
    const parsedValue = parseFloat(data);
    if (parsedValue > 0) {
      return { valid: true };
    }
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.PositiveNumber };
};

export const isValidPositiveNumberOrZero = (
  data: string | boolean | number | object | null | undefined
): ValidationResult => {
  if (typeof data === "number") {
    if (data >= 0) {
      return { valid: true };
    }
  } else if (typeof data === "string") {
    const parsedValue = parseFloat(data);
    if (parsedValue >= 0) {
      return { valid: true };
    }
  }

  return {
    valid: false,
    errMessage: FIELD_VALIDATION_ERRORS.PositiveNumberOrZero,
  };
};

export const isValidPositiveNumberOrZeroOrEmptyValue = (
  data: string | boolean | number | object | null | undefined
): ValidationResult => {
  if (!data) {
    return { valid: true };
  } else {
    return isValidPositiveNumberOrZero(data);
  }
};

const stringContains = (
  value: string,
  stringsToContain: string[]
): ValidationResult => {
  const isValid = stringsToContain
    .map((stringToContain) => value.indexOf(stringToContain) >= 0)
    .reduce((acc, stringContained) => acc && stringContained, true);

  if (isValid) {
    return { valid: true };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

const isValidText = (value: string, regex: RegExp): ValidationResult => {
  const isValid = regex.test(value);
  if (isValid) {
    return {
      valid: true,
    };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.InvalidField };
};

const isNonEmptyArray = (value: string): ValidationResult => {
  const isValid = Array.isArray(value) && !!value.length;
  if (isValid) {
    return {
      valid: true,
    };
  }

  return { valid: false, errMessage: FIELD_VALIDATION_ERRORS.NonEmptyArray };
};

const executeValidator = (value: any, validator: ValidatorType) => {
  switch (validator) {
    case ValidatorType.Required:
      return isPresent(value);
    case ValidatorType.ValidEmail:
      return isValidEmail(value);
    case ValidatorType.ValidPhoneNumber:
      return isValidPhoneNumber(value);
    case ValidatorType.ValidPassword:
      return isValidPassword(value);
    case ValidatorType.ValidConfirmPassword:
      return isValidConfirmPassword(value[0], value[1]);
    case ValidatorType.ValidDate:
      return isValidDate(value);
    case ValidatorType.ValidOptionalNumber:
      return isValidOptionalNumber(value);
    case ValidatorType.Contains:
      return stringContains(value[0], value[1]);
    case ValidatorType.ValidText:
      return isValidText(value[0], value[1]);
    case ValidatorType.RequiredRichText:
      return isRichTextPresent(value);
    case ValidatorType.ValidDateMaxDateToday:
      return isValidDateMaxDateToday(value);
    case ValidatorType.PositiveNumber:
      return isValidPositiveNumber(value);
    case ValidatorType.PositiveNumberOrZero:
      return isValidPositiveNumberOrZero(value);
    case ValidatorType.PositiveNumberOrZeroOrEmptyValue:
      return isValidPositiveNumberOrZeroOrEmptyValue(value);
    case ValidatorType.NonEmptyArray:
      return isNonEmptyArray(value);
    case ValidatorType.ValidPastDate:
      return isValidPastDate(value);
    case ValidatorType.NullablePhoneNumber:
      return isNullablePhoneNumber(value);
    case ValidatorType.ValidOption:
      return isValidOption(value[0], value[1]);
    case ValidatorType.ValidOptionOrEmpty:
      return isValidOptionOrEmpty(value[0], value[1]);
    case ValidatorType.ValidDateMinDateToday:
      return isValidDateMinDateToday(value);
  }
};

export const validateData = (
  data: any,
  dataValidators: DataValidators
): { valid: true } | { valid: false; errors: { [key: string]: string } } => {
  const objectKeys = Object.keys(data);
  const validatorsKeys = Object.keys(dataValidators);
  const result: { [key: string]: string } = {};

  const missingFields = difference(validatorsKeys, objectKeys);
  if (missingFields.length) {
    missingFields.forEach((field) => {
      result[field] = FIELD_VALIDATION_ERRORS.RequiredField;
    });
  }

  for (let i = 0; i < objectKeys.length; i++) {
    const key = objectKeys[i];
    if (dataValidators[key]) {
      for (let j = 0; j < dataValidators[key].validators.length; j++) {
        const curValidator = dataValidators[key].validators[j];
        const dataToValidate = dataValidators[key].getData
          ? dataValidators[key].getData?.()
          : data[key];
        const validationResult = executeValidator(dataToValidate, curValidator);
        if (!validationResult.valid) {
          result[key] = validationResult.errMessage;
          break;
        }
      }
    }
  }

  if (Object.keys(result).length) {
    return { valid: false, errors: result };
  }
  return { valid: true };
};
