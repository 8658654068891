import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { DeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/DeleteProductItemDraftConfirmModal";
import { useDeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/useDeleteProductItemDraftConfirmModal";
import { NewProductItemHeader } from "containers/Projects/components/Header/NewProductItemHeader/NewProductItemHeader";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { EWInstructionAction } from "containers/Projects/Projects.decl";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  AuthorizationWorkflow,
  EarlyWarningItem,
  EditDraftEarlyWarningItemInput,
  ItemDataInput,
  ProductSchema,
  ProductType,
  SendEarlyWarningItemInput,
} from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { ProductItemDraftConfirmModal } from "../../components/ProductItemDraftConfirmModal";
import { NewEWInstructionItemConfirmModal } from "./components/NewEarlyWarningItemConfirmModal/NewEWInstructionItemConfirmModal";
import { CommonTabs, snackbarAutoHideDuration } from "constants/constants";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useSnackbar } from "notistack";
import { doesSchemaValuesContainEffect } from "./NewEarlyWarning.utils";
import { useDefaultEffectValues } from "./hooks/useDefaultEffectValues";
import { InstructionProvideQuotationExtraDataType } from "containers/Projects/components/CompEvents/CompEventActionModal/components/NotifyAndRequestQuotationCEAction/InstructionProvideQuotationExtraDataForm/InstructionProvideQuotationExtraDataForm";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useNewEarlyWarning } from "./hooks/useNewEarlyWarning";

type SaveDraftContext = {
  isCancel?: boolean;
};

export const NewEarlyWarning = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authWorkflowTriggered = useRef<AuthorizationWorkflow>();
  const { contractId, productInstanceId } = useParams();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const navigateBack = useNavigateBack();
  const { enqueueSnackbar } = useSnackbar();

  const [newlyCreatedEWId, setNewlyCreatedEWId] = useState<string>();
  const [newlyCreatedDraftEWId, setNewlyCreatedDraftEWId] = useState<string>();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [ewAction, setEWAction] = useState<EWInstructionAction>();
  const [draftModalVisible, setDraftModalVisible] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const saveDraftContext = useRef<SaveDraftContext>({});
  const [isFormPristine, setIsFormPristine] = useState(true);
  const isEditingDraft = !!draftId;

  const [schemaValues, setSchemaValues] = useState<ItemDataInput>({
    sections: [],
  });
  const [schemaValid, setSchemaValid] = useState<boolean>();
  const [enhancedAttachments, setEnhancedAttachments] = useState<
    EnhancedAttachment[]
  >([]);
  const attachmentsToAdd = useRef<AttachmentInput[]>();

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    attachmentsToAdd.current = attachmentsUpdated;
    setIsFormPristine(false);

    if (draftId) {
      await upsertDraftEW({
        id: draftId,
        data: schemaValues,
        attachments: attachmentsUpdated,
      });
    }
  };

  const {
    allAttachments,
    attachmentsLoading,
    addAttachments,
    removeAttachment,
    updateAttachment,
    unloadLocalAttachments,
    downloadAttachment,
  } = useAttachments(enhancedAttachments, handleAttachmentsUpdated);

  const { effectDefaultValues } = useDefaultEffectValues();

  // When sending/recording an EW, preview contains empty spaces if Effect values are not set
  // Therefore, when the user doesn't touch the Effect field at all, send default value (false for all options)
  const schemaValuesWithDefaultEffectValues = useMemo(() => {
    return doesSchemaValuesContainEffect(schemaValues)
      ? schemaValues
      : effectDefaultValues
      ? {
          sections: [...schemaValues.sections, effectDefaultValues],
        }
      : schemaValues;
  }, [schemaValues, effectDefaultValues]);

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const {
    showPrompt: showBeforeNavigateAwayModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(!isFormPristine);

  const {
    modalVisible: deleteDraftEWModalVisible,
    setModalVisible: setDeleteDraftEWModalVisible,
    closeModal: closeDeleteDraftEWModal,
    handleCancel: handleDeleteDraftEWModalCancel,
    handleConfirm: handleDeleteDraftEWModalConfirm,
    loading: deleteDraftEWLoading,
  } = useDeleteProductItemDraftConfirmModal();

  const {
    fetchDraftEWData,
    draftEWData,
    getDraftEWDataLoading,
    sendEarlyWarning,
    sendEarlyWarningLoading,
    recordEarlyWarning,
    recordEarlyWarningLoading,
    addEWItemDraft,
    addEWItemDraftLoading,
    editEWItemDraft,
    editEWItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
  } = useNewEarlyWarning();

  const triggerDeleteDraftEW = () => {
    setDeleteDraftEWModalVisible(true);
  };

  const handleDeleteDraftEW = async () => {
    await handleDeleteDraftEWModalConfirm(draftId!, ProductType.EarlyWarnings);
    navigate(NewAppPaths.authorized.Projects.path, {
      state: { autoSelectTab: CommonTabs.Drafts },
    });
  };

  const handleCancel = () => {
    if (isFormPristine) {
      navigate(-1);
    } else {
      saveDraftContext.current = {
        isCancel: true,
      };
      setDraftModalVisible(true);
    }
  };

  const handleCreateEWItem = useCallback(
    async (
      recordData?: RecordProductItemExtraData,
      _instProvideQuotationExtraData?: InstructionProvideQuotationExtraDataType,
      authWorkflow?: AuthorizationWorkflow
    ) => {
      authWorkflowTriggered.current = authWorkflow;

      const computedAttachments = enhancedAttachments.length
        ? attachmentsToAttachmentInputs(enhancedAttachments as Attachment[])
        : attachmentsToAdd.current ?? undefined;

      let data: EarlyWarningItem | undefined;
      let errors;

      if (ewAction === EWInstructionAction.Send) {
        const response = await sendEarlyWarning({
          triggersAuthorizationWorkflow: !!authWorkflow,
          args: [
            {
              variables: {
                input: {
                  productInstanceId: productInstanceId!,
                  data: schemaValuesWithDefaultEffectValues,
                  attachments: computedAttachments,
                },
                ...(draftId && { draftId }),
              },
            },
          ],
        });
        data = response.data?.sendEarlyWarningItem as EarlyWarningItem;
        errors = response.errors;

        if (data) {
          const msg = authWorkflow ? (
            <AuthorizationToastTransMessage />
          ) : (
            t("common.successMessages.entitySent", {
              entity: t("Projects.EarlyWarnings.earlyWarning"),
            })
          );

          enqueueSnackbar(msg, {
            autoHideDuration: snackbarAutoHideDuration,
            persist: !!authWorkflow,
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("common.errorMessages.generic"), {
            variant: "error",
          });
        }
      } else {
        const response = await recordEarlyWarning({
          triggersAuthorizationWorkflow: !!authWorkflow,
          args: [
            {
              variables: {
                input: {
                  productInstanceId: productInstanceId!,
                  data: schemaValuesWithDefaultEffectValues,
                  attachments: computedAttachments,
                  ...recordData!,
                },
                ...(draftId && { draftId }),
              },
            },
          ],
        });

        data = response.data?.recordEarlyWarningItem as EarlyWarningItem;
        errors = response.errors;
      }

      setIsFormPristine(true);
      setConfirmModalVisible(false);
      setEWAction(undefined);

      if (!errors) {
        setNewlyCreatedEWId(data!.id);
      }
    },
    [
      t,
      enqueueSnackbar,
      ewAction,
      productInstanceId,
      schemaValuesWithDefaultEffectValues,
      recordEarlyWarning,
      sendEarlyWarning,
      draftId,
      attachmentsToAdd,
      enhancedAttachments,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setConfirmModalVisible(false);
    setEWAction(undefined);
  }, []);

  const handleSaveDraft = async () => {
    if (!isFormSaved) {
      const newDraftId = await upsertDraftEW();

      if (newDraftId !== draftId) {
        setNewlyCreatedDraftEWId(newDraftId);
      }
    }
  };

  const handleDraftModalDontSave = () => {
    confirmNavigation(); // for navigate away scenarios

    if (attachmentsToAdd.current?.length) {
      // remove from S3 uploaded files
      unloadLocalAttachments();
    }

    if (saveDraftContext.current.isCancel) {
      setIsFormPristine(true);
      navigate(-1);
    }
  };

  const handleDraftModalConfirm = async () => {
    await upsertDraftEW();

    confirmNavigation();
    if (saveDraftContext.current.isCancel) {
      navigate(-1);
    }
  };

  const upsertDraftEW = async (
    draftEW?: EditDraftEarlyWarningItemInput | SendEarlyWarningItemInput
  ) => {
    let newDraftId = "";

    if (draftId) {
      // edit draft
      const { data } = await editEWItemDraft({
        variables: {
          input: (draftEW as EditDraftEarlyWarningItemInput) ?? {
            id: draftId,
            data: schemaValues,
            attachments: enhancedAttachments.length
              ? attachmentsToAttachmentInputs(
                  enhancedAttachments as Attachment[]
                )
              : undefined,
          },
        },
      });
      newDraftId = data?.editDraftEarlyWarningItem.id || "";
    } else {
      // add new draft
      const { data } = await addEWItemDraft({
        variables: {
          input: (draftEW as SendEarlyWarningItemInput) ?? {
            productInstanceId: productInstanceId!,
            data: schemaValues,
            attachments: attachmentsToAdd.current,
          },
        },
      });
      newDraftId = data?.addDraftEarlyWarningItem.id || "";

      attachmentsToAdd.current = [];
    }

    setDraftModalVisible(false);
    setIsFormSaved(true);
    setIsFormPristine(true);

    return newDraftId;
  };

  const handleSchemaValuesChange = useCallback(
    (schemaValues: ItemDataInput) => {
      setIsFormPristine(false);
      setIsFormSaved(false);
      setSchemaValues(schemaValues);
    },
    []
  );

  const handleDraftModalClose = () => {
    cancelNavigation();
    setDraftModalVisible(false);
  };

  const newEWItemName = useMemo(() => {
    const sectionContainingTitle = schemaValues.sections.find((section) =>
      section.entries.find((entry) => entry.name.toLowerCase() === "title")
    );

    return (
      sectionContainingTitle?.entries.find(
        (entry) => entry.name.toLowerCase() === "title"
      )?.value || ""
    );
  }, [schemaValues]);

  const triggerSendEWFlow = useCallback(() => {
    setConfirmModalVisible(true);
    setEWAction(EWInstructionAction.Send);
  }, []);

  const triggerRecordEWFlow = useCallback(() => {
    setConfirmModalVisible(true);
    setEWAction(EWInstructionAction.Record);
  }, []);

  useEffect(() => {
    if (draftId) {
      fetchDraftEWData({ variables: { id: draftId } });
    }
  }, [draftId, fetchDraftEWData]);

  useEffect(() => {
    if (draftEWData && draftEWData.draftEarlyWarningItem) {
      setSchemaValues(draftEWData.draftEarlyWarningItem.data);
      setEnhancedAttachments(
        (draftEWData.draftEarlyWarningItem.attachments as Attachment[]).filter(
          (attach) => attach.status === AttachmentStatus.Active
        ) ?? []
      );
    }
  }, [draftEWData]);

  useEffect(() => {
    if (newlyCreatedEWId && isFormPristine) {
      if (authWorkflowTriggered.current) {
        navigateBack();
      } else {
        navigate(
          NewAppPaths.authorized.Projects.children.EarlyWarningDetails.pathConstructor(
            productInstanceId!,
            newlyCreatedEWId,
            ProductType.EarlyWarnings
          ),
          { replace: true }
        );
      }
    }
  }, [
    newlyCreatedEWId,
    navigate,
    navigateBack,
    productInstanceId,
    isFormPristine,
  ]);

  useEffect(() => {
    if (newlyCreatedDraftEWId && isFormPristine) {
      navigate(
        { search: `draftId=${newlyCreatedDraftEWId}` },
        { replace: true }
      );
    }
  }, [newlyCreatedDraftEWId, navigate, isFormPristine]);

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={contractDataLite?.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <DeleteProductItemDraftConfirmModal
        open={deleteDraftEWModalVisible}
        onClose={closeDeleteDraftEWModal}
        onPrimaryClick={handleDeleteDraftEW}
        onSecondaryClick={handleDeleteDraftEWModalCancel}
        primaryBtnLoading={deleteDraftEWLoading}
      />
      {ewAction && (
        <NewEWInstructionItemConfirmModal
          open={confirmModalVisible}
          attachments={attachmentsToAdd.current}
          data={schemaValuesWithDefaultEffectValues}
          productInstanceId={productInstanceId!}
          contractId={contractId!}
          isProvideQuotationType={false}
          projectName={projectDataLite?.name || ""}
          contractName={contractDataLite?.name || ""}
          itemName={newEWItemName}
          action={ewAction}
          productType={ProductType.EarlyWarnings}
          primaryBtnLoading={
            sendEarlyWarningLoading || recordEarlyWarningLoading
          }
          onClose={handleCloseModal}
          onPrimaryClick={handleCreateEWItem}
          onSecondaryClick={handleCloseModal}
        />
      )}
      <ProductItemDraftConfirmModal
        productItemType={ProductType.EarlyWarnings}
        open={draftModalVisible || showBeforeNavigateAwayModal}
        secondaryBtnCaption={t("common.buttons.dontSave")}
        primaryBtnCaption={t("common.buttons.save")}
        primaryBtnLoading={addEWItemDraftLoading || editEWItemDraftLoading}
        onClose={handleDraftModalClose}
        onPrimaryClick={handleDraftModalConfirm}
        onSecondaryClick={handleDraftModalDontSave}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <NewProductItemHeader
            onCancel={handleCancel}
            onBack={handleCancel}
            onSend={triggerSendEWFlow}
            onRecord={triggerRecordEWFlow}
            onSaveDraft={handleSaveDraft}
            onDiscardDraft={triggerDeleteDraftEW}
            recordSupported={productInstanceData?.soloModeSupported}
            upsertDraftProductItemLoading={
              addEWItemDraftLoading || editEWItemDraftLoading
            }
            productType={ProductType.EarlyWarnings}
            disabled={!schemaValid || attachmentsLoading}
            saveBtnDisabledTooltip={
              !schemaValid
                ? t("common.labels.invalidForm")
                : attachmentsLoading
                ? t("Attachments.loadingTooltip")
                : undefined
            }
            saved={isFormSaved}
            editMode={isEditingDraft}
          />
          <Box mt={3}>
            {productInstanceDataLoading || getDraftEWDataLoading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode
                productItemType={ProductType.EarlyWarnings}
                schema={productInstanceData!.productSchema as ProductSchema}
                schemaValues={schemaValues}
                onSchemaValuesChange={handleSchemaValuesChange}
                onSchemaValidityChange={setSchemaValid}
                contractCurrency={contractDataLite?.valueCurrency ?? ""}
                contractTimezone={contractDataLite?.timeZone ?? ""}
                productInstanceId={productInstanceData!.id}
                mainColumnExtraWidgetsBottom={
                  <Grid item xs={12} key={"attachments"} position="relative">
                    <SectionContainer>
                      <Attachments
                        editMode
                        attachments={allAttachments}
                        timezone={contractDataLite?.timeZone}
                        onAttachmentsAdd={addAttachments}
                        onAttachmentRemove={removeAttachment}
                        onAttachmentUpdate={updateAttachment}
                        onAttachmentClick={handleAttachmentClick}
                      />
                    </SectionContainer>
                  </Grid>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
