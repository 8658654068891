import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Info } from "phosphor-react";
import { Trans } from "react-i18next";
import moment from "moment";
import { useMemo } from "react";
import { DateWithTimeTooltip } from "containers/Projects/components/DateWithTimeTooltip";
import { InfoBannerContainer } from "components/Banners";

type DiaryWaivedBannerProps = {
  dateWaived: string;
  waivedBy: string;
  reason: string;
  contractTimezone: string;
};

export const DiaryWaivedBanner: React.FC<DiaryWaivedBannerProps> = ({
  dateWaived,
  waivedBy,
  reason,
  contractTimezone,
}) => {
  const theme = useTheme();

  const transComponentsConfig = useMemo(() => {
    return {
      bold: (
        <Typography
          variant="p2"
          color="grey.900"
          fontWeight={600}
          display="inline"
        />
      ),
      boldTooltipDate: (
        <DateWithTimeTooltip
          display="inline"
          datetime={dateWaived}
          timezone={contractTimezone}
          variant="p2"
          fontWeight={600}
        />
      ),
    };
  }, [contractTimezone, dateWaived]);

  return (
    <InfoBannerContainer>
      <Info size={20} color={theme.palette.info.main} />
      <Stack
        direction="column"
        alignItems="flex-start"
        ml={2}
        flex={1}
        spacing={1}
      >
        <Box sx={{ display: "inline", fontSize: "14px" }}>
          <Trans
            i18nKey={"Projects.DailyDiaries.diaryWaivedBannerMessage"}
            values={{
              date: moment(dateWaived).format("MMMM Do, yyyy"),
              user: waivedBy,
            }}
            components={transComponentsConfig}
          />
        </Box>
        <Typography variant="p2" color="grey.900" fontStyle="italic">
          {reason}
        </Typography>
      </Stack>
    </InfoBannerContainer>
  );
};
