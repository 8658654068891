import { useClaimProductInstance } from "containers/Projects/hooks/useClaimProductInstance";
import { useVariationProductInstance } from "containers/Projects/hooks/useVariationProductInstance";
import {
  isFIDICContractType,
  isFIDIC17WhiteContractType,
  isNECContractType,
} from "containers/Projects/Projects.utils";
import { useCompEventProductInstance } from "hooks/useCompEventProductInstance";
import { useMemo } from "react";

type useShallDisplayMainSectionsReturnType = {
  displayPrice: boolean;
  displayTime: boolean;
  displaySections: boolean;
  displayKeyDates: boolean;
  loading: boolean;
};

/**
 * Price, Time, Sections sections should be displayed as such:
 *  - FIDIC
 *     - White book: Visible to users with roles for Variations
 *     - RY book: Visible to users with roles for Claims AND Variations
 *  - NEC: Visible to users with roles for Compensation Events
 *
 * Key Dates section should be displayed:
 *   - NEC: Visible to users with roles for Compensation Events
 */
export const useShallDisplayMainSections = (
  contractId?: string,
  contractType?: {
    description: string;
    subType: string;
    version: string;
  }
): useShallDisplayMainSectionsReturnType => {
  const isNEC = isNECContractType(contractType);
  const isFIDIC = isFIDICContractType(contractType);
  const isFIDICWhite = isFIDIC17WhiteContractType(contractType);

  const { variationProductInstance, loading: variationsLoading } =
    useVariationProductInstance(contractId);
  const { claimProductInstance, loading: claimsLoading } =
    useClaimProductInstance(contractId);
  const { compEventProductInstance, loading: compEventsLoading } =
    useCompEventProductInstance(contractId);

  const baseCondition = useMemo(() => {
    if (isNEC) {
      return !!compEventProductInstance;
    } else if (isFIDIC) {
      if (isFIDICWhite) {
        return !!variationProductInstance;
      }
      return !!variationProductInstance && !!claimProductInstance;
    }
    return false;
  }, [
    isNEC,
    isFIDIC,
    compEventProductInstance,
    variationProductInstance,
    claimProductInstance,
    isFIDICWhite,
  ]);

  return {
    loading: variationsLoading || claimsLoading || compEventsLoading,
    displayPrice: baseCondition,
    displayTime: baseCondition,
    displaySections: baseCondition,
    displayKeyDates: isNEC ? baseCondition : false,
  };
};
