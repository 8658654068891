import { Box, Stack, Typography } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ItemTag } from "components/ItemTag";
import { ProductItemTypenameToProduct } from "constants/products";
import { isNECContractType } from "containers/Projects/Projects.utils";
// import { provideQuotationEnumValue } from "../../constants";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { AttachmentsDencity } from "containers/Projects/components/Attachments/Attachments.decl";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import {
  AttachmentStatus,
  EarlyWarningItem,
  InstructionItem,
  ProductSchema,
  // ProductType,
} from "generated/graphql";
import { useContractLite } from "hooks/useContractLite";
// import { useMemo } from "react";
// import { InstructionProvideQuotationCEDetails } from "./InstructionProvideQuotationCEDetails";

type EWInstructionSummaryProps = {
  ewInstItem: Omit<InstructionItem | EarlyWarningItem, "id">;
};

export const EWInstructionSummary: React.FC<EWInstructionSummaryProps> = ({
  ewInstItem,
}) => {
  const productType = ProductItemTypenameToProduct[ewInstItem.__typename!];
  const { contractLite, contractLiteLoading } = useContractLite(
    ewInstItem.productInstance.contract.id
  );

  // const isIntruction = productType === ProductType.Instructions;
  // const isInstructionProvideQuotation = useMemo(() => {
  //   return isIntruction
  //     ? (ewInstItem as InstructionItem).data.sections?.[0].entries.find(
  //         (entry) => entry.name === "Type"
  //       )?.value === provideQuotationEnumValue
  //     : false;
  // }, [isIntruction, ewInstItem]);

  const { allAttachments, downloadAttachment } = useAttachments(
    (ewInstItem.attachments ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    )
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={contractLite?.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Stack>
        {contractLiteLoading || !contractLite ? (
          <CenteredLoadingIndicator />
        ) : (
          <>
            <Stack
              direction="row"
              ml={2}
              spacing={1}
              alignItems="center"
              data-testid="item-reference-container"
            >
              <ItemTag type={productType} />
              <Typography
                variant="h3"
                fontWeight={600}
                color="grey.900"
                data-testid="item-reference"
              >
                {ewInstItem.title}
              </Typography>
            </Stack>
            <SchemaInterpretor
              editMode={false}
              productItemType={productType}
              schema={ewInstItem.productInstance.productSchema as ProductSchema}
              schemaValues={ewInstItem.data}
              liteVariant
              contractTypeId={
                ewInstItem.productInstance.contract.contractTypeId
              }
              contractCurrency={
                ewInstItem.productInstance.contract.valueCurrency ?? ""
              }
              contractTimezone={
                ewInstItem.productInstance.contract.timeZone ?? ""
              }
              productInstanceId={ewInstItem.productInstanceId}
              isNECContractType={isNECContractType(contractLite.contractType)}
            />
          </>
        )}

        {/* TODO: when BE is ready to export this information, uncomment and display it right */}
        {/* {isInstructionProvideQuotation && (
          <Box ml={2}>
            <InstructionProvideQuotationCEDetails />
          </Box>
        )} */}
        {allAttachments.length > 0 && (
          <Box ml={2} mt={2}>
            <Attachments
              editMode={false}
              showTabs={false}
              timezone={ewInstItem.productInstance.contract.timeZone}
              dencity={AttachmentsDencity.Compact}
              attachments={allAttachments}
              onAttachmentClick={handleAttachmentClick}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};
