import { gql } from "@apollo/client";
import { contractKeyDateFields } from "graphql/fragments/contractKeyDate.fragment";

export const editContractKeyDatesMutation = gql`
  ${contractKeyDateFields}

  mutation editContractKeyDates($input: EditContractKeyDatesInput!) {
    editContractKeyDates(input: $input) {
      id
      keyDates {
        ...ContractKeyDateFields
      }
    }
  }
`;
