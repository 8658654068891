import { useTheme } from "@mui/material";
import {
  ContractStatus,
  ItemStatusOptionStyles,
  ProductInstanceStatus,
  ProjectStatus,
} from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StatusOption } from "./StatusTag";
import { StatusOptionNew } from "./StatusTagNew";

/**
 * Valid for ProjectStatus as well
 * @returns Array of valid options for <StatusTag />
 */
export const useContractStatusOptions = (): StatusOption<
  ProjectStatus | ContractStatus | ProductInstanceStatus
>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ContractStatus).map((status) => ({
      id: status,
      label: t(`Enums.ContractStatus.${status}`),
      color:
        status === ContractStatus.Active
          ? theme.palette.primary.main
          : status === ContractStatus.Offline
          ? theme.palette.warning.main
          : status === ContractStatus.Closed
          ? theme.palette.common.black
          : theme.palette.grey[700],
    }));
  }, [theme, t]);

  return options;
};

/**
 * Valid for ProjectStatus as well
 * @returns Array of valid options for <StatusTagNew />
 */
export const useContractStatusOptionsNew = (): StatusOptionNew<
  ProjectStatus | ContractStatus | ProductInstanceStatus
>[] => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(ContractStatus).map((status) => ({
      id: status,
      label: t(`Enums.ContractStatus.${status}`),
      style:
        status === ContractStatus.Active
          ? ItemStatusOptionStyles.Green
          : status === ContractStatus.Offline
          ? ItemStatusOptionStyles.Amber
          : status === ContractStatus.Closed
          ? ItemStatusOptionStyles.Black
          : ItemStatusOptionStyles.Grey,
    }));
  }, [t]);

  return options;
};
