import { SectionTitle } from "./SectionHeader";
import { useTranslation } from "react-i18next";
import { ContractDetailsSectionContainer } from "./ContractDetailsSectionContainer";
import {
  Divider,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Info, Money } from "phosphor-react";
import React from "react";
import { customShadows, unofficialThemeColors } from "theme/extendedTheme";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";

type PriceSectionProps = {
  isNec: boolean;
  isFidicRedYellow: boolean;
  isFidicWhite: boolean;
  currency: string;
  originalValue: number;
  confirmedValue: number;
  unconfirmedValue: number;
  confirmedPrice: number;
  unconfirmedPrice: number;
};

export const PriceSection: React.FC<PriceSectionProps> = ({
  confirmedPrice,
  confirmedValue,
  currency,
  originalValue,
  isNec,
  isFidicRedYellow,
  isFidicWhite,
  unconfirmedPrice,
  unconfirmedValue,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const originalContractValueTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ocvTooltipNEC"
        : isFidicRedYellow
        ? "ocvTooltipFIDIC_RY"
        : isFidicWhite
        ? "ocvTooltipFIDIC_WHITE"
        : ""
    }`
  );

  const confirmedContractValueTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ccvTooltipNEC"
        : isFidicRedYellow
        ? "ccvTooltipFIDIC_RY"
        : isFidicWhite
        ? "ccvTooltipFIDIC_WHITE"
        : ""
    }`
  );

  const unconfirmedContractValueTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ucvTooltipNEC"
        : isFidicRedYellow
        ? "ucvTooltipFIDIC_RY"
        : isFidicWhite
        ? "ucvTooltipFIDIC_WHITE"
        : ""
    }`
  );

  return (
    <ContractDetailsSectionContainer>
      <SectionTitle mb={2}>{t("Projects.ContractDetails.price")}</SectionTitle>
      <Stack direction="row" width="100%">
        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Money size={16} color={theme.palette.grey[800]} />
            <Typography color="grey.800">
              {`${currency} ${Number(originalValue).toLocaleString("en-US")}`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.originalContractValue")}
            </Typography>
            <Tooltip title={originalContractValueTooltip}>
              <Info size={16} color={theme.palette.grey[600]} />
            </Tooltip>
          </Stack>
        </Stack>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: theme.palette.grey[200],
            mx: theme.spacing(2.5),
            my: theme.spacing(1),
          }}
        />

        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Money size={16} color={theme.palette.success.main} />
            <LocalTooltip value={confirmedPrice} currency={currency}>
              <Typography>
                {`${currency} ${Number(confirmedValue).toLocaleString(
                  "en-US"
                )}`}
              </Typography>
            </LocalTooltip>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.confirmed")}
            </Typography>
            <Tooltip title={confirmedContractValueTooltip}>
              <Info size={16} />
            </Tooltip>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Money size={16} color={unofficialThemeColors.amberDarker} />
            <LocalTooltip value={unconfirmedPrice} currency={currency}>
              <Typography>
                {`${currency} ${Number(unconfirmedValue).toLocaleString(
                  "en-US"
                )}`}
              </Typography>
            </LocalTooltip>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.unconfirmed")}
            </Typography>
            <Tooltip title={unconfirmedContractValueTooltip}>
              <Info size={16} />
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </ContractDetailsSectionContainer>
  );
};

export const LocalTooltip: React.FC<
  Omit<TooltipProps, "title"> & { value: number; currency: string }
> = ({ children, value, currency, ...props }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            "& .MuiTooltip-arrow": {
              color: "common.white",
            },
            boxShadow: customShadows.popoverMedium,
          },
        },
      }}
      arrow
      placement="top"
      title={
        <PriceTag
          value={value}
          currency={currency}
          sx={{ width: "100%" }}
          noBackground
        />
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
