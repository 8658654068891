import { Grid, SelectChangeEvent } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInstructionProvideQuotationExtraDataForm } from "./useInstructionProvideQuotationExtraDataForm";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { useTranslation } from "react-i18next";
import { FormLabel } from "components/FormLabel";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ClaimTypeSelect } from "containers/Projects/components/ActionModal/ClaimTypeSelectGridItem";
import { ProductType } from "generated/graphql";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { dateISOFormat } from "constants/constants";

export type InstructionProvideQuotationExtraDataType = {
  claimTypeId: string;
  assumptions?: string | null;
  dateInstructionMayBeGiven?: string; // dateByWhichProposedInstructionMayBeGiven, but renamed to match graphQL schema
};

const defaultFormData: InstructionProvideQuotationExtraDataType = {
  claimTypeId: "",
  assumptions: "",
};

export type InstructionProvideQuotationExtraDataFormProps = {
  contractId: string;
  extraData?: InstructionProvideQuotationExtraDataType;
  assumptionsPlaceholder?: string;
  showDateByWhichProposedInstructionMayBeGiven?: boolean;
  onChange: (extraData: InstructionProvideQuotationExtraDataType) => void;
  onFormValidationChange: (isValid: boolean) => void;
};

export const InstructionProvideQuotationExtraDataForm: React.FC<
  InstructionProvideQuotationExtraDataFormProps
> = ({
  contractId,
  extraData,
  assumptionsPlaceholder,
  showDateByWhichProposedInstructionMayBeGiven,
  onChange,
  onFormValidationChange,
}) => {
  const { t } = useTranslation();
  const formTouched = useRef<boolean>(false);

  const dataValidators: DataValidators = useMemo(() => {
    return {
      claimTypeId: {
        validators: [ValidatorType.Required],
      },
      ...(showDateByWhichProposedInstructionMayBeGiven
        ? {
            dateInstructionMayBeGiven: {
              validators: [ValidatorType.ValidDateMinDateToday],
            },
          }
        : {}),
    };
  }, [showDateByWhichProposedInstructionMayBeGiven]);

  const [formData, setFormData] =
    useState<InstructionProvideQuotationExtraDataType>(
      extraData ?? defaultFormData
    );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const { activeClaimTypes, loading: activeClaimTypesLoading } =
    useInstructionProvideQuotationExtraDataForm(contractId);

  const validateForm = useCallback(
    (externalFormData?: InstructionProvideQuotationExtraDataType) => {
      const validationResult = validateData(
        externalFormData ?? formData,
        dataValidators
      );

      if (validationResult.valid) {
        if (Object.keys(formDataErrors).length > 0) {
          setFormDataErrors({});
        }
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    },
    [dataValidators, formData, formDataErrors]
  );

  const handleClaimTypeChange = (event: SelectChangeEvent<string>) => {
    setFormData((curData) => ({
      ...curData,
      claimTypeId: event.target.value,
    }));

    onChange({
      ...formData,
      claimTypeId: event.target.value,
    });
    formTouched.current = true;

    validateForm({ ...formData, claimTypeId: event.target.value });
  };

  const handleAssumptionsChange = (updatedAssuptions: string) => {
    setFormData((curData) => ({
      ...curData,
      assumptions: updatedAssuptions,
    }));

    onChange({
      ...formData,
      assumptions: updatedAssuptions,
    });
    formTouched.current = true;
  };

  const handleDateChange = (date: Date | null) => {
    const newDate = date ? moment(date).format(dateISOFormat) : undefined;

    setFormData((curData) => ({
      ...curData,
      dateInstructionMayBeGiven: newDate,
    }));

    onChange({
      ...formData,
      dateInstructionMayBeGiven: newDate,
    });
    formTouched.current = true;

    validateForm({
      ...formData,
      dateInstructionMayBeGiven: newDate,
    });
  };

  const isFormValid = useMemo(() => {
    if (
      !formTouched.current &&
      (formData.claimTypeId || formData.dateInstructionMayBeGiven)
    ) {
      // form hasn't been touched, but has some data, it means user pressed Back button and came back to the form.
      // Do not validate the form if it's untouched because it'll show errors as soon as opening the form, which is bad UX.
      return validateForm(formData);
    }

    return formTouched.current && !Object.keys(formDataErrors).length;
  }, [formDataErrors, formData, validateForm]);

  useEffect(() => {
    onFormValidationChange(isFormValid);
  }, [isFormValid, onFormValidationChange]);

  return activeClaimTypesLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <Grid container spacing={2}>
      {showDateByWhichProposedInstructionMayBeGiven && (
        <Grid item xs={12}>
          <FormLabel
            label={t(
              "Projects.Instructions.dateByWhichProposedInstructionMayBeGiven"
            )}
            required
          />
          <DatePicker
            value={
              formData.dateInstructionMayBeGiven
                ? new Date(formData.dateInstructionMayBeGiven)
                : null
            }
            onChange={handleDateChange}
            format="yyyy-MM-dd"
            disablePast
            slotProps={{
              popper: {
                placement: "bottom-end",
              },
              textField: {
                fullWidth: true,
                size: "small",
                error: !!formDataErrors.dateInstructionMayBeGiven,
                helperText: formDataErrors.dateInstructionMayBeGiven,
                required: true,
                ["data-testid" as any]:
                  "date-by-which-proposed-instruction-may-be-given",
              },
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ClaimTypeSelect
          productType={ProductType.CompEvents}
          value={formData.claimTypeId ?? ""}
          claimTypes={activeClaimTypes}
          error={formDataErrors.claimTypeId}
          onChange={handleClaimTypeChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel label={t("Projects.CompEvents.ActionModal.assumptions")} />
        <RichTextArea
          content={formData.assumptions ?? ""}
          placeholder={assumptionsPlaceholder}
          onChange={handleAssumptionsChange}
        />
      </Grid>
    </Grid>
  );
};
