import { Typography, TypographyProps } from "@mui/material";

export const SectionTitle: React.FC<TypographyProps> = ({
  children,
  ...props
}) => {
  return (
    <Typography variant="h4" fontWeight={600} color="grey.800" {...props}>
      {children}
    </Typography>
  );
};
