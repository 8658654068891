import {
  AddContractKeyDateInput,
  ContractKeyDate,
  ContractKeyDateStatus,
  EditContractKeyDateInput,
  EditContractKeyDatesMutation,
  EditContractKeyDatesMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { contractKeyDateToEditContractKeyDateInput } from "../ContractDetails.utils";
import { snackbarAutoHideDuration } from "constants/constants";
import { editContractKeyDatesMutation } from "./ContractKeyDates.query";

export type useContractKeyDatesArgs = {
  contractId?: string;
  contractKeyDates?: ContractKeyDate[];
};

export const useContractKeyDates = ({
  contractId,
  contractKeyDates,
}: useContractKeyDatesArgs) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editContractKeyDates, { loading }] = useGraphMutation<
    EditContractKeyDatesMutation,
    EditContractKeyDatesMutationVariables
  >(
    editContractKeyDatesMutation,
    {
      update: (cache, result, meta) => {
        if (!meta.variables || result.errors?.length || !result.data) {
          return;
        }

        const contractCacheItemIdentifier = cache.identify({
          __typename: "Contract",
          id: meta.variables.input.id,
        });

        cache.modify({
          id: contractCacheItemIdentifier,
          fields: {
            keyDates() {
              return result.data?.editContractKeyDates.keyDates;
            },
          },
        });

        cache.gc();
      },
    },
    null
  );

  const handleAddKeyDate = useCallback(
    async (newKeyDate: AddContractKeyDateInput) => {
      if (!contractId) {
        return;
      }

      const keyDatesToUpdate =
        contractKeyDates?.map((keyDate) =>
          contractKeyDateToEditContractKeyDateInput(keyDate)
        ) ?? [];

      keyDatesToUpdate.push({
        ...newKeyDate,
        status: ContractKeyDateStatus.Active,
      });

      const { data } = await editContractKeyDates({
        variables: {
          input: {
            id: contractId,
            keyDates: keyDatesToUpdate,
          },
        },
      });

      if (data) {
        enqueueSnackbar(
          t("common.successMessages.entityCreated", {
            entity: t("AdminConsole.ContractKeyDates.contractKeyDate"),
          }),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContractKeyDates, contractId, contractKeyDates, t, enqueueSnackbar]
  );

  const handleEditKeyDate = useCallback(
    async (updatedKeyDate: EditContractKeyDateInput) => {
      if (!contractId) {
        return;
      }

      const isDeleteCase =
        updatedKeyDate.status === ContractKeyDateStatus.Removed;

      let keyDatesToUpdate =
        contractKeyDates?.map((keyDate) =>
          contractKeyDateToEditContractKeyDateInput(keyDate)
        ) ?? [];

      if (isDeleteCase) {
        keyDatesToUpdate = keyDatesToUpdate?.filter(
          (keyDate) => keyDate.id !== updatedKeyDate.id
        );
      } else {
        keyDatesToUpdate = keyDatesToUpdate?.map((keyDate) =>
          keyDate.id === updatedKeyDate.id ? updatedKeyDate : keyDate
        );
      }

      const { data } = await editContractKeyDates({
        variables: {
          input: {
            id: contractId,
            keyDates: keyDatesToUpdate,
          },
        },
      });

      if (data) {
        enqueueSnackbar(
          t(
            `common.successMessages.${
              isDeleteCase ? "entityDeleted" : "entityUpdated"
            }`,
            {
              entity: t("AdminConsole.ContractKeyDates.contractKeyDate"),
            }
          ),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContractKeyDates, contractId, contractKeyDates, t, enqueueSnackbar]
  );

  return {
    addKeyDate: handleAddKeyDate,
    editKeyDate: handleEditKeyDate,
    loading,
  };
};
