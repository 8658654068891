import {
  DailyDiaryItem,
  DailyDiaryItemsQuery,
  DailyDiaryItemsQueryVariables,
  DailyDiaryItemsRequiresMyAttentionQuery,
  DailyDiaryItemsRequiresMyAttentionQueryVariables,
  DailyDiaryItemsReviewedByMeQuery,
  DailyDiaryItemsReviewedByMeQueryVariables,
  DailyDiaryItemsSentByMeQuery,
  DailyDiaryItemsSentByMeQueryVariables,
} from "generated/graphql";
import { dailyDiaryItemsQuery } from "../queries/dailyDiaryItems.query";
import { dailyDiaryItemsRequiresMyAttentionQuery } from "../queries/dailyDiaryItemsRequiresMyAttention.query";
import { dailyDiaryItemsSentByMeQuery } from "../queries/dailyDiaryItemsSentByMe.query";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useGraphLazyQuery } from "../../../../../hooks/useGraphLazyQuery";
import uniqBy from "lodash.uniqby";
import { dailyDiaryItemsReviewedByMeQuery } from "../queries/dailyDiaryItemsReviewedByMe.query";

const dailyDiariesInitialBatchCount = 15;
const dailyDiaryItemsPageSize = 200;

export const useDailyDiaries = (productInstanceId: string) => {
  const dailyDiaryItemsNextToken = useRef<string | undefined>();
  const dailyDiaryRequiresMyAttentionItemsNextToken = useRef<
    string | undefined
  >();
  const dailyDiarySentByMeItemsNextToken = useRef<string | undefined>();
  const dailyDiaryReviewedByMeItemsNextToken = useRef<string | undefined>();

  const [
    loadDailyDiaries,
    {
      data: dailyDiaryItems,
      fetchMore: loadMoreDailyDiaries,
      refetch: refetchDailyDiaries,
      loading: dailyDiaryItemsLoading,
    },
  ] = useGraphLazyQuery<DailyDiaryItemsQuery, DailyDiaryItemsQueryVariables>(
    dailyDiaryItemsQuery,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const [
    loadDDRequireMyAttention,
    {
      data: dailyDiaryRequireMyAttentionItems,
      refetch: refetchDDRequireMyAttentionItems,
      fetchMore: loadMoreDailyDiariesRequireMyAttention,
      loading: dailyDiaryRequireMyAttentionItemsLoading,
    },
  ] = useGraphLazyQuery<
    DailyDiaryItemsRequiresMyAttentionQuery,
    DailyDiaryItemsRequiresMyAttentionQueryVariables
  >(dailyDiaryItemsRequiresMyAttentionQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [
    loadDDSentByMe,
    {
      data: dailyDiaryItemsSentByMe,
      refetch: refetchDDSentByMe,
      fetchMore: loadMoreDDSentByMe,
      loading: dailyDiaryItemsSentByMeLoading,
    },
  ] = useGraphLazyQuery<
    DailyDiaryItemsSentByMeQuery,
    DailyDiaryItemsSentByMeQueryVariables
  >(dailyDiaryItemsSentByMeQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [
    loadDDReviewedByMe,
    {
      data: dailyDiaryItemsReviewedByMe,
      refetch: refetchDDReviewedByMe,
      fetchMore: loadMoreDDReviewedByMe,
      loading: dailyDiaryItemsReviewedByMeLoading,
    },
  ] = useGraphLazyQuery<
    DailyDiaryItemsReviewedByMeQuery,
    DailyDiaryItemsReviewedByMeQueryVariables
  >(dailyDiaryItemsReviewedByMeQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const loadMoreDailyDiaryItems = useCallback(async () => {
    if (dailyDiaryItemsNextToken.current) {
      const { data } = await loadMoreDailyDiaries({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
          nextToken: dailyDiaryItemsNextToken.current,
        },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...(oldData?.dailyDiaryItems?.items ?? []),
            ...newData.dailyDiaryItems.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            dailyDiaryItems: {
              ...newData.dailyDiaryItems,
              items: uniqNewItems,
            },
          };
        },
      });
      dailyDiaryItemsNextToken.current =
        data.dailyDiaryItems.nextToken ?? undefined;
    } else {
      const { data } = await loadDailyDiaries({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
        },
      });
      dailyDiaryItemsNextToken.current =
        data?.dailyDiaryItems.nextToken ?? undefined;
    }
  }, [loadMoreDailyDiaries, loadDailyDiaries, productInstanceId]);

  const loadMoreDailyDiaryRequireMyAttentionItems = useCallback(async () => {
    if (dailyDiaryRequiresMyAttentionItemsNextToken.current) {
      const { data } = await loadMoreDailyDiariesRequireMyAttention({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
          nextToken: dailyDiaryRequiresMyAttentionItemsNextToken.current,
        },
        updateQuery: (oldData, { fetchMoreResult: newData }) => ({
          ...newData,
          dailyDiaryItemsRequiresMyAttention: {
            ...newData.dailyDiaryItemsRequiresMyAttention,
            items: [
              ...(oldData?.dailyDiaryItemsRequiresMyAttention?.items ?? []),
              ...newData.dailyDiaryItemsRequiresMyAttention.items,
            ],
          },
        }),
      });
      dailyDiaryRequiresMyAttentionItemsNextToken.current =
        data.dailyDiaryItemsRequiresMyAttention.nextToken ?? undefined;
    } else {
      const { data } = await loadDDRequireMyAttention({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
        },
      });
      dailyDiaryRequiresMyAttentionItemsNextToken.current =
        data?.dailyDiaryItemsRequiresMyAttention.nextToken ?? undefined;
    }
  }, [
    loadMoreDailyDiariesRequireMyAttention,
    loadDDRequireMyAttention,
    productInstanceId,
  ]);

  const loadMoreDailyDiarySentByMeItems = useCallback(async () => {
    if (dailyDiarySentByMeItemsNextToken.current) {
      const { data } = await loadMoreDDSentByMe({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
          nextToken: dailyDiarySentByMeItemsNextToken.current,
        },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...(oldData?.dailyDiaryItemsSentByMe?.items ?? []),
            ...newData.dailyDiaryItemsSentByMe.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            dailyDiaryItemsSentByMe: {
              ...newData.dailyDiaryItemsSentByMe,
              items: uniqNewItems,
            },
          };
        },
      });
      dailyDiarySentByMeItemsNextToken.current =
        data.dailyDiaryItemsSentByMe.nextToken ?? undefined;
    } else {
      const { data } = await loadDDSentByMe({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
        },
      });
      dailyDiarySentByMeItemsNextToken.current =
        data?.dailyDiaryItemsSentByMe.nextToken ?? undefined;
    }
  }, [loadMoreDDSentByMe, loadDDSentByMe, productInstanceId]);

  const loadMoreDailyDiaryReviewedByMeItems = useCallback(async () => {
    if (dailyDiaryReviewedByMeItemsNextToken.current) {
      const { data } = await loadMoreDDReviewedByMe({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
          nextToken: dailyDiaryReviewedByMeItemsNextToken.current,
        },
        updateQuery: (oldData, { fetchMoreResult: newData }) => {
          const newItems = [
            ...(oldData?.dailyDiaryItemsReviewedByMe?.items ?? []),
            ...newData?.dailyDiaryItemsReviewedByMe.items,
          ];

          const uniqNewItems = uniqBy(newItems, "id");

          return {
            ...newData,
            dailyDiaryItemsReviewedByMe: {
              ...newData.dailyDiaryItemsReviewedByMe,
              items: uniqNewItems,
            },
          };
        },
      });
      dailyDiaryReviewedByMeItemsNextToken.current =
        data.dailyDiaryItemsReviewedByMe.nextToken ?? undefined;
    } else {
      const { data } = await loadDDReviewedByMe({
        variables: {
          productInstanceId,
          limit: dailyDiaryItemsPageSize,
        },
      });
      dailyDiaryReviewedByMeItemsNextToken.current =
        data?.dailyDiaryItemsReviewedByMe.nextToken ?? undefined;
    }
  }, [loadMoreDDReviewedByMe, loadDDReviewedByMe, productInstanceId]);

  const resetDailyDiaryItems = useCallback(() => {
    refetchDailyDiaries({ productInstanceId, limit: dailyDiaryItemsPageSize });
    dailyDiaryItemsNextToken.current = undefined;
  }, [refetchDailyDiaries, productInstanceId]);

  const resetDailyDiaryRequireMyAttentionItems = useCallback(() => {
    refetchDDRequireMyAttentionItems({
      productInstanceId,
      limit: dailyDiaryItemsPageSize,
    });
    dailyDiaryRequiresMyAttentionItemsNextToken.current = undefined;
  }, [refetchDDRequireMyAttentionItems, productInstanceId]);

  const resetDailyDiarySentByMeItems = useCallback(() => {
    refetchDDSentByMe({ productInstanceId, limit: dailyDiaryItemsPageSize });
    dailyDiarySentByMeItemsNextToken.current = undefined;
  }, [refetchDDSentByMe, productInstanceId]);

  const resetDailyDiaryReviewedByMeItems = useCallback(() => {
    refetchDDReviewedByMe({
      productInstanceId,
      limit: dailyDiaryItemsPageSize,
    });
    dailyDiaryReviewedByMeItemsNextToken.current = undefined;
  }, [refetchDDReviewedByMe, productInstanceId]);

  useEffect(() => {
    // initial load of general diaries
    loadMoreDailyDiaryItems();
  }, [loadMoreDailyDiaryItems]);

  useEffect(() => {
    // initial load diaries that require my attention
    loadMoreDailyDiaryRequireMyAttentionItems();
  }, [loadMoreDailyDiaryRequireMyAttentionItems]);

  useEffect(() => {
    // initial load of diaries sent by me
    loadMoreDailyDiarySentByMeItems();
  }, [loadMoreDailyDiarySentByMeItems]);

  useEffect(() => {
    // initial load of diaries reviewed by me
    loadMoreDailyDiaryReviewedByMeItems();
  }, [loadMoreDailyDiaryReviewedByMeItems]);

  const dailyDiaries = useMemo(
    () => (dailyDiaryItems?.dailyDiaryItems.items ?? []) as DailyDiaryItem[],
    [dailyDiaryItems]
  );

  const statusFacets = useMemo(
    () => dailyDiaryItems?.dailyDiaryItems.statusFacets,
    [dailyDiaryItems]
  );

  useEffect(() => {
    // fetching until nextToken = null or there are at least 15 records
    if (
      dailyDiaries.length <= dailyDiariesInitialBatchCount &&
      !!dailyDiaryItemsNextToken.current &&
      !dailyDiaryItemsLoading
    ) {
      loadMoreDailyDiaryItems();
    }
  }, [dailyDiaries, dailyDiaryItemsLoading, loadMoreDailyDiaryItems]);

  const dailyDiariesRequireMyAttention = useMemo(
    () =>
      (dailyDiaryRequireMyAttentionItems?.dailyDiaryItemsRequiresMyAttention
        .items ?? []) as DailyDiaryItem[],
    [dailyDiaryRequireMyAttentionItems]
  );

  const dailyDiariesSentByMe = useMemo(
    () =>
      (dailyDiaryItemsSentByMe?.dailyDiaryItemsSentByMe.items ??
        []) as DailyDiaryItem[],
    [dailyDiaryItemsSentByMe]
  );

  const dailyDiariesReviewedByMe = useMemo(
    () =>
      (dailyDiaryItemsReviewedByMe?.dailyDiaryItemsReviewedByMe.items ??
        []) as DailyDiaryItem[],
    [dailyDiaryItemsReviewedByMe]
  );

  return {
    statusFacets,
    totalCount: dailyDiaryItems?.dailyDiaryItems.totalCount,
    dailyDiaryItems: dailyDiaries,
    loading: dailyDiaryItemsLoading,
    hasMoreDailyDiaries: !!dailyDiaryItemsNextToken.current,
    loadMoreDailyDiaryItems,
    resetDailyDiaryItems,
    dailyDiaryRequireMyAttentionItems: dailyDiariesRequireMyAttention,
    dailyDiaryRequireMyAttentionItemsLoading,
    hasMoreDailyDiariesRequireMyAttention:
      !!dailyDiaryRequiresMyAttentionItemsNextToken.current,
    loadMoreDailyDiaryRequireMyAttentionItems,
    resetDailyDiaryRequireMyAttentionItems,
    dailyDiariesSentByMe,
    dailyDiaryItemsSentByMeLoading,
    hasMoreDailyDiariesSentByMe: !!dailyDiarySentByMeItemsNextToken.current,
    loadMoreDailyDiarySentByMeItems,
    resetDailyDiarySentByMeItems,
    dailyDiariesReviewedByMe,
    dailyDiaryItemsReviewedByMeLoading,
    hasMoreDailyDiariesReviewedByMe:
      !!dailyDiaryReviewedByMeItemsNextToken.current,
    loadMoreDailyDiaryReviewedByMeItems,
    resetDailyDiaryReviewedByMeItems,
  };
};
