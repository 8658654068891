import { css, Stack, styled, Typography } from "@mui/material";
import { ContractDetailsHeader } from "./components/ContractDetailsHeader";
import { useContractDetails } from "./hooks/useContractDetails";
import { useContext, useEffect } from "react";
import { ExplorerContext } from "containers/Projects/components/Explorer/Explorer.context";
import { useShallDisplayMainSections } from "./hooks/useShallDisplayPriceSection";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { useTranslation } from "react-i18next";
import { PriceSection } from "./components/PriceSection";
import {
  isFIDICRedYellowContractType,
  isFIDIC17WhiteContractType,
  isNECContractType,
} from "containers/Projects/Projects.utils";
import { TimeSection } from "./components/TimeSection";
import { SectionsSection } from "./components/SectionsSection/SectionsSection";
import { KeyDatesSection } from "./components/KeyDatesSection/KeyDatesSection";
import { useContractDetailsEvent } from "./hooks/useContractDetailsEvent";

const BodyContainer = styled(Stack)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.default};
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
  `
);

type ContractDetailsProps = {
  onClose: () => void;
};

export const ContractDetails: React.FC<ContractDetailsProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { trackContractDetailsEvent } = useContractDetailsEvent();
  const { contractId } = useContext(ExplorerContext);

  const { contractDetails, loading } = useContractDetails(
    contractId ?? undefined
  );

  const contractTypeLite = contractDetails
    ? {
        description: contractDetails.contractTypeDescription,
        subType: contractDetails.contractTypeSubType,
        version: contractDetails.contractTypeVersion,
      }
    : undefined;
  const isNec = isNECContractType(contractTypeLite);
  const isFidicRedYellow = isFIDICRedYellowContractType(contractTypeLite);
  const isFidicWhite = isFIDIC17WhiteContractType(contractTypeLite);

  const {
    displayKeyDates,
    displayPrice,
    displaySections,
    displayTime,
    loading: sectionsLoading,
  } = useShallDisplayMainSections(
    contractId,
    contractDetails
      ? {
          description: contractDetails.contractType,
          subType: contractDetails.contractTypeSubType,
          version: contractDetails.contractTypeVersion,
        }
      : undefined
  );

  const generalLoading = loading || sectionsLoading;
  const noSectionDisplayable =
    !displayKeyDates && !displayPrice && !displaySections && !displayTime;

  useEffect(() => {
    if (contractDetails) {
      trackContractDetailsEvent(
        contractDetails.friendlyName,
        contractDetails.projectFriendlyName
      );
    }
  }, [trackContractDetailsEvent, contractDetails]);

  return (
    <Stack>
      <ContractDetailsHeader
        contractDetails={contractDetails ?? undefined}
        loading={loading}
        onClose={onClose}
      />
      <BodyContainer spacing={4}>
        {generalLoading ? (
          <CenteredLoadingIndicator />
        ) : noSectionDisplayable ? (
          <Typography textAlign="center" py={1} color="grey.600">
            {isNec
              ? t("Projects.ContractDetails.noSectionsNEC")
              : isFidicWhite
              ? t("Projects.ContractDetails.noSectionsFIDICWhite")
              : isFidicRedYellow
              ? t("Projects.ContractDetails.noSectionsFIDIC")
              : t("common.labels.n/a")}
          </Typography>
        ) : (
          <>
            {displayPrice && contractDetails && (
              <PriceSection
                isNec={isNec}
                isFidicRedYellow={isFidicRedYellow}
                isFidicWhite={isFidicWhite}
                currency={contractDetails.valueCurrency}
                originalValue={contractDetails.value}
                confirmedValue={contractDetails.confirmedValue}
                unconfirmedValue={contractDetails.unconfirmedValue}
                confirmedPrice={contractDetails.confirmedPrice}
                unconfirmedPrice={contractDetails.unconfirmedPrice}
              />
            )}
            {displayTime && contractDetails && (
              <TimeSection
                isNec={isNec}
                isFidicRedYellow={isFidicRedYellow}
                isFidicWhite={isFidicWhite}
                endDate={contractDetails.endDate}
                confirmedEndDate={contractDetails.confirmedEndDate}
                unconfirmedEndDate={contractDetails.unconfirmedEndDate}
                confirmedTime={contractDetails.confirmedTime}
                unconfirmedTime={contractDetails.unconfirmedTime}
              />
            )}
            {displaySections && contractDetails && (
              <SectionsSection
                sections={contractDetails.sectionalChanges}
                contractFriendlyName={contractDetails.friendlyName}
              />
            )}
            {displayKeyDates && contractDetails && (
              <KeyDatesSection
                keyDates={contractDetails.keyDateChanges}
                contractFriendlyName={contractDetails.friendlyName}
              />
            )}
          </>
        )}
      </BodyContainer>
    </Stack>
  );
};
