import { gql } from "@apollo/client";
import { contractSectionFields } from "graphql/fragments/contractSection.fragment";

export const editContractSectionsMutation = gql`
  ${contractSectionFields}

  mutation editContractSections($input: EditContractSectionsInput!) {
    editContractSections(input: $input) {
      id
      sections {
        ...ContractSectionFields
      }
    }
  }
`;
