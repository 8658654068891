import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { DeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/DeleteProductItemDraftConfirmModal";
import { useDeleteProductItemDraftConfirmModal } from "containers/Projects/components/DeleteProductItemDraftConfirmModal/useDeleteProductItemDraftConfirmModal";
import { NewProductItemHeader } from "containers/Projects/components/Header/NewProductItemHeader/NewProductItemHeader";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { EWInstructionAction } from "containers/Projects/Projects.decl";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  AuthorizationWorkflow,
  EditDraftInstructionItemInput,
  InstructionItem,
  ItemDataInput,
  ProductSchema,
  ProductType,
  SendInstructionItemInput,
} from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { ProductItemDraftConfirmModal } from "../../components/ProductItemDraftConfirmModal";
import { NewEWInstructionItemConfirmModal } from "../NewEarlyWarning/components/NewEarlyWarningItemConfirmModal/NewEWInstructionItemConfirmModal";
import { InstructionProvideQuotationExtraDataType } from "../../components/CompEvents/CompEventActionModal/components/NotifyAndRequestQuotationCEAction/InstructionProvideQuotationExtraDataForm/InstructionProvideQuotationExtraDataForm";
import {
  CommonTabs,
  provideQuotationEnumValue,
  snackbarAutoHideDuration,
} from "constants/constants";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useSnackbar } from "notistack";
import { useNavigateBack } from "hooks/useNavigateBack";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useNewInstruction } from "./useNewInstruction";

type SaveDraftContext = {
  isCancel?: boolean;
};

export const NewInstruction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authWorkflowTriggered = useRef<AuthorizationWorkflow>();
  const { contractId, productInstanceId } = useParams();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const { enqueueSnackbar } = useSnackbar();
  const navigateBack = useNavigateBack();

  const [newlyCreatedInstructionId, setNewlyCreatedInstructionId] =
    useState<string>();
  const [newlyCreatedDraftInstructionId, setNewlyCreatedDraftInstructionId] =
    useState<string>();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [instructionAction, setInstructionAction] =
    useState<EWInstructionAction>();
  const [draftModalVisible, setDraftModalVisible] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const saveDraftContext = useRef<SaveDraftContext>({});
  const [isFormPristine, setIsFormPristine] = useState(true);
  const isEditingDraft = !!draftId;

  const [schemaValues, setSchemaValues] = useState<ItemDataInput>({
    sections: [],
  });
  const [schemaValid, setSchemaValid] = useState<boolean>();
  const [enhancedAttachments, setEnhancedAttachments] = useState<
    EnhancedAttachment[]
  >([]);
  const attachmentsToAdd = useRef<AttachmentInput[]>();

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    attachmentsToAdd.current = attachmentsUpdated;
    setIsFormPristine(false);

    if (draftId) {
      await upsertDraftInstruction({
        id: draftId,
        data: schemaValues,
        attachments: attachmentsUpdated,
      });
    }
  };

  const {
    allAttachments,
    attachmentsLoading,
    addAttachments,
    removeAttachment,
    updateAttachment,
    unloadLocalAttachments,
    downloadAttachment,
  } = useAttachments(enhancedAttachments, handleAttachmentsUpdated);

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const {
    showPrompt: showBeforeNavigateAwayModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(!isFormPristine);

  const {
    modalVisible: deleteDraftInstructionModalVisible,
    setModalVisible: setDeleteDraftInstructionModalVisible,
    closeModal: closeDeleteDraftInstructionModal,
    handleCancel: handleDeleteDraftInstructionModalCancel,
    handleConfirm: handleDeleteDraftInstructionModalConfirm,
    loading: deleteDraftInstructionLoading,
  } = useDeleteProductItemDraftConfirmModal();

  const getInstructionTrackingExtraData = useCallback(() => {
    return {
      Type:
        schemaValues.sections?.[0]?.entries.find(
          (entry) => entry.name === "Type"
        )?.value ?? "",
    };
  }, [schemaValues]);

  const {
    fetchDraftInstructionData,
    draftInstructionData,
    draftInstructionDataLoading,
    sendInstruction,
    sendInstructionLoading,
    recordInstruction,
    recordInstructionLoading,
    addInstructionItemDraft,
    addInstructionItemDraftLoading,
    editInstructionItemDraft,
    editInstructionItemDraftLoading,
    projectDataLite,
    contractDataLite,
    productInstanceData,
    productInstanceDataLoading,
    isNECContractType,
    isNEC4ECCContractType,
    isNEC4PSCContractType,
    contractTypeId,
  } = useNewInstruction(getInstructionTrackingExtraData);

  const isProvideQuotationType = useMemo(
    () =>
      !!schemaValues.sections?.[0]?.entries.find(
        (entry) =>
          entry.name === "Type" &&
          entry.value === provideQuotationEnumValue &&
          isNECContractType
      ),
    [schemaValues, isNECContractType]
  );

  const triggerDeleteDraftInstruction = () => {
    setDeleteDraftInstructionModalVisible(true);
  };

  const handleDeleteDraftInstruction = async () => {
    await handleDeleteDraftInstructionModalConfirm(
      draftId!,
      ProductType.Instructions
    );
    navigate(NewAppPaths.authorized.Projects.path, {
      state: { autoSelectTab: CommonTabs.Drafts },
    });
  };

  const handleCancel = () => {
    if (isFormPristine) {
      navigate(-1);
    } else {
      saveDraftContext.current = {
        isCancel: true,
      };
      setDraftModalVisible(true);
    }
  };

  const handleCreateInstructionItem = useCallback(
    async (
      recordData?: RecordProductItemExtraData,
      instrProvideQuotationExtraData?: InstructionProvideQuotationExtraDataType,
      authWorkflow?: AuthorizationWorkflow
    ) => {
      authWorkflowTriggered.current = authWorkflow;
      const computedAttachments = enhancedAttachments.length
        ? attachmentsToAttachmentInputs(enhancedAttachments as Attachment[])
        : attachmentsToAdd.current ?? undefined;

      let data: InstructionItem | undefined;
      let errors;
      if (instructionAction === EWInstructionAction.Send) {
        const response = await sendInstruction({
          triggersAuthorizationWorkflow: !!authWorkflow,
          args: [
            {
              variables: {
                input: {
                  productInstanceId: productInstanceId!,
                  data: schemaValues,
                  attachments: computedAttachments,
                  ...(isProvideQuotationType
                    ? instrProvideQuotationExtraData
                    : {}),
                },
                ...(draftId && { draftId }),
              },
            },
          ],
        });
        data = response.data?.sendInstructionItem as InstructionItem;
        errors = response.errors;

        if (data) {
          const msg = authWorkflow ? (
            <AuthorizationToastTransMessage />
          ) : (
            t("common.successMessages.entitySent", {
              entity: t("Projects.Instructions.instruction"),
            })
          );

          enqueueSnackbar(msg, {
            autoHideDuration: snackbarAutoHideDuration,
            persist: !!authWorkflow,
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("common.errorMessages.generic"), {
            variant: "error",
          });
        }
      } else {
        const response = await recordInstruction({
          triggersAuthorizationWorkflow: !!authWorkflow,
          args: [
            {
              variables: {
                input: {
                  productInstanceId: productInstanceId!,
                  data: schemaValues,
                  attachments: computedAttachments,
                  ...(isProvideQuotationType
                    ? instrProvideQuotationExtraData
                    : {}),
                  ...recordData!,
                },
                ...(draftId && { draftId }),
              },
            },
          ],
        });

        data = response.data?.recordInstructionItem as
          | InstructionItem
          | undefined;
        errors = response.errors;
      }

      setIsFormPristine(true);
      setConfirmModalVisible(false);
      setInstructionAction(undefined);
      if (!errors) {
        setNewlyCreatedInstructionId(data!.id);
      }
    },
    [
      t,
      enqueueSnackbar,
      instructionAction,
      productInstanceId,
      schemaValues,
      recordInstruction,
      sendInstruction,
      draftId,
      attachmentsToAdd,
      isProvideQuotationType,
      enhancedAttachments,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setConfirmModalVisible(false);
    setInstructionAction(undefined);
  }, []);

  const handleSaveDraft = async () => {
    if (!isFormSaved) {
      const newDraftId = await upsertDraftInstruction();

      if (newDraftId !== draftId) {
        setNewlyCreatedDraftInstructionId(newDraftId);
      }
    }
  };

  const handleDraftModalDontSave = () => {
    confirmNavigation(); // for navigate away scenarios

    if (attachmentsToAdd.current?.length) {
      // remove from S3 uploaded files
      unloadLocalAttachments();
    }

    if (saveDraftContext.current.isCancel) {
      setIsFormPristine(true);
      navigate(-1);
    }
  };

  const handleDraftModalConfirm = async () => {
    await upsertDraftInstruction();

    confirmNavigation();
    if (saveDraftContext.current.isCancel) {
      navigate(-1);
    }
  };

  const upsertDraftInstruction = async (
    draftInstruction?: EditDraftInstructionItemInput | SendInstructionItemInput
  ) => {
    let newDraftId = "";

    if (draftId) {
      // edit draft
      const { data } = await editInstructionItemDraft({
        variables: {
          input: (draftInstruction as EditDraftInstructionItemInput) ?? {
            id: draftId,
            data: schemaValues,
            attachments: enhancedAttachments.length
              ? attachmentsToAttachmentInputs(
                  enhancedAttachments as Attachment[]
                )
              : undefined,
          },
        },
      });
      newDraftId = data?.editDraftInstructionItem.id || "";
    } else {
      // add new draft
      const { data } = await addInstructionItemDraft({
        variables: {
          input: (draftInstruction as SendInstructionItemInput) ?? {
            productInstanceId: productInstanceId!,
            data: schemaValues,
            attachments: attachmentsToAdd.current,
          },
        },
      });
      newDraftId = data?.addDraftInstructionItem.id || "";

      attachmentsToAdd.current = [];
    }

    setDraftModalVisible(false);
    setIsFormSaved(true);
    setIsFormPristine(true);

    return newDraftId;
  };

  const handleSchemaValuesChange = useCallback(
    (schemaValues: ItemDataInput) => {
      setIsFormPristine(false);
      setIsFormSaved(false);
      setSchemaValues(schemaValues);
    },
    []
  );

  const handleDraftModalClose = () => {
    cancelNavigation();
    setDraftModalVisible(false);
  };

  const newInstructionItemName = useMemo(() => {
    const sectionContainingTitle = schemaValues.sections.find((section) =>
      section.entries.find((entry) => entry.name.toLowerCase() === "title")
    );

    return (
      sectionContainingTitle?.entries.find(
        (entry) => entry.name.toLowerCase() === "title"
      )?.value || ""
    );
  }, [schemaValues]);

  const triggerSendInstructionFlow = useCallback(() => {
    setConfirmModalVisible(true);
    setInstructionAction(EWInstructionAction.Send);
  }, []);

  const triggerRecordInstructionFlow = useCallback(() => {
    setConfirmModalVisible(true);
    setInstructionAction(EWInstructionAction.Record);
  }, []);

  useEffect(() => {
    if (draftId) {
      fetchDraftInstructionData({ variables: { id: draftId } });
    }
  }, [draftId, fetchDraftInstructionData]);

  useEffect(() => {
    if (draftInstructionData && draftInstructionData.draftInstructionItem) {
      setSchemaValues(draftInstructionData.draftInstructionItem.data);
      setEnhancedAttachments(
        (
          draftInstructionData.draftInstructionItem.attachments as Attachment[]
        ).filter((attach) => attach.status === AttachmentStatus.Active) ?? []
      );
    }
  }, [draftInstructionData]);

  useEffect(() => {
    if (newlyCreatedInstructionId && isFormPristine) {
      if (authWorkflowTriggered.current) {
        navigateBack();
      } else {
        navigate(
          NewAppPaths.authorized.Projects.children.InstructionDetails.pathConstructor(
            productInstanceId!,
            newlyCreatedInstructionId,
            ProductType.Instructions
          ),
          { replace: true }
        );
      }
    }
  }, [
    newlyCreatedInstructionId,
    navigate,
    navigateBack,
    productInstanceId,
    isFormPristine,
  ]);

  useEffect(() => {
    if (newlyCreatedDraftInstructionId && isFormPristine) {
      navigate(
        { search: `draftId=${newlyCreatedDraftInstructionId}` },
        { replace: true }
      );
    }
  }, [newlyCreatedDraftInstructionId, navigate, isFormPristine]);

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={contractDataLite?.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <DeleteProductItemDraftConfirmModal
        open={deleteDraftInstructionModalVisible}
        onClose={closeDeleteDraftInstructionModal}
        onPrimaryClick={handleDeleteDraftInstruction}
        onSecondaryClick={handleDeleteDraftInstructionModalCancel}
        primaryBtnLoading={deleteDraftInstructionLoading}
      />
      {instructionAction && (
        <NewEWInstructionItemConfirmModal
          open={confirmModalVisible}
          attachments={attachmentsToAdd.current}
          data={schemaValues}
          productInstanceId={productInstanceId!}
          contractId={contractId!}
          projectName={projectDataLite?.name || ""}
          contractName={contractDataLite?.name || ""}
          isNEC4ECCContractType={isNEC4ECCContractType}
          isNEC4PSCContractType={isNEC4PSCContractType}
          itemName={newInstructionItemName}
          isProvideQuotationType={isProvideQuotationType}
          action={instructionAction}
          productType={ProductType.Instructions}
          primaryBtnLoading={sendInstructionLoading || recordInstructionLoading}
          onClose={handleCloseModal}
          onPrimaryClick={handleCreateInstructionItem}
          onSecondaryClick={handleCloseModal}
        />
      )}
      <ProductItemDraftConfirmModal
        productItemType={ProductType.Instructions}
        open={draftModalVisible || showBeforeNavigateAwayModal}
        secondaryBtnCaption={t("common.buttons.dontSave")}
        primaryBtnCaption={t("common.buttons.save")}
        primaryBtnLoading={
          addInstructionItemDraftLoading || editInstructionItemDraftLoading
        }
        onClose={handleDraftModalClose}
        onPrimaryClick={handleDraftModalConfirm}
        onSecondaryClick={handleDraftModalDontSave}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <NewProductItemHeader
            onCancel={handleCancel}
            onBack={handleCancel}
            onSend={triggerSendInstructionFlow}
            onRecord={triggerRecordInstructionFlow}
            onSaveDraft={handleSaveDraft}
            onDiscardDraft={triggerDeleteDraftInstruction}
            recordSupported={productInstanceData?.soloModeSupported}
            upsertDraftProductItemLoading={
              addInstructionItemDraftLoading || editInstructionItemDraftLoading
            }
            productType={ProductType.Instructions}
            disabled={!schemaValid || attachmentsLoading}
            saveBtnDisabledTooltip={
              !schemaValid
                ? t("common.labels.invalidForm")
                : attachmentsLoading
                ? t("Attachments.loadingTooltip")
                : undefined
            }
            saved={isFormSaved}
            editMode={isEditingDraft}
          />
          <Box mt={3}>
            {productInstanceDataLoading ||
            draftInstructionDataLoading ||
            !contractTypeId ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode
                productItemType={ProductType.Instructions}
                schema={productInstanceData!.productSchema as ProductSchema}
                schemaValues={schemaValues}
                onSchemaValuesChange={handleSchemaValuesChange}
                onSchemaValidityChange={setSchemaValid}
                contractCurrency={contractDataLite?.valueCurrency ?? ""}
                contractTimezone={contractDataLite?.timeZone ?? ""}
                isNECContractType={isNECContractType}
                productInstanceId={productInstanceData!.id}
                contractTypeId={contractTypeId}
                mainColumnExtraWidgetsBottom={
                  <Grid item xs={12} key={"attachments"} position="relative">
                    <SectionContainer>
                      <Attachments
                        editMode
                        attachments={allAttachments}
                        timezone={contractDataLite?.timeZone}
                        onAttachmentsAdd={addAttachments}
                        onAttachmentRemove={removeAttachment}
                        onAttachmentUpdate={updateAttachment}
                        onAttachmentClick={handleAttachmentClick}
                      />
                    </SectionContainer>
                  </Grid>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
