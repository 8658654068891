import { useApolloClient } from "@apollo/client";
import { useCallback, useContext } from "react";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";

export const useOnInstructionWorkToCommenceActionUpdated = () => {
  const { cache } = useApolloClient();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const onUpdated = useCallback(() => {
    cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationProposalExplorerItems",
      args: { variationId: variation!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationInstructionCommenceWorkPrompt",
      args: { input: { variationId: variation!.id } },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationAction",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "variationHistoryItems",
      args: { variationId: variation!.id },
    });
    cache.gc();
    onChange?.();
  }, [onChange, variation, cache]);

  return {
    onUpdated,
  };
};
