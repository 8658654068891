import {
  css,
  styled,
  Stack,
  Divider,
  useTheme,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { ContractDetailsHeaderTitle } from "./ContractDetailsHeaderTitle";
import { ContractStatus, ContractView } from "generated/graphql";
import {
  StatusOptionNew,
  StatusTagNew,
} from "components/StatusTag/StatusTagNew";
import { useContractStatusOptionsNew } from "components/StatusTag/useContractStatusOptions";
import ReactCountryFlag from "react-country-flag";
import { getCountryByName } from "helpers/countries/countries";
import { getTimezoneOffset } from "helpers/timezones";
import moment from "moment";
import { X } from "phosphor-react";
import { useTranslation } from "react-i18next";

const Container = styled(Stack)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    padding-top: ${theme.spacing(
      2
    )}; // Note: design not following spacing pattern 15px
    padding-bottom: ${theme.spacing(
      2
    )}; // Note: design not following spacing pattern 15px
    position: sticky;
    top: 0;
    z-index: 10;
  `
);

type ContractDetailsHeaderProps = {
  loading?: boolean;
  contractDetails?: ContractView;
  onClose: () => void;
};

export const ContractDetailsHeader: React.FC<ContractDetailsHeaderProps> = ({
  loading,
  contractDetails,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const contractStatusOptions =
    useContractStatusOptionsNew() as StatusOptionNew<ContractStatus>[];
  const countryData = getCountryByName(contractDetails?.country);
  const timezoneOffset = contractDetails?.timeZone
    ? `${getTimezoneOffset(contractDetails.timeZone)}`
    : "";
  const datetimeOnTz = contractDetails?.timeZone
    ? moment(new Date()).tz(contractDetails.timeZone).format("HH:mm")
    : "";

  return (
    <Container direction="row" alignItems="center">
      <ContractDetailsHeaderTitle
        loading={loading}
        friendlyName={contractDetails?.friendlyName}
        number={contractDetails?.number}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          my: theme.spacing(1.5),
          pl: theme.spacing(3),
        }}
      />
      <Box display="flex" alignItems="center" pl={3}>
        {loading || !contractDetails?.status ? (
          <Skeleton variant="text" sx={{ fontSize: "24px" }} width={80} />
        ) : (
          <StatusTagNew
            size="small"
            hideArrow
            placement="bottom-start"
            selectedOptionId={contractDetails?.status}
            options={contractStatusOptions}
            disabled
          />
        )}
      </Box>
      <Stack direction="row" spacing={6} alignItems="center" ml="auto">
        <Stack direction="column" spacing={0.5}>
          <Typography variant="p3">
            {t("Projects.ContractDetails.type")}
          </Typography>
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={80} />
          ) : (
            <Typography variant="p2" fontWeight={600}>
              {`${contractDetails?.contractType} ${contractDetails?.contractTypeVersion} ${contractDetails?.contractTypeSubType}`}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" spacing={0.5}>
          <Typography variant="p3">
            {t("Projects.ContractDetails.location")}
          </Typography>
          {loading || !countryData ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={200} />
          ) : (
            <Stack direction="row" alignItems="center">
              <ReactCountryFlag
                countryCode={countryData.countryCodeA2}
                svg
                style={{
                  fontSize: "1.5em",
                  lineHeight: "1.5em",
                }}
              />
              <Typography ml={1} variant="p2" fontWeight={600}>
                {countryData.name}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="column" spacing={0.5}>
          <Typography variant="p3">
            {t("Projects.ContractDetails.localTime")}
          </Typography>
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "14px" }} width={80} />
          ) : (
            <Typography ml={1} variant="p2" fontWeight={600}>
              {`${datetimeOnTz} ${timezoneOffset}`}
            </Typography>
          )}
        </Stack>
        <X size={24} cursor="pointer" onClick={onClose} />
      </Stack>
    </Container>
  );
};
