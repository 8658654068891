import { create } from "zustand";

type GlobalState = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  contractDetailsPopupVisible: boolean;
  setContractDetailsPopupVisible: (visible: boolean) => void;
};

export const useGlobalStore = create<GlobalState>()((set) => ({
  loading: false,
  setLoading: (loading?: boolean) => set(() => ({ loading })),
  contractDetailsPopupVisible: false,
  setContractDetailsPopupVisible: (visible: boolean) =>
    set(() => ({ contractDetailsPopupVisible: visible })),
}));
