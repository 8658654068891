import {
  getNextStepText as getCENextStepText,
  getNextStepDueDate as getCENextStepDueDate,
  isCompEvent,
} from "containers/Projects/components/CompEvents/CompEvents.utils";
import { NextStepItem } from "./NextStepsTable";
import {
  getNextStepText as getClaimNextStepText,
  getNextStepDueDate as getClaimNextStepDueDate,
  isClaim,
} from "containers/Projects/components/Claims/Claims.utils";
import { isFIDIC99RedYellowContractType } from "containers/Projects/Projects.utils";
import {
  getNextStepDueDate as getVONextStepDueDate,
  getNextStepText as getVONextStepText,
} from "containers/Projects/components/Variations/Variations.utils";

export const getItemNextStepText = (item: NextStepItem) => {
  return isCompEvent(item)
    ? getCENextStepText(item)
    : isClaim(item)
    ? getClaimNextStepText(
        item,
        isFIDIC99RedYellowContractType(
          item.productInstance.contract.contractType
        ) // TODO: double check
      )
    : getVONextStepText(item);
};

export const getItemNextStepParty = (item: NextStepItem) => {
  return item.nextStep?.party.description;
};

export const getItemDeemingDate = (item: NextStepItem) => {
  return isCompEvent(item)
    ? item.deemingDate
      ? new Date(item.deemingDate)
      : null
    : null;
};

// No need to pass eventItem and instructionItem because in record appears in NextSteps, it means CE, Claim, VO exists 100%
export const getItemDueDate = (item: NextStepItem) => {
  return isCompEvent(item)
    ? getCENextStepDueDate(item)
    : isClaim(item)
    ? getClaimNextStepDueDate(item)
    : getVONextStepDueDate(item);
};
