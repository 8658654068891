import { Grid } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import {
  Contract,
  InstructionItem,
  ProductType,
  VariationAction,
  VariationActionType,
  VariationItem,
  VariationProposalExplorerItem,
} from "generated/graphql";
import { useCallback, useContext, useMemo } from "react";
import { useVariationWidget } from "./hooks/useVariationWidget";
import { WidgetHeader } from "../../Widget/WidgetHeader";
import { CEWidgetSectionType, WidgetSection } from "../../Widget/WidgetSection";
import { VariationWidgetNextStepSection } from "./components/VariationWidgetNextStepSection";
import { VariationWidgetLastStepSection } from "./components/VariationWidgetLastStepSection";
import { ContractPriceTimeChangeSection } from "../../Widget/ContractPriceTimeChangeSection";
import {
  VariationWidgetContext,
  VariationWidgetContextType,
} from "./VariationWidget.context";
import { useVariationProposalExplorerItems } from "../hooks/useVariationProposalExplorerItems";
import {
  isFIDIC17WhiteContractType,
  isFIDIC99RedYellowContractType,
} from "containers/Projects/Projects.utils";
import { useVariationWidgetNextStepSection } from "containers/Projects/hooks/useVariationWidgetNextStepSection";
import { useVariation } from "./hooks/useVariation/useVariation";

type VariationWidgetInnerProps = {
  previewMode?: boolean;
  variation?: VariationItem;
  explorerItems: VariationProposalExplorerItem[];
  explorerItemsLoading?: boolean;
  variationActionList: VariationAction[];
};

type VariationWidgetProps = {
  loading?: boolean;
  variationId?: string;
  instructionItem?: InstructionItem;
  regardingId: string;
  contract: Contract;
  projectId?: string;
  projectName?: string;
  onChange?: () => void;
} & Omit<
  VariationWidgetInnerProps,
  "explorerItems" | "explorerItemsLoading" | "variationActionList" | "variation"
>;

const VariationWidgetInner: React.FC<VariationWidgetInnerProps> = ({
  previewMode,
  variation,
  explorerItems,
  variationActionList,
  explorerItemsLoading,
}) => {
  const { isFIDIC17White, isFIDIC99RedYellow } = useContext(
    VariationWidgetContext
  );
  const shallDisplayNextStepSection =
    variation?.nextStep || variationActionList.length > 0;

  const mostRecentExplorerItem = useMemo(() => {
    const agreementOrDetermination = explorerItems.find(
      (item) =>
        item.__typename === "VariationAgreement" ||
        item.__typename === "VariationDetermination"
    );

    // return first and only VariationAgreement/VariationDetermination or most recent VariationProposal
    return (
      agreementOrDetermination ??
      explorerItems
        .slice()
        .filter((item) => item.__typename === "VariationProposal")
        .sort(
          (dc1, dc2) =>
            new Date(dc2.dateCreated).getTime() -
            new Date(dc1.dateCreated).getTime()
        )[0]
    );
  }, [explorerItems]);

  const { contract } = useContext(VariationWidgetContext);

  return (
    <Grid
      container
      spacing={previewMode ? 1.5 : 2}
      data-testid="variation-widget-container"
    >
      {!previewMode && variation && (
        <Grid item xs={12}>
          <WidgetHeader
            productItem={variation}
            productType={ProductType.Variations}
          />
        </Grid>
      )}
      {shallDisplayNextStepSection && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.NextStep}>
            <VariationWidgetNextStepSection
              variation={variation}
              variationActionList={variationActionList}
              previewMode={previewMode}
            />
          </WidgetSection>
        </Grid>
      )}
      {variation &&
        variation.lastAction.actionType !== VariationActionType.None && (
          <Grid item xs={12}>
            <WidgetSection type={CEWidgetSectionType.LastAction}>
              <VariationWidgetLastStepSection
                previewMode={previewMode}
                variation={variation}
              />
            </WidgetSection>
          </Grid>
        )}
      {variation && explorerItems.length > 0 && !previewMode && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.Quotation}>
            <ContractPriceTimeChangeSection
              time={mostRecentExplorerItem.time}
              price={mostRecentExplorerItem.price}
              currency={mostRecentExplorerItem.currency}
              reference={mostRecentExplorerItem.reference}
              loading={explorerItemsLoading}
              contract={contract}
              regardingItemNumber={variation.number}
              productType={ProductType.Variations}
              explorerItems={explorerItems}
              explorerItemsLoading={explorerItemsLoading}
              isFIDIC17White={isFIDIC17White}
              isFIDIC99RedYellow={isFIDIC99RedYellow}
            />
          </WidgetSection>
        </Grid>
      )}
    </Grid>
  );
};

export const VariationWidget: React.FC<VariationWidgetProps> = ({
  variationId,
  contract,
  projectId,
  projectName,
  instructionItem,
  previewMode,
  regardingId,
  loading,
  onChange,
}) => {
  const { variation, variationLoading, refetchVariation } =
    useVariation(variationId);
  const { variationProductInstance, loading: variationProdInstanceLoading } =
    useVariationWidget(contract);
  const { explorerItems, loading: explorerItemsLoading } =
    useVariationProposalExplorerItems(variationId);

  const { variationActionList, loading: variationActionListLoading } =
    useVariationWidgetNextStepSection(variationId);

  const refetchVariationItem = useCallback(() => {
    if (variationId) {
      refetchVariation({ id: variationId });
    } else {
      onChange?.();
    }
  }, [variationId, refetchVariation, onChange]);

  const variationContextValue = useMemo(
    (): VariationWidgetContextType => ({
      productInstanceId: variationProductInstance?.id ?? "",
      productInstanceNumberingFormat:
        variationProductInstance?.numberingFormat ?? "",
      recordSupported: !!variationProductInstance?.soloModeSupported,
      contract: contract!,
      projectId: projectId ?? "", // TODO to check if needed
      projectName: projectName ?? "",
      regardingId,
      instructionItem,
      isFIDIC17White: isFIDIC17WhiteContractType(contract.contractType),
      isFIDIC99RedYellow: isFIDIC99RedYellowContractType(contract.contractType),
      variation,
      onChange: refetchVariationItem,
    }),
    [
      projectId,
      projectName,
      instructionItem,
      contract,
      regardingId,
      variationProductInstance,
      variation,
      refetchVariationItem,
    ]
  );

  const showLoadingState =
    loading ||
    variationLoading ||
    variationProdInstanceLoading ||
    variationActionListLoading ||
    !variationContextValue.productInstanceId ||
    !contract;

  return showLoadingState ? (
    <CenteredLoadingIndicator />
  ) : (
    <VariationWidgetContext.Provider value={variationContextValue}>
      <VariationWidgetInner
        previewMode={previewMode}
        explorerItems={explorerItems}
        variationActionList={variationActionList}
        explorerItemsLoading={explorerItemsLoading}
        variation={variation}
      />
    </VariationWidgetContext.Provider>
  );
};
