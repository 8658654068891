import { Box, Button, Typography, useTheme } from "@mui/material";
import { dateISOFormat } from "constants/constants";
import { DaysLate } from "containers/Projects/components/DaysLate";
import {
  VariationAction,
  VariationActionType,
  VariationItem,
} from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { VariationWidgetContext } from "../VariationWidget.context";
import { VariationActionModal } from "../../VariationActionModal/VariationActionModal";
import { getNextStepDueDate, getNextStepText } from "../../Variations.utils";
import { AuthorizationBanner } from "components/Authorization/AuthorizationBanner";
import { useAuthStore } from "state-management/store/authStore";
import { useLoggedInUserReviewer } from "hooks/useLoggedInUserReviewer";
import { useOnConsentToProposalForProposedVariationActionUpdated } from "../../VariationActionModal/ConsentToProposalForProposedVariationAction/useOnConsentToProposalForProposedVariationActionUpdated";
import { useOnInstructionWorkToCommenceActionUpdated } from "../../VariationActionModal/InstructWorkToCommenceAction/useOnInstructionWorkToCommenceActionUpdated";
import { useOnNotifyAgreementOfVariationActionUpdated } from "../../VariationActionModal/NotifyAgreementOfVariationAction/useOnNotifyAgreementOfVariationActionUpdated";
import { useOnNotifyDeterminationOfVariationActionUpdated } from "../../VariationActionModal/NotifyDeterminationOfVariationAction/useOnNotifyDeterminationOfVariationActionUpdated";

export type VariationWidgetNextStepSectionProps = {
  variation?: VariationItem;
  previewMode?: boolean;
  variationActionList: VariationAction[];
};

export const VariationWidgetNextStepSection: React.FC<
  VariationWidgetNextStepSectionProps
> = ({ variation, previewMode = false, variationActionList }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { instructionItem, onChange } = useContext(VariationWidgetContext);
  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);
  const { canUserReviewCrtLevel, isUserReviewer } = useLoggedInUserReviewer(
    variation?.ongoingAuthorizationAudit ?? undefined
  );

  const { onUpdated: onConsentToProposalForProposedVariationUpdated } =
    useOnConsentToProposalForProposedVariationActionUpdated();

  const { onUpdated: onInstructionWorkToCommenceUpdated } =
    useOnInstructionWorkToCommenceActionUpdated();

  const { onUpdated: onNotifyAgreementOfVariationUpdated } =
    useOnNotifyAgreementOfVariationActionUpdated();
  const { onUpdated: onNotifyDeterminationOfVariationUpdated } =
    useOnNotifyDeterminationOfVariationActionUpdated();

  const {
    modalVisibility: nextStepModalVisibility,
    toggleModalVisibility: toggleNextStepModalVisibility,
  } = useBasicModal();

  const computedNextStepText = getNextStepText(variation);
  const computedNextStepDueDate = getNextStepDueDate(
    variation,
    instructionItem
  );

  // TODO: maybe extract this function as it's the same for all 3 widgets
  const computedNextStepDaysLate = useMemo(() => {
    if (variation) {
      return moment().diff(moment(variation.nextStep?.dueDate), "days");
    }

    if (computedNextStepDueDate) {
      const daysDifference = moment(computedNextStepDueDate).diff(
        moment(),
        "days"
      );
      if (daysDifference >= 0) {
        return undefined;
      }

      return Math.abs(daysDifference);
    }
  }, [variation, computedNextStepDueDate]);

  const shallDisplayNextStepButton = !!variationActionList.length;

  const triggerNextStepFlow = () => {
    toggleNextStepModalVisibility();
  };

  const handleAuthorizationFinalised = (actionExecuted?: boolean) => {
    if (actionExecuted) {
      // Refresh necessary queries after actions are authorized and executed
      switch (variation?.nextStep?.actionType) {
        case VariationActionType.ConsentToProposalForProposedVariation:
          onConsentToProposalForProposedVariationUpdated();
          break;
        case VariationActionType.InstructWorkToCommenceOnVariation:
          onInstructionWorkToCommenceUpdated();
          break;
        case VariationActionType.NotifyAgreementVariation:
          onNotifyAgreementOfVariationUpdated();
          break;
        case VariationActionType.NotifyDeterminationVariation:
          onNotifyDeterminationOfVariationUpdated();
          break;
        default:
          break;
      }
    }
    onChange?.();
  };

  const handleAuthorizationBannerClick = () => {
    if (variation?.ongoingAuthorizationAudit) {
      setAuthModalState({
        authAuditId: variation.ongoingAuthorizationAudit.id,
        type: canUserReviewCrtLevel ? "AuthorizeDeny" : "AuditTrail",
        variationId: variation.id,
        onChange: handleAuthorizationFinalised,
      });
    }
  };

  return (
    <>
      <VariationActionModal
        open={nextStepModalVisibility}
        onClose={toggleNextStepModalVisibility}
        variation={variation}
        variationActions={variationActionList}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        data-testid="vo-next-step-section"
      >
        <Box
          display="flex"
          flexDirection={previewMode ? "row" : "column"}
          alignItems={previewMode ? "center" : "flex-start"}
          justifyContent={
            !computedNextStepText && !previewMode ? "center" : "space-between"
          }
          width="100%"
        >
          {variation?.ongoingAuthorizationAudit && !previewMode && (
            <AuthorizationBanner
              size="small"
              stage="inProgress"
              mb={2}
              onClick={
                isUserReviewer ? handleAuthorizationBannerClick : undefined
              }
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            flex={!computedNextStepText ? "unset" : 3}
          >
            <Typography variant="p2" fontWeight={600} color="grey.800">
              {computedNextStepText
                ? t("Projects.Variations.nextStep")
                : t("Projects.Variations.furtherStepsAvailable")}
            </Typography>
            {computedNextStepText && (
              <Typography
                variant="p2"
                color="grey.800"
                mt={0.5}
                data-testid="next-step"
              >
                {t(computedNextStepText)}
              </Typography>
            )}
          </Box>
          {computedNextStepDueDate && (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              mt={previewMode ? 0 : 2}
            >
              <Typography variant="p2" fontWeight={600} color="grey.800">
                {t("common.labels.dueDate")}
              </Typography>
              <Box display="flex" alignItems="center" mt={0.5}>
                <Typography
                  variant="p2"
                  color="grey.800"
                  data-testid="due-date"
                >
                  {moment(computedNextStepDueDate).format(dateISOFormat)}
                </Typography>
                {computedNextStepDaysLate && computedNextStepDaysLate > 0 ? (
                  <DaysLate daysLate={computedNextStepDaysLate} ml={1.5} />
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
        {shallDisplayNextStepButton && !previewMode && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignSelf="stretch"
          >
            <Button
              sx={{
                minWidth: computedNextStepText ? "32px" : "24px",
                maxHeight: computedNextStepText ? "36px" : "24px",
              }}
              data-testid="vo-next-step-btn"
              onClick={triggerNextStepFlow}
            >
              <CaretRight color={theme.palette.grey[800]} size={18} />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
