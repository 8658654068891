import { Typography } from "@mui/material";
import { Banner } from "components/Banners";
import { dateISOFormat } from "constants/constants";
import { CompEventStatus } from "generated/graphql";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

type InstructionNEC4ECCProvideQuotationBannerProps = {
  compEventStatus: CompEventStatus;
  dateByWhichProposedInstructionMayBeGiven: string;
};

const transComponentsConfig = {
  bold: <Typography variant="p2" fontWeight={600} color="grey.900" />,
};

export const InstructionNEC4ECCProvideQuotationBanner: React.FC<
  InstructionNEC4ECCProvideQuotationBannerProps
> = ({ compEventStatus, dateByWhichProposedInstructionMayBeGiven }) => {
  const { t } = useTranslation();

  switch (compEventStatus) {
    case CompEventStatus.Proposed:
    case CompEventStatus.Quoted:
      return (
        <Banner
          variant="info"
          content={
            <Typography variant="p2" color="grey.900">
              <Trans
                i18nKey={
                  "Projects.Instructions.Banners.NEC4ECCProvideQuotation.info"
                }
                values={{
                  date: moment(dateByWhichProposedInstructionMayBeGiven).format(
                    dateISOFormat
                  ),
                }}
                components={transComponentsConfig}
              />
            </Typography>
          }
        />
      );
    case CompEventStatus.Abandoned:
      return (
        <Banner
          variant="error"
          content={t(
            "Projects.Instructions.Banners.NEC4ECCProvideQuotation.error"
          )}
        />
      );
    case CompEventStatus.Implemented:
      return (
        <Banner
          variant="success"
          content={t(
            "Projects.Instructions.Banners.NEC4ECCProvideQuotation.success"
          )}
        />
      );
  }

  return null;
};
