import { Box } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AddDraftVariationDeterminationInput,
  AttachmentInput,
  DraftVariationDetermination,
  EditDraftVariationDeterminationInput,
  VariationDeterminationNoticePrompt,
  VariationProposal,
} from "generated/graphql";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import { VariationActionFormProps } from "../VariationActionView";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { useVariationProposalAgreementDeterminationModal } from "../../VariationProposalAgreementDeterminationModal/useVariationProposalAgreementDeterminationModal";
import {
  ModalType,
  VariationProposalAgreementDeterminationFormDataType,
  VariationProposalAgreementDeterminationModal,
} from "../../VariationProposalAgreementDeterminationModal/VariationProposalAgreementDeterminationModal";
import {
  VOProposalsAgreementsDeterminationsTable,
  VariationResolveType,
} from "../components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { useNotifyDeterminationOfVariationAction } from "./useNotifyDeterminationOfVariationAction";
import { SendDeterminationOfVariationNoticeModal } from "./SendDeterminationOfVariationNoticeModal";
import { RecordDeterminationOfVariationNoticeModal } from "./RecordDeterminationOfVariationNoticeModal";

const defaultFormData: VariationDeterminationNoticePrompt = {
  draftVariationDeterminations: [], // Note: even though prompt accepts an array of VariationDeterminations, engineers will be able to add only one at a time
};

export const NotifyDeterminationOfVariationAction: React.FC<
  VariationActionFormProps & {
    consentedProposal?: VariationProposal;
    finalProposal?: VariationProposal;
    triggersAuthWorkflow?: boolean;
  }
> = ({
  apiRef,
  consentedProposal,
  finalProposal,
  onClose,
  triggersAuthWorkflow,
}) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [formData, setFormData] =
    useState<VariationDeterminationNoticePrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedDraftVariationId, setSelectedDraftVariationDeterminationId] =
    useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    sendVariationDetermination,
    recordVariationDetermination,
    refetchPrompt,
    draftVariationDeterminations: promptDraftVariationDeterminations,
    loading: promptDraftVariationDeterminationsLoading,
    actionLoading,
  } = useNotifyDeterminationOfVariationAction(triggersAuthWorkflow);

  const {
    modalVisibility: VODeterminationModalVisibility,
    toggleModalVisibility: toggleVODeterminationModalVisibility,
    loading: VODeterminationLoading,
    addDraftVODetermination,
    editDraftVODetermination,
    removeDraftVODetermination,
  } = useVariationProposalAgreementDeterminationModal(refetchPrompt);

  const draftVariationDeterminationIds = useMemo(
    () =>
      formData.draftVariationDeterminations.map(
        (draftDetermination) => draftDetermination.id
      ),
    [formData]
  );

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendVariationDeterminationNotice = async () => {
    await sendVariationDetermination({
      variables: {
        input: {
          variationId: variation?.id!,
          draftVariationDeterminationId: draftVariationDeterminationIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordVariationDeterminationNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordVariationDetermination({
      variables: {
        input: {
          variationId: variation?.id!,
          draftVariationDeterminationId: draftVariationDeterminationIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftVariationDeterminations.length,
    [formData]
  );

  const handleCreateEditDraftVariationDetermination = useCallback(
    async (
      data: VariationProposalAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftVariationDeterminationData = data as
        | AddDraftVariationDeterminationInput
        | EditDraftVariationDeterminationInput;

      if (formData.draftVariationDeterminations.length) {
        // edit mode
        const { data } = await editDraftVODetermination({
          variables: {
            input:
              draftVariationDeterminationData as EditDraftVariationDeterminationInput,
          },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVariationDeterminations: [
              data.editDraftVariationDetermination as DraftVariationDetermination,
            ],
          }));
        }
      } else {
        // create new draft VO determination
        const { data } = await addDraftVODetermination({
          variables: { input: draftVariationDeterminationData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVariationDeterminations: [
              data.addDraftVariationDetermination as DraftVariationDetermination,
            ],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleVODeterminationModalVisibility();
      }
    },
    [
      formData,
      addDraftVODetermination,
      editDraftVODetermination,
      toggleVODeterminationModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (formData: VariationProposalAgreementDeterminationFormDataType) => {
      updatedAttachmentsRef.current = formData.attachments;
      const isEditMode = !!selectedDraftVariationId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftVariationDetermination(formData, true);
      }
    },
    [handleCreateEditDraftVariationDetermination, selectedDraftVariationId]
  );

  const handleVariationDeterminationModalClose = () => {
    toggleVODeterminationModalVisibility();
    setSelectedDraftVariationDeterminationId(undefined);
  };

  const handleVariationDeterminationRowClick = (
    draftVariationDetermination: VariationResolveType
  ) => {
    setSelectedDraftVariationDeterminationId(
      (draftVariationDetermination as DraftVariationDetermination).id
    );
    toggleVODeterminationModalVisibility();
  };

  const handleDeleteDraftVariationDetermination = useCallback(
    (variationDeterminationId: string) => {
      removeDraftVODetermination({
        variables: { id: variationDeterminationId },
      });
    },
    [removeDraftVODetermination]
  );

  const handleNewVariationDeterminationClick = () => {
    setSelectedDraftVariationDeterminationId(undefined);
    toggleVODeterminationModalVisibility();
  };

  const selectedDraftVariationDetermination = useMemo(
    () =>
      formData.draftVariationDeterminations.find(
        (draftVariationDetermination) =>
          draftVariationDetermination.id === selectedDraftVariationId
      ),
    [formData, selectedDraftVariationId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftVariationDeterminations) {
      setFormData({
        draftVariationDeterminations: promptDraftVariationDeterminations,
      });
    }
  }, [promptDraftVariationDeterminations]);

  return !selectedDraftVariationId &&
    (VODeterminationLoading || promptDraftVariationDeterminationsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {VODeterminationModalVisibility && (
        <VariationProposalAgreementDeterminationModal
          open={VODeterminationModalVisibility}
          type={ModalType.DraftVODetermination}
          draftDetermination={selectedDraftVariationDetermination}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          contractTimezone={contract.timeZone}
          consentedProposal={consentedProposal}
          finalProposal={finalProposal}
          maxWidth="lg"
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftVariationDetermination}
          onClose={handleVariationDeterminationModalClose}
          onSecondaryClick={handleVariationDeterminationModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendDeterminationOfVariationNoticeModal
          open={sendModalVisibility}
          draftVariationDeterminations={formData.draftVariationDeterminations}
          onPrimaryClick={handleSendVariationDeterminationNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordDeterminationOfVariationNoticeModal
          open={recordModalVisibility}
          draftVariationDeterminations={formData.draftVariationDeterminations}
          onPrimaryClick={handleRecordVariationDeterminationNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="VariationDetermination"
          onAdd={handleNewVariationDeterminationClick}
          disabled={!!formData.draftVariationDeterminations.length}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={VODeterminationLoading}
          items={formData.draftVariationDeterminations}
          type="VariationDetermination"
          onRowClick={handleVariationDeterminationRowClick}
          onDelete={handleDeleteDraftVariationDetermination}
        />
      </Box>
    </>
  );
};
