import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import {
  RecordCompEventConfirmationMutation,
  RecordCompEventConfirmationMutationVariables,
  SendCompEventConfirmationMutation,
  SendCompEventConfirmationMutationVariables,
} from "generated/graphql";
import { recordCompEventConfirmationMutation } from "graphql/mutations/recordCompEventConfirmation";
import { sendCompEventConfirmationMutation } from "graphql/mutations/sendCompEventConfirmation";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import { useOnConfirmCEActionUpdated } from "./useOnConfirmCEActionUpdated";

export const useConfirmCEAction = (triggersAuthWorkflow?: boolean) => {
  const { t } = useTranslation();
  const { onUpdated } = useOnConfirmCEActionUpdated();

  const [
    sendCompEventConfirmation,
    { loading: sendCompEventConfirmationLoading },
  ] = useGraphMutation<
    SendCompEventConfirmationMutation,
    SendCompEventConfirmationMutationVariables
  >(
    sendCompEventConfirmationMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEventConfirmation"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordCompEventConfirmation,
    { loading: recordCompEventConfirmationLoading },
  ] = useGraphMutation<
    RecordCompEventConfirmationMutation,
    RecordCompEventConfirmationMutationVariables
  >(
    recordCompEventConfirmationMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.CompEvents.compEventConfirmation"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  return {
    actionLoading:
      sendCompEventConfirmationLoading || recordCompEventConfirmationLoading,
    sendCompEventConfirmation,
    recordCompEventConfirmation,
  };
};
