import { useCallback, useContext, useMemo } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  ClaimFinalClaimDetailsPromptQuery,
  ClaimFinalClaimDetailsPromptQueryVariables,
  DraftDetailedClaim,
  RecordClaimFinalClaimDetailsMutation,
  RecordClaimFinalClaimDetailsMutationVariables,
  SendClaimFinalClaimDetailsMutation,
  SendClaimFinalClaimDetailsMutationVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  claimFinalClaimDetailsPromptQuery,
  recordClaimFinalClaimDetailsMutation,
  sendClaimFinalClaimDetailsMutation,
} from "./SubmitFinalDetailedClaimAction.query";

export const useSubmitFinalDetailedClaimAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const {
    data: promptData,
    refetch,
    loading,
  } = useGraphQuery<
    ClaimFinalClaimDetailsPromptQuery,
    ClaimFinalClaimDetailsPromptQueryVariables
  >(claimFinalClaimDetailsPromptQuery, {
    variables: {
      input: {
        claimId: claim?.id!,
      },
    },
    fetchPolicy: "no-cache",
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "claimItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDetailsExplorerItems",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDraftDetailedClaims",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimFinalClaimDetailsPrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimHistoryItems",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.gc();
      onChange?.();
    },
    [onChange, claim]
  );

  const [sendFinalClaim, { loading: sendFinalClaimLoading }] = useGraphMutation<
    SendClaimFinalClaimDetailsMutation,
    SendClaimFinalClaimDetailsMutationVariables
  >(
    sendClaimFinalClaimDetailsMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t(
        `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
      ),
    })
  );

  const [recordFinalClaim, { loading: recordFinalClaimLoading }] =
    useGraphMutation<
      RecordClaimFinalClaimDetailsMutation,
      RecordClaimFinalClaimDetailsMutationVariables
    >(
      recordClaimFinalClaimDetailsMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityRecorded", {
        entity: t(
          `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
        ),
      })
    );

  const draftDetailedClaims = useMemo(() => {
    return (promptData?.claimFinalClaimDetailsPrompt.draftDetailedClaims ??
      []) as DraftDetailedClaim[];
  }, [promptData]);

  return {
    draftDetailedClaims,
    loading,
    sendFinalClaim,
    recordFinalClaim,
    refetchPrompt: refetch,
    actionLoading: sendFinalClaimLoading || recordFinalClaimLoading,
  };
};
