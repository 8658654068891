import { MainNavigation } from "components/MainNavigation/MainNavigation";
import { ThemeProvider } from "@mui/material";
import { extendedTheme } from "theme/extendedTheme";
import { Router } from "./Router";
import React, { useContext } from "react";
import { AuthContext } from "providers/auth.context";
import { GraphqlProvider } from "providers/graphqlProvider";
import { GlobalContextProvider } from "state-management/globalContext/Global.context";
import { useNewUIVersion } from "hooks/useNewUIVersion";
import { NewUIVersionAlert } from "components/NewUIVersionAlert";
import { ErrorBoundary } from "components/ErrorBoundary";

export const App: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { newUIVersionReleased } = useNewUIVersion();

  return (
    <>
      {newUIVersionReleased && (
        <ThemeProvider
          theme={(outerTheme) => ({
            ...outerTheme,
            ...extendedTheme,
          })}
        >
          <NewUIVersionAlert />
        </ThemeProvider>
      )}
      <GraphqlProvider>
        <GlobalContextProvider>
          <ThemeProvider
            theme={(outerTheme) => ({
              ...outerTheme,
              ...extendedTheme,
            })}
          >
            <ErrorBoundary>{isLoggedIn && <MainNavigation />}</ErrorBoundary>
          </ThemeProvider>
          <ErrorBoundary>
            <Router />
          </ErrorBoundary>
        </GlobalContextProvider>
      </GraphqlProvider>
    </>
  );
};
