import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationProposalConsentForProposedVariationMutation,
  RecordVariationProposalConsentForProposedVariationMutationVariables,
  SendVariationProposalConsentForProposedVariationMutation,
  SendVariationProposalConsentForProposedVariationMutationVariables,
  VariationProposal,
  VariationProposalConsentForProposedVariationPromptQuery,
  VariationProposalConsentForProposedVariationPromptQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationProposalConsentForProposedVariationMutation,
  sendVariationProposalConsentForProposedVariationMutation,
  variationProposalConsentForProposedVariationPromptQuery,
} from "./ConsentToProposalForProposedVariationAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useOnConsentToProposalForProposedVariationActionUpdated } from "./useOnConsentToProposalForProposedVariationActionUpdated";

export const useConsentToProposalForProposedVariationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { variation } = useContext(VariationWidgetContext);

  const { onUpdated } =
    useOnConsentToProposalForProposedVariationActionUpdated();

  const { data: promptData, loading } = useGraphQuery<
    VariationProposalConsentForProposedVariationPromptQuery,
    VariationProposalConsentForProposedVariationPromptQueryVariables
  >(variationProposalConsentForProposedVariationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [
    sendVariationProposalConsent,
    { loading: sendVariationProposalConsentLoading },
  ] = useGraphMutation<
    SendVariationProposalConsentForProposedVariationMutation,
    SendVariationProposalConsentForProposedVariationMutationVariables
  >(
    sendVariationProposalConsentForProposedVariationMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.Variations.variationProposalConsent"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordVariationProposalConsent,
    { loading: recordVariationProposalConsentLoading },
  ] = useGraphMutation<
    RecordVariationProposalConsentForProposedVariationMutation,
    RecordVariationProposalConsentForProposedVariationMutationVariables
  >(
    recordVariationProposalConsentForProposedVariationMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Variations.variationProposalConsent"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const proposals = useMemo(
    () =>
      (promptData?.variationProposalConsentForProposedVariationPrompt
        .proposals ?? []) as VariationProposal[],
    [promptData]
  );

  return {
    proposals,
    loading,
    sendVariationProposalConsent,
    recordVariationProposalConsent,
    actionLoading:
      sendVariationProposalConsentLoading ||
      recordVariationProposalConsentLoading,
  };
};
