import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  CompEventOwnAssessmentPromptQuery,
  CompEventOwnAssessmentPromptQueryVariables,
  DraftAssessment,
  RecordCompEventOwnAssessmentMutation,
  RecordCompEventOwnAssessmentMutationVariables,
  SendCompEventOwnAssessmentMutation,
  SendCompEventOwnAssessmentMutationVariables,
} from "generated/graphql";
import { recordCompEventOwnAssessmentMutation } from "graphql/mutations/recordCompEventOwnAssessment";
import { sendCompEventOwnAssessmentMutation } from "graphql/mutations/sendCompEventOwnAssessment";
import { compEventOwnAssessmentPromptQuery } from "graphql/queries/compEventOwnAssessmentPrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOnSubmitOwnAssessmentCEActionUpdated } from "./useOnSubmitOwnAssessmentCEActionUpdated";

export const useSubmitOwnAssessmentCEAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { compEvent } = useContext(CompEventWidgetContext);
  const { onUpdated } = useOnSubmitOwnAssessmentCEActionUpdated();

  const { data, refetch, loading } = useGraphQuery<
    CompEventOwnAssessmentPromptQuery,
    CompEventOwnAssessmentPromptQueryVariables
  >(compEventOwnAssessmentPromptQuery, {
    variables: {
      input: {
        compEventId: compEvent!.id,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [sendCEOwnAssessment, { loading: sendCEOwnAssessmentLoading }] =
    useGraphMutation<
      SendCompEventOwnAssessmentMutation,
      SendCompEventOwnAssessmentMutationVariables
    >(
      sendCompEventOwnAssessmentMutation,
      {
        update: onUpdated,
      },
      triggersAuthWorkflow ? (
        <AuthorizationToastTransMessage />
      ) : (
        t("common.successMessages.entitySent", {
          entity: t("Projects.CompEvents.compEventOwnAssessment"),
        })
      ),
      {
        persist: triggersAuthWorkflow,
      }
    );

  const [recordCEOwnAssessment, { loading: recordCEOwnAssessmentLoading }] =
    useGraphMutation<
      RecordCompEventOwnAssessmentMutation,
      RecordCompEventOwnAssessmentMutationVariables
    >(
      recordCompEventOwnAssessmentMutation,
      {
        update: onUpdated,
      },
      triggersAuthWorkflow ? (
        <AuthorizationToastTransMessage />
      ) : (
        t("common.successMessages.entityRecorded", {
          entity: t("Projects.CompEvents.compEventOwnAssessment"),
        })
      ),
      {
        persist: triggersAuthWorkflow,
      }
    );

  const draftAssessments = useMemo(
    () =>
      (data?.compEventOwnAssessmentPrompt
        .draftAssessments as DraftAssessment[]) ?? [],
    [data]
  );

  return {
    draftAssessments,
    loading,
    actionLoading: sendCEOwnAssessmentLoading || recordCEOwnAssessmentLoading,
    refetchPrompt: refetch,
    sendCEOwnAssessment,
    recordCEOwnAssessment,
  };
};
