import { styled, Typography, useTheme } from "@mui/material";
import { Trans } from "react-i18next";
import { useMemo } from "react";
import { Banner } from "components/Banners";

type RejectedDiaryBannerProps = {
  onClick: () => void;
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`;

export const RejectedDiaryBanner: React.FC<RejectedDiaryBannerProps> = ({
  onClick,
}) => {
  const theme = useTheme();

  const transComponentsConfig = useMemo(
    () => ({
      customLink: (
        <StyledTypography
          variant="p2"
          onClick={onClick}
          color={theme.palette.info.main}
          ml={0.5}
        ></StyledTypography>
      ),
      normal: <Typography variant="p2" color="grey.900"></Typography>,
    }),
    [onClick, theme]
  );

  // Old version. To be deleted if no regression shows up
  // <InfoBannerContainer>
  //   <Box mr={2} display="flex" alignItems="center">
  //     <Info size={24} color={theme.palette.info.main} />
  //   </Box>
  // <Trans
  //   i18nKey={"Projects.DailyDiaries.diaryRejectedBannerMessage"}
  //   components={transComponentsConfig}
  // />
  // </InfoBannerContainer>

  return (
    <Banner
      variant="info"
      content={
        <Trans
          i18nKey={"Projects.DailyDiaries.diaryRejectedBannerMessage"}
          components={transComponentsConfig}
        />
      }
    />
  );
};
