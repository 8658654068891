import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationInstructionCommenceWorkMutation,
  RecordVariationInstructionCommenceWorkMutationVariables,
  SendVariationInstructionCommenceWorkMutation,
  SendVariationInstructionCommenceWorkMutationVariables,
  VariationInstructionCommenceWorkPromptQuery,
  VariationInstructionCommenceWorkPromptQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationInstructionCommenceWorkMutation,
  sendVariationInstructionCommenceWorkMutation,
  variationInstructionCommenceWorkPromptQuery,
} from "./InstructWorkToCommenceAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useOnInstructionWorkToCommenceActionUpdated } from "./useOnInstructionWorkToCommenceActionUpdated";

export const useInstructWorkToCommenceAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { variation } = useContext(VariationWidgetContext);

  const { onUpdated } = useOnInstructionWorkToCommenceActionUpdated();

  const { data: promptData, loading } = useGraphQuery<
    VariationInstructionCommenceWorkPromptQuery,
    VariationInstructionCommenceWorkPromptQueryVariables
  >(variationInstructionCommenceWorkPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const [
    sendCommenceWorkInstruction,
    { loading: sendCommenceWorkInstructionLoading },
  ] = useGraphMutation<
    SendVariationInstructionCommenceWorkMutation,
    SendVariationInstructionCommenceWorkMutationVariables
  >(
    sendVariationInstructionCommenceWorkMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.Variations.instructionCommenceWork"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordCommenceWorkInstruction,
    { loading: recordCommenceWorkInstructionLoading },
  ] = useGraphMutation<
    RecordVariationInstructionCommenceWorkMutation,
    RecordVariationInstructionCommenceWorkMutationVariables
  >(
    recordVariationInstructionCommenceWorkMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Variations.instructionCommenceWork"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  return {
    remarks: promptData?.variationInstructionCommenceWorkPrompt.remarks ?? "",
    agreementSubmitted:
      promptData?.variationInstructionCommenceWorkPrompt.agreementSubmitted,
    loading,
    sendCommenceWorkInstruction,
    recordCommenceWorkInstruction,
    actionLoading:
      sendCommenceWorkInstructionLoading ||
      recordCommenceWorkInstructionLoading,
  };
};
