import { Banner } from "components/Banners";
import { useTranslation } from "react-i18next";

export const LockedDailyDiaryBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Banner
      variant="info"
      content={t("Projects.DailyDiaries.lockedBannerMessage")}
    />
  );
};
