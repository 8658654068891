import { css, styled } from "@mui/material";
import { unofficialThemeColors } from "theme/extendedTheme";
import { BaseBannerContainer } from "./BaseBannerContainer";

export const InfoBannerContainer = styled(BaseBannerContainer)(
  () => css`
    border: 1px solid rgba(24, 106, 222, 0.3); // Note: this is info.main color, but with 0,30 opacity
    background-color: ${unofficialThemeColors.bluePale}; // note: the same color used in notifications
  `
);
