import { useCallback, useContext, useMemo } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  claimInterimClaimDetailsPromptQuery,
  recordClaimInterimClaimDetailsMutation,
  sendClaimInterimClaimDetailsMutation,
} from "./SubmitInterimDetailedClaimAction.query";
import {
  ClaimInterimClaimDetailsPromptQuery,
  ClaimInterimClaimDetailsPromptQueryVariables,
  DraftDetailedClaim,
  RecordClaimInterimClaimDetailsMutation,
  RecordClaimInterimClaimDetailsMutationVariables,
  SendClaimInterimClaimDetailsMutation,
  SendClaimInterimClaimDetailsMutationVariables,
} from "generated/graphql";
import { OnMutationDone } from "types/decl";
import { useGraphMutation } from "hooks/useGraphMutation";

export const useSubmitInterimDetailedClaimAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const {
    data: promptData,
    refetch,
    loading,
  } = useGraphQuery<
    ClaimInterimClaimDetailsPromptQuery,
    ClaimInterimClaimDetailsPromptQueryVariables
  >(claimInterimClaimDetailsPromptQuery, {
    variables: {
      input: {
        claimId: claim?.id!,
      },
    },
    fetchPolicy: "no-cache",
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "claimItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDetailsExplorerItems",
      });
      // query never used
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimDraftDetailedClaims",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimInterimClaimDetailsPrompt",
        args: { input: { claimId: claim!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimHistoryItems",
        args: { claimId: claim!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "claimAction",
      });
      cache.gc();
      onChange?.();
    },
    [onChange, claim]
  );

  const [sendInterimClaim, { loading: sendInterimClaimLoading }] =
    useGraphMutation<
      SendClaimInterimClaimDetailsMutation,
      SendClaimInterimClaimDetailsMutationVariables
    >(
      sendClaimInterimClaimDetailsMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entitySent", {
        entity: t(
          `Projects.Claims.detailedInterimClaim${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const [recordInterimClaim, { loading: recordInterimClaimLoading }] =
    useGraphMutation<
      RecordClaimInterimClaimDetailsMutation,
      RecordClaimInterimClaimDetailsMutationVariables
    >(
      recordClaimInterimClaimDetailsMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityRecorded", {
        entity: t(
          `Projects.Claims.detailedInterimClaim${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    );

  const draftDetailedClaims = useMemo(() => {
    return (promptData?.claimInterimClaimDetailsPrompt.draftDetailedClaims ??
      []) as DraftDetailedClaim[];
  }, [promptData]);

  return {
    draftDetailedClaims,
    loading,
    sendInterimClaim,
    recordInterimClaim,
    refetchPrompt: refetch,
    actionLoading: sendInterimClaimLoading || recordInterimClaimLoading,
  };
};
