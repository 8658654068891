import {
  Product,
  ProductsQuery,
  ProductsQueryVariables,
} from "generated/graphql";
import { productsQuery } from "graphql/queries/products.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";
import { useContractLite } from "../../../hooks/useContractLite";
import {
  ContractTypeEnum,
  contractProductsMapping,
} from "../containers/Projects/constants";
import {
  isFIDIC17WhiteContractType,
  isNECContractType,
} from "containers/Projects/Projects.utils";
import { ProductTypenameToProduct } from "constants/products";

export const useContractProducts = (contractId: string) => {
  const { contractLite, contractLiteLoading } = useContractLite(contractId!);

  const { data: products, loading: productsLoading } = useGraphQuery<
    ProductsQuery,
    ProductsQueryVariables
  >(productsQuery);

  /**
   * NEC contracts have CE product and FIDIC contracts have Claims & Variations. FIDIC white doesn't have Claims
   */
  const contractBasedProducts = useMemo(() => {
    if (contractLite) {
      const contractType = isNECContractType(contractLite.contractType)
        ? ContractTypeEnum.NEC
        : isFIDIC17WhiteContractType(contractLite.contractType)
        ? ContractTypeEnum.FIDIC17White
        : ContractTypeEnum.FIDIC;

      return (
        (products?.products.items.filter((product) => {
          const productType = ProductTypenameToProduct[product.__typename];

          return contractProductsMapping[contractType].includes(productType);
        }) ?? []) as any as Product[]
      ).sort((p1, p2) => p1.name.localeCompare(p2.name)); // light products
    }

    return [];
  }, [contractLite, products]);

  return {
    products: contractBasedProducts,
    loading: contractLiteLoading || productsLoading,
  };
};
