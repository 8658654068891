import { dateISOFormat } from "constants/constants";
import {
  ContractKeyDate,
  ContractSection,
  EditContractKeyDateInput,
  EditContractSectionInput,
} from "generated/graphql";
import moment from "moment";

export const contractSectionToEditContractSectionInput = (
  contractSection: ContractSection
): EditContractSectionInput => ({
  id: contractSection.id,
  number: contractSection.number,
  description: contractSection.description,
  completionDate: moment(contractSection.completionDate).format(dateISOFormat),
  status: contractSection.status,
});

export const contractKeyDateToEditContractKeyDateInput = (
  contractKeyDate: ContractKeyDate
): EditContractKeyDateInput => ({
  id: contractKeyDate.id,
  number: contractKeyDate.number,
  status: contractKeyDate.status,
  conditionToBeMet: contractKeyDate.conditionToBeMet,
  keyDate: moment(contractKeyDate.keyDate).format(dateISOFormat),
});
