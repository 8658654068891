import { Box } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  AttachmentInput,
  CompEventOwnAssessmentPrompt,
  EditDraftAssessmentInput,
  AddDraftAssessmentInput,
  DraftAssessment,
  CompEventAssessment,
} from "generated/graphql";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { TableHeader } from "../../../../ActionModal/TableHeader";
import { AssessmentsTable } from "./AssessmentsTable/AssessmentsTable";
import { RecordCEOwnAssessment } from "./RecordCEOwnAssessment";
import { SendCEOwnAssessment } from "./SendCEOwnAssessment";
import { useSubmitOwnAssessmentCEAction } from "./useSubmitOwnAssessmentCEAction";
import { CEActionFormProps } from "../CompEventAction/CompEventActionView";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { QuotationAssessmentFormDataType } from "../../../CEQuotationAssessmentModal/CEQuotationAssessment/CEQuotationAssessment.decl";
import {
  CEQuotationAssessmentModal,
  CEQuotationAssessmentModalType,
} from "../../../CEQuotationAssessmentModal/CEQuotationAssessmentModal";

const defaultFormData: CompEventOwnAssessmentPrompt = {
  draftAssessments: [],
};

export const SubmitOwnAssessmentCEAction: React.FC<
  CEActionFormProps & { triggersAuthWorkflow?: boolean }
> = ({ apiRef, triggersAuthWorkflow, onClose }) => {
  const { compEvent, contract } = useContext(CompEventWidgetContext);

  const [formData, setFormData] =
    useState<CompEventOwnAssessmentPrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    sendCEOwnAssessment,
    recordCEOwnAssessment,
    refetchPrompt,
    draftAssessments: promptDraftOwnAssessments,
    loading: submitOwnAssessmentLoading,
    actionLoading,
  } = useSubmitOwnAssessmentCEAction(triggersAuthWorkflow);

  const {
    modalVisibility: assessmentModalVisibility,
    toggleModalVisibility: toggleAssessmentModalVisibility,
    loading: assessmentsLoading,
    addDraftAssessment,
    editDraftAssessment,
    removeDraftAssessment,
  } = useCEQuotationAssessmentModal(refetchPrompt);

  const assessmentIds = useMemo(
    () => formData.draftAssessments.map((assessment) => assessment.id),
    [formData]
  );

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendCEOwnAssessment = async () => {
    await sendCEOwnAssessment({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          draftAssessmentId: assessmentIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordCEOwnAssessment = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordCEOwnAssessment({
      variables: {
        input: {
          compEventId: compEvent?.id!,
          draftAssessmentId: assessmentIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftAssessments.length,
    [formData]
  );

  const handleCreateEditOwnAssessment = useCallback(
    async (
      quotationAssessment: QuotationAssessmentFormDataType,
      keepModalOpen?: boolean
    ) => {
      const assessmentData = quotationAssessment as
        | AddDraftAssessmentInput
        | EditDraftAssessmentInput;

      if (formData.draftAssessments.length) {
        // edit mode
        const { data } = await editDraftAssessment({
          variables: { input: assessmentData as EditDraftAssessmentInput },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftAssessments: [data.editDraftAssessment as DraftAssessment],
          }));
        }
      } else {
        // create new draft assessment
        const { data } = await addDraftAssessment({
          variables: { input: assessmentData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftAssessments: [data.addDraftAssessment as DraftAssessment],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleAssessmentModalVisibility();
      }
    },
    [
      formData,
      addDraftAssessment,
      editDraftAssessment,
      toggleAssessmentModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (draftQuotationAssessmentData: QuotationAssessmentFormDataType) => {
      updatedAttachmentsRef.current = draftQuotationAssessmentData.attachments;
      const isEditMode = !!selectedAssessmentId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditOwnAssessment(draftQuotationAssessmentData, true);
      }
    },
    [handleCreateEditOwnAssessment, selectedAssessmentId]
  );

  const handleQuotationAssessmentModalClose = () => {
    toggleAssessmentModalVisibility();
    setSelectedAssessmentId(undefined);
  };

  const handleAssessmentRowClick = (
    assessment: DraftAssessment | CompEventAssessment
  ) => {
    setSelectedAssessmentId((assessment as DraftAssessment).id);
    toggleAssessmentModalVisibility();
  };

  const handleDeleteDraftAssessment = useCallback(
    (assessmentId: string) => {
      removeDraftAssessment({ variables: { id: assessmentId } });
    },
    [removeDraftAssessment]
  );

  const handleNewAssessmentClick = () => {
    setSelectedAssessmentId(undefined);
    toggleAssessmentModalVisibility();
  };

  const selectedDraftAssessment = useMemo(
    () =>
      formData.draftAssessments.find(
        (assessment) => assessment.id === selectedAssessmentId
      ),
    [formData, selectedAssessmentId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftOwnAssessments) {
      setFormData({
        draftAssessments: promptDraftOwnAssessments,
      });
    }
  }, [promptDraftOwnAssessments]);

  return !selectedAssessmentId &&
    (assessmentsLoading || submitOwnAssessmentLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {assessmentModalVisibility && (
        <CEQuotationAssessmentModal
          open={assessmentModalVisibility}
          type={CEQuotationAssessmentModalType.Assessment}
          draftAssessment={selectedDraftAssessment}
          contractCurrency={contract.valueCurrency ?? ""}
          contractKeyDates={contract.keyDates}
          contractSections={contract.sections}
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditOwnAssessment}
          onClose={handleQuotationAssessmentModalClose}
          onSecondaryClick={handleQuotationAssessmentModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendCEOwnAssessment
          open={sendModalVisibility}
          draftAssessments={formData.draftAssessments}
          onPrimaryClick={handleSendCEOwnAssessment}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordCEOwnAssessment
          open={recordModalVisibility}
          draftAssessments={formData.draftAssessments}
          onPrimaryClick={handleRecordCEOwnAssessment}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="Assessment"
          onAdd={handleNewAssessmentClick}
          disabled={!!formData.draftAssessments.length}
        />
        <AssessmentsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={assessmentsLoading}
          assessments={formData.draftAssessments}
          onRowClick={handleAssessmentRowClick}
          onDelete={handleDeleteDraftAssessment}
        />
      </Box>
    </>
  );
};
