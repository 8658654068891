import { Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

type ContractDetailsHeaderTitleProps = {
  loading?: boolean;
  friendlyName?: string;
  number?: string;
};

export const ContractDetailsHeaderTitle: React.FC<
  ContractDetailsHeaderTitleProps
> = ({ friendlyName, number, loading }) => {
  return (
    <Stack
      direction="column"
      spacing={0.25}
      height="42px"
      justifyContent="center"
    >
      {loading ? (
        <Skeleton variant="text" sx={{ fontSize: "16px" }} width={150} />
      ) : (
        <Typography
          variant="h3"
          color="grey.800"
          fontSize="16px"
          lineHeight="20px"
        >
          {friendlyName}
        </Typography>
      )}

      {loading ? (
        <Skeleton variant="text" sx={{ fontSize: "13px" }} width={130} />
      ) : number ? (
        <Typography variant="p2" color="grey.600">
          {number}
        </Typography>
      ) : null}
    </Stack>
  );
};
