import { Color, css, styled, useTheme } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GRID_DEFAULT_LOCALE_TEXT,
  GridAutosizeOptions,
} from "@mui/x-data-grid-pro";

const customCheckboxColStyle = css`
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
`;

const LocalDataGrid = styled(DataGridPro)<{ clickable?: boolean }>(
  ({ theme, clickable = false }) => {
    return css`
      && {
        .MuiDataGrid-columnHeaders {
          background: ${theme.palette.grey[300]};

          .MuiDataGrid-columnHeaderCheckbox {
            ${customCheckboxColStyle}
          }
        }
        .MuiDataGrid-cell.greyed-out {
          color: ${theme.palette.action.disabled};
        }

        .MuiDataGrid-row {
          min-height: 52; // Note: default from MUI. Re-setting it for grids with height 'auto'
          ${clickable &&
          `&:hover {
          cursor: pointer;
        }`}
          .visible-on-hover {
            visibility: hidden;
          }

          .MuiDataGrid-cellCheckbox {
            ${customCheckboxColStyle}
          }

          .Mui-error {
            background-color: rgb(126, 10, 15, 0.1); // TODO use theme values
            color: #750f0f; // TODO use theme values
            height: 100%;
          }
        }

        .MuiDataGrid-row:hover {
          .MuiDataGrid-cell:not(.greyed-out) {
            .visible-on-hover {
              visibility: visible;
            }
          }
        }
      }
    `;
  }
);

const LocalNewDataGrid = styled(DataGridPro)<{ clickable?: boolean }>(
  ({ theme, clickable = false }) => {
    return css`
      && {
        &.MuiDataGrid-root {
          border-radius: 8px;
          border-color: ${theme.palette.grey[100]};
          background: white;

          .MuiDataGrid-filler {
            background: white;
          }

          .MuiDataGrid-cell {
            display: flex;
            align-items: center;
            overflow: visible;

            &.selected {
              background: ${(theme.palette.secondary as Partial<Color>)[100]};
            }
          }

          .MuiDataGrid-columnHeaders {
            .MuiDataGrid-columnHeader {
              background: white;
            }

            .MuiDataGrid-columnHeaderCheckbox {
              ${customCheckboxColStyle}
            }
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .MuiDataGrid-columnHeaderTitle {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: ${theme.palette.grey[600]};
            }

            .MuiDataGrid-columnSeparator {
              opacity: 0 !important;
            }
          }

          .MuiDataGrid-row {
            .visible-on-hover {
              visibility: hidden;
            }

            .MuiDataGrid-cellCheckbox {
              ${customCheckboxColStyle}
            }
          }

          .MuiDataGrid-row:hover {
            background: ${theme.palette.grey[200]};
            cursor: ${clickable ? "pointer" : "default"};

            .MuiDataGrid-cell:not(.greyed-out) {
              .visible-on-hover {
                visibility: visible;
              }
            }
          }
          .MuiDataGrid-row.Mui-selected {
            background: ${(theme.palette.secondary as Partial<Color>)[100]};
          }

          .MuiDataGrid-cellContent {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${theme.palette.grey[600]};
          }

          .MuiDataGrid-cell--withRenderer {
            overflow: visible;
          }
        }
      }
    `;
  }
);

// AdminConsole grid
export const StyledDataGrid = (
  props: DataGridProProps<any> & React.RefAttributes<HTMLDivElement>
) => {
  return (
    <LocalDataGrid
      autosizeOnMount
      autosizeOptions={{
        includeHeaders: true,
        includeOutliers: true,
        expand: true,
      }}
      {...props}
      clickable={!!props.onRowClick}
      localeText={{
        ...GRID_DEFAULT_LOCALE_TEXT,
        ...props.localeText,
      }}
    />
  );
};

// Projects Grid
export const NewStyledDataGrid = (
  props: DataGridProProps<any> &
    React.RefAttributes<HTMLDivElement> & {
      disableSelectAll?: boolean;
      error?: boolean;
    }
) => {
  const theme = useTheme();

  return (
    <LocalNewDataGrid
      {...props}
      clickable={!!props.onRowClick}
      localeText={{
        ...GRID_DEFAULT_LOCALE_TEXT,
        ...props.localeText,
      }}
      {...(props.disableSelectAll
        ? {
            sx: {
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                  display: "none",
                },
              "&.MuiDataGrid-root": {
                borderColor: props.error
                  ? `${theme.palette.error.main} !important`
                  : undefined,
              },
            },
          }
        : {})}
    />
  );
};

const paddingDataCellHeaderStyle = css`
  max-width: 14px !important;
  min-width: 14px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &:focus {
    outline: none;
  }
`;

const StyledDataGridLight = styled(DataGridPro)<{
  clickable?: boolean;
  stickyHeader?: boolean; // headers are sticky by default, but not if autoHeight = true. This fixes this situation
}>(({ theme, clickable = false, stickyHeader = false }) => {
  return css`
    && {
      &.MuiDataGrid-root {
        border: none;
        background: white;

        .MuiDataGrid-filler {
          background: white;
        }

        ${stickyHeader &&
        css`
          .MuiDataGrid-main {
            overflow: unset;
          }
        `}

        .MuiDataGrid-cell {
          min-height: 36px !important;
          box-sizing: border-box;
          padding: ${theme.spacing(1)} 10px;
          justify-content: flex-start;
          align-items: flex-start;

          &.padding-cell {
            ${paddingDataCellHeaderStyle}
          }

          .MuiButtonBase-root.MuiCheckbox-root {
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: -8px;
            margin-bottom: -8px;
            margin-left: -3px;
          }

          & > * {
            box-sizing: border-box;
          }

          &.selected {
            background: ${(theme.palette.secondary as Partial<Color>)[100]};
          }
        }

        .MuiDataGrid-columnHeaders {
          .MuiDataGrid-columnHeader {
            background: white;

            &.padding-header {
              ${paddingDataCellHeaderStyle}
            }
          }
          background: white;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .MuiDataGrid-columnHeaderCheckbox {
            ${customCheckboxColStyle}
          }

          ${stickyHeader &&
          css`
            position: sticky;
            top: 0;
            background: white;
            z-index: 2;
          `}

          .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: ${theme.palette.grey[600]};
          }

          .MuiDataGrid-columnSeparator {
            opacity: 0 !important;
          }
        }

        .MuiDataGrid-row {
          .visible-on-hover {
            visibility: hidden;
          }

          .MuiDataGrid-cell:focus {
            outline: none;
          }

          .MuiDataGrid-cell--withRenderer {
            overflow: visible;
            outline: none;
          }

          .MuiDataGrid-cellCheckbox {
            ${customCheckboxColStyle}
          }
        }

        .MuiDataGrid-row:hover {
          background: transparent;
          cursor: ${clickable ? "pointer" : "default"};

          .MuiDataGrid-cell:not(.greyed-out) {
            .visible-on-hover {
              visibility: visible;
            }
          }
        }
        .MuiDataGrid-row.Mui-selected {
          background: ${(theme.palette.secondary as Partial<Color>)[100]};
        }

        .MuiDataGrid-cell,
        .MuiDataGrid-cellContent {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.palette.grey[600]};
        }
      }
    }
  `;
});

// Diary sections grid
export const DataGridLight = (
  props: DataGridProProps<any> &
    React.RefAttributes<HTMLDivElement> & {
      stickyHeader?: boolean;
      autosizeOnMount?: boolean;
      autosizeOptions?: GridAutosizeOptions;
    }
) => {
  return (
    <StyledDataGridLight
      {...props}
      clickable={!!props.onRowClick}
      stickyHeader={props.stickyHeader}
      autosizeOnMount={
        typeof props.autosizeOnMount === "undefined" || props.autosizeOnMount
      }
      autosizeOptions={
        props.autosizeOptions ?? {
          includeHeaders: true,
          includeOutliers: true,
          expand: true,
        }
      }
      getRowHeight={() => "auto"}
      localeText={{
        ...GRID_DEFAULT_LOCALE_TEXT,
        ...props.localeText,
      }}
    />
  );
};
