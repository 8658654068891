import { IntercomEvents } from "constants/intercom";
import { useCallback } from "react";
import { useIntercom } from "react-use-intercom";

export const useContractDetailsEvent = () => {
  const { trackEvent } = useIntercom();

  const trackContractDetailsEvent = useCallback(
    (contractFriendlyName: string, projectFriendlyName: string) => {
      trackEvent(IntercomEvents.ViewedContractDetails, {
        Project: projectFriendlyName,
        Contract: contractFriendlyName,
      });
    },
    [trackEvent]
  );

  return {
    trackContractDetailsEvent,
  };
};
