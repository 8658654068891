import {
  AddContractSectionInput,
  ContractSection,
  ContractSectionStatus,
  EditContractSectionInput,
  EditContractSectionsMutation,
  EditContractSectionsMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { contractSectionToEditContractSectionInput } from "../ContractDetails.utils";
import { snackbarAutoHideDuration } from "constants/constants";
import { editContractSectionsMutation } from "./ContractSections.query";

export type useContractSectionsArgs = {
  contractId?: string;
  contractSections?: ContractSection[];
};

export const useContractSections = ({
  contractId,
  contractSections,
}: useContractSectionsArgs) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editContractSections, { loading }] = useGraphMutation<
    EditContractSectionsMutation,
    EditContractSectionsMutationVariables
  >(
    editContractSectionsMutation,
    {
      update: (cache, result, meta) => {
        if (!meta.variables || result.errors?.length || !result.data) {
          return;
        }

        const contractCacheItemIdentifier = cache.identify({
          __typename: "Contract",
          id: meta.variables.input.id,
        });

        cache.modify({
          id: contractCacheItemIdentifier,
          fields: {
            sections() {
              return result.data?.editContractSections.sections;
            },
          },
        });

        cache.gc();
      },
    },
    null
  );

  const handleAddSection = useCallback(
    async (newSection: AddContractSectionInput) => {
      if (!contractId) {
        return;
      }

      const sectionsToUpdate =
        contractSections?.map((section) =>
          contractSectionToEditContractSectionInput(section)
        ) ?? [];

      sectionsToUpdate.push({
        ...newSection,
        status: ContractSectionStatus.Active,
      });

      const { data } = await editContractSections({
        variables: {
          input: {
            id: contractId,
            sections: sectionsToUpdate,
          },
        },
      });

      if (data) {
        enqueueSnackbar(
          t("common.successMessages.entityCreated", {
            entity: t("AdminConsole.ContractSections.contractSection"),
          }),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContractSections, contractSections, contractId, t, enqueueSnackbar]
  );

  const handleEditSection = useCallback(
    async (updatedSection: EditContractSectionInput) => {
      if (!contractId) {
        return;
      }

      const isDeleteCase =
        updatedSection.status === ContractSectionStatus.Removed;

      let sectionsToUpdate =
        contractSections?.map((section) =>
          contractSectionToEditContractSectionInput(section)
        ) ?? [];

      if (isDeleteCase) {
        sectionsToUpdate = sectionsToUpdate?.filter(
          (section) => section.id !== updatedSection.id
        );
      } else {
        sectionsToUpdate = sectionsToUpdate?.map((section) =>
          section.id === updatedSection.id ? updatedSection : section
        );
      }

      const { data } = await editContractSections({
        variables: {
          input: {
            id: contractId,
            sections: sectionsToUpdate,
          },
        },
      });

      if (data) {
        enqueueSnackbar(
          t(
            `common.successMessages.${
              isDeleteCase ? "entityDeleted" : "entityUpdated"
            }`,
            {
              entity: t("AdminConsole.ContractSections.contractSection"),
            }
          ),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContractSections, contractId, contractSections, t, enqueueSnackbar]
  );

  return {
    addSection: handleAddSection,
    editSection: handleEditSection,
    loading,
  };
};
