import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

const ErrorView = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={6}
      flex={1}
    >
      <Box flex={1} justifyContent="center" display="flex">
        <Typography
          variant="h5"
          maxWidth={400}
          color="grey.800"
          textAlign="center"
        >
          {t("common.errorMessages.generic")}
        </Typography>
      </Box>
      <Box flex={1}>
        <img src="./maintenance-img.svg" alt="under construction" />
      </Box>
    </Box>
  );
};

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ReactErrorBoundary fallback={<ErrorView />}>{children}</ReactErrorBoundary>
  );
};
