import { useGlobalStore } from "state-management/store/globalStore";
import { ContractDetails } from "./ContractDetails";
import { Dialog } from "@mui/material";

export const ContractDetailsModal = () => {
  const { contractDetailsPopupVisible, setContractDetailsPopupVisible } =
    useGlobalStore((state) => ({
      contractDetailsPopupVisible: state.contractDetailsPopupVisible,
      setContractDetailsPopupVisible: state.setContractDetailsPopupVisible,
    }));

  const handleClose = () => {
    setContractDetailsPopupVisible(false);
  };

  return contractDetailsPopupVisible ? (
    <Dialog
      open={contractDetailsPopupVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
      PaperProps={{ className: "contract-details-modal" }}
    >
      <ContractDetails onClose={handleClose} />
    </Dialog>
  ) : null;
};
