import { useProjectLite } from "../containers/AdminConsole/hooks/useProjectLite";
import {
  isNEC4ECCContractType as isNEC4ECCContractTypeFn,
  isNECContractType,
  isNEC4PSCContractType as isNEC4PSCContractTypeFn,
} from "containers/Projects/Projects.utils";
import { useProductInstanceSchema } from "./useProductInstanceSchema";
import { useContractLite } from "./useContractLite";

export const useProductItemContext = (
  projectId: string,
  contractId: string,
  productInstanceId: string
) => {
  const { contractLite, contractLiteLoading } = useContractLite(contractId);
  const { projectDataLite, loading: projectDataLoading } =
    useProjectLite(projectId);
  const { productInstanceData, loading: productInstanceDataLoading } =
    useProductInstanceSchema(productInstanceId);

  return {
    contractDataLite: contractLite,
    contractDataLoading: contractLiteLoading,
    isNECContractType: isNECContractType(contractLite?.contractType),
    isNEC4ECCContractType: isNEC4ECCContractTypeFn(
      contractLite
        ? {
            description: contractLite.contractType.description,
            subType: contractLite.contractType.subType,
            version: contractLite.contractType.version,
          }
        : undefined
    ),
    isNEC4PSCContractType: isNEC4PSCContractTypeFn(
      contractLite
        ? {
            description: contractLite.contractType.description,
            subType: contractLite.contractType.subType,
            version: contractLite.contractType.version,
          }
        : undefined
    ),
    contractTypeId: contractLite?.contractTypeId,
    projectDataLite,
    projectDataLoading,
    productInstanceData,
    productInstanceDataLoading,
  };
};
