import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  DraftVariationDetermination,
  RecordVariationDeterminationNoticeMutation,
  RecordVariationDeterminationNoticeMutationVariables,
  SendVariationDeterminationNoticeMutation,
  SendVariationDeterminationNoticeMutationVariables,
  VariationDeterminationNoticePromptQuery,
  VariationDeterminationNoticePromptQueryVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationDeterminationNoticeMutation,
  sendVariationDeterminationNoticeMutation,
  variationDeterminationNoticePromptQuery,
} from "./NotifyDeterminationOfVariationAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useOnNotifyDeterminationOfVariationActionUpdated } from "./useOnNotifyDeterminationOfVariationActionUpdated";

export const useNotifyDeterminationOfVariationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { variation } = useContext(VariationWidgetContext);
  const { onUpdated } = useOnNotifyDeterminationOfVariationActionUpdated();

  const {
    data: promptData,
    refetch,
    loading,
  } = useGraphQuery<
    VariationDeterminationNoticePromptQuery,
    VariationDeterminationNoticePromptQueryVariables
  >(variationDeterminationNoticePromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [
    sendVariationDetermination,
    { loading: sendVariationDeterminationLoading },
  ] = useGraphMutation<
    SendVariationDeterminationNoticeMutation,
    SendVariationDeterminationNoticeMutationVariables
  >(
    sendVariationDeterminationNoticeMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t("Projects.Variations.variationDetermination"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordVariationDetermination,
    { loading: recordVariationDeterminationLoading },
  ] = useGraphMutation<
    RecordVariationDeterminationNoticeMutation,
    RecordVariationDeterminationNoticeMutationVariables
  >(
    recordVariationDeterminationNoticeMutation,
    { update: onUpdated },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Variations.variationDetermination"),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftVariationDeterminations = useMemo(
    () =>
      (promptData?.variationDeterminationNoticePrompt
        .draftVariationDeterminations ?? []) as DraftVariationDetermination[],
    [promptData]
  );

  return {
    draftVariationDeterminations,
    loading,
    refetchPrompt: refetch,
    sendVariationDetermination,
    recordVariationDetermination,
    actionLoading:
      sendVariationDeterminationLoading || recordVariationDeterminationLoading,
  };
};
