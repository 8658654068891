import { gql } from "@apollo/client";

export const contractViewQuery = gql`
  query contractView($id: ID!) {
    contractView(id: $id) {
      contractId
      name
      friendlyName
      number
      valueCurrency
      value
      startDate
      endDate
      contractType
      contractTypeDescription
      contractTypeSubType
      contractTypeVersion
      project
      projectFriendlyName
      country
      province
      timeZone
      status
      unconfirmedTime
      unconfirmedPrice
      confirmedTime
      confirmedPrice
      sectionalChanges {
        number
        description
        originalCompletionDate
        confirmedTime
        unconfirmedTime
        confirmedCompletionDate
        unconfirmedCompletionDate
      }
      keyDateChanges {
        number
        conditionToBeMet
        originalKeyDate
        confirmedTime
        unconfirmedTime
        confirmedKeyDate
        unconfirmedKeyDate
      }
      confirmedEndDate
      unconfirmedEndDate
      confirmedValue
      unconfirmedValue
    }
  }
`;
