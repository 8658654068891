import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { attachmentsToAttachmentInputs } from "containers/Projects/components/Attachments/Attachments.utils";
import {
  useAttachments,
  AttachmentOperation,
} from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  ItemDataInput,
  ProductSchema,
  ProductType,
} from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useHasAccess } from "hooks/useHasAccess";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useCallback, useEffect, useState, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { EditRiskEventHeader } from "../../components/Header/EditRiskEventHeader/EditRiskEventHeader";
import { ExplorerContext } from "containers/Projects/components/Explorer/Explorer.context";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { noop } from "helpers/miscelaneous";
import { useEditRiskTrackingEvents } from "./hooks/useEditRiskTrackingEvents";
import { IntercomEvents } from "constants/intercom";
import { useEditRisk } from "./hooks/useEditRisk";
import { useHeaderLinks } from "containers/Projects/hooks/useHeaderLinks";

export const EditRisk = () => {
  const { productInstanceId, riskItemId } = useParams();
  const navigate = useNavigate();
  const onNavigateBack = useNavigateBack();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const {
    attachmentsContainerRef,
    commentsContainerRef,
    commentsCount,
    handleCommentsLoaded,
    hasMoreComments,
    handleAttachmentsClick,
    handleCommentsClick,
  } = useHeaderLinks();

  const { trackEditRiskDetailsEvent } = useEditRiskTrackingEvents();

  const [schemaValues, setSchemaValues] = useState<ItemDataInput | null>({
    sections: [],
  });
  const [schemaValid, setSchemaValid] = useState<boolean>();

  const {
    riskItem,
    riskItemError,
    riskItemLoading,
    editRiskLoading,
    doEditRisk,
  } = useEditRisk();

  const isCurrentUserOwner = authenticatedUser?.id === riskItem?.ownerId;
  const hasAccess =
    useHasAccess(undefined, [PermissionEnum.Edit], productInstanceId!) ||
    isCurrentUserOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserOwner;

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[],
    operation: AttachmentOperation
  ) => {
    await handleSaveRisk(attachmentsUpdated, false, operation);
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((riskItem?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const handleSaveRisk = useCallback(
    async (
      updatedAttachments?: AttachmentInput[],
      navigateBack: boolean = true,
      operation?: AttachmentOperation
    ) => {
      if (!riskItem || !riskItemId || !schemaValues) return;

      if (schemaValid) {
        await doEditRisk({
          variables: {
            input: {
              id: riskItemId,
              data: schemaValues,
              attachments:
                updatedAttachments ??
                attachmentsToAttachmentInputs(
                  (riskItem.attachments as Attachment[]) ?? []
                ),
            },
          },
          ...(updatedAttachments && {
            optimisticResponse: {
              editRiskItem: {
                ...riskItem,
                __typename: "RiskItem",
                attachments:
                  operation === AttachmentOperation.Delete &&
                  riskItem.attachments
                    ? riskItem.attachments.filter((attach) =>
                        updatedAttachments.find(
                          (crtAttach) => crtAttach.id === attach.id
                        )
                      )
                    : riskItem.attachments,
              },
            },
          }),
        });

        if (navigateBack) {
          onNavigateBack();
        }
      }
    },
    [
      doEditRisk,
      riskItemId,
      schemaValues,
      schemaValid,
      onNavigateBack,
      riskItem,
    ]
  );

  const handleCommentAdded = (noOfMentions: number) => {
    riskItem &&
      trackEditRiskDetailsEvent(IntercomEvents.AddedComment, riskItem, {
        Product: riskItem.productInstance.product.name,
        Mentions: noOfMentions,
      });
  };

  useEffect(() => {
    if (riskItem?.data) {
      setSchemaValues(riskItem.data);
    }
  }, [riskItem]);

  useEffect(() => {
    if (riskItem) {
      changeExplorerEntities({
        projectId: riskItem.productInstance.contract.project.id,
        contractId: riskItem.productInstance.contract.id,
        productId: riskItem.productInstance.product.id,
        productInstanceId: riskItem.productInstanceId,
      });
    }
  }, [changeExplorerEntities, riskItem]);

  useEffect(() => {
    setExplorerDataLoading(true);
  }, [setExplorerDataLoading]);

  useEffect(() => {
    if (riskItem) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, riskItem]);

  useEffect(() => {
    if (riskItemError && !riskItem) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [riskItemError, riskItem, navigate, clearExplorerData]);

  if (!hasAccess && !!riskItem) {
    return <Navigate to={NewAppPaths.authorized.Projects.path} replace />;
  }

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        contractTimezone={riskItem?.productInstance.contract.timeZone}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <EditRiskEventHeader
            onCancel={onNavigateBack}
            onSave={() => handleSaveRisk()}
            disabled={riskItemLoading}
            loading={editRiskLoading}
            onDuplicate={noop}
            onMoveToProject={noop}
            attachmentsCount={allAttachments.length}
            commentsCount={commentsCount}
            hasMoreComments={hasMoreComments}
            onAttachmentsClick={handleAttachmentsClick}
            onCommentsClick={handleCommentsClick}
          />
          <Box mt={3}>
            {riskItemLoading || schemaValues === null || !riskItem ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode
                productItemType={ProductType.RisksRegister}
                schema={riskItem.productInstance.productSchema as ProductSchema}
                schemaValues={schemaValues}
                onSchemaValuesChange={setSchemaValues}
                onSchemaValidityChange={setSchemaValid}
                contractCurrency={
                  riskItem.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  riskItem.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={riskItem.productInstanceId}
                mainColumnExtraWidgetsBottom={
                  <>
                    <Grid item xs={12} key={"attachments"} position="relative">
                      <SectionContainer ref={attachmentsContainerRef}>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={riskItem?.productInstance.contract.timeZone}
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key={"comments"} position="relative">
                      <SectionContainer ref={commentsContainerRef}>
                        <Comments
                          productType={ProductType.RisksRegister}
                          productItemId={riskItemId!}
                          productInstanceId={productInstanceId!}
                          timezone={riskItem?.productInstance.contract.timeZone}
                          onCommentAdded={handleCommentAdded}
                          onCommentsLoaded={handleCommentsLoaded}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
