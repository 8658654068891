import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  AuthorizationWorkflow,
  AuthorizationWorkflowAudit,
} from "generated/graphql";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { Eye, Question } from "phosphor-react";
import { AuthorizationLevels } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/AuthorizationLevels";
import { AuthorizationPreview } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationPreview";
import React from "react";

export const ReviewersPanel = React.forwardRef(
  (
    {
      authAudit,
      authWorkflow,
      contractTimezone,
      loading,
    }: {
      authAudit?: AuthorizationWorkflowAudit;
      authWorkflow?: AuthorizationWorkflow;
      contractTimezone?: string;
      loading?: boolean;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <Box width="100%" key="reviewers-section" position="relative" ref={ref}>
        {loading ? (
          <CenteredLoadingIndicator />
        ) : (
          <CollapsibleSectionContainer
            collapsible
            title={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Eye size={24} color={theme.palette.grey[900]} />
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="h3" color="grey.900">
                    {t("common.labels.reviewers")}
                  </Typography>
                  <Tooltip
                    title={
                      <Typography variant="body2">
                        {t("Projects.DailyDiaries.reviewersInfoTooltip")}
                      </Typography>
                    }
                  >
                    <Question size={16} cursor="pointer" />
                  </Tooltip>
                </Stack>
              </Stack>
            }
          >
            {authAudit ? (
              <AuthorizationLevels authAudit={authAudit} dailyDiaryVariant />
            ) : (
              authWorkflow &&
              contractTimezone && (
                <AuthorizationPreview
                  workflow={authWorkflow}
                  contractTimezone={contractTimezone}
                />
              )
            )}
          </CollapsibleSectionContainer>
        )}
      </Box>
    );
  }
);
