import {
  Box,
  BoxProps,
  Typography,
  css,
  styled,
  useTheme,
} from "@mui/material";
import { ClockClockwise, Info } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { unofficialThemeColors } from "theme/extendedTheme";

// TODO: revisit if can reuse new components/Banner.tsx
const BannerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "clickable",
})<{
  size?: "small" | "large";
  clickable?: boolean;
}>(
  ({ theme, size = "small", clickable = false }) => css`
    padding: ${size === "small"
      ? `${theme.spacing(1)} ${theme.spacing(2)}`
      : `${theme.spacing(2)} ${theme.spacing(3)}`};
    border-radius: 8px;
    border: 1px solid rgba(24, 106, 222, 0.3); // Note: this is info.main color, but with 0,30 opacity
    background-color: ${unofficialThemeColors.bluePale}; // note: the same color used in notifications
    display: flex;
    align-items: center;
    width: ${size === "small" ? "content-fit" : "100%"};
    box-sizing: border-box;
    ${clickable
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${unofficialThemeColors.bluePaleDarker};
          }
        `
      : ""}
  `
);

export type AuthorizationBannerProps = {
  size: "small" | "large";
  stage: "notStarted" | "inProgress";
  onClick?: () => void;
} & BoxProps;

export const AuthorizationBanner: React.FC<AuthorizationBannerProps> = ({
  size,
  stage,
  onClick,
  ...restProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <BannerContainer
      size={size}
      clickable={!!onClick}
      onClick={onClick}
      {...restProps}
    >
      {stage === "notStarted" ? (
        <Info size={22} color={theme.palette.info.main} />
      ) : (
        <ClockClockwise size={22} color={theme.palette.info.main} />
      )}

      <Typography variant="p2" color="grey.900" ml={size === "small" ? 1 : 2}>
        {t(
          `Home.Authorizations.${
            stage === "notStarted"
              ? "selectedActionRequiresAuth"
              : "authorizationInProgress"
          }`
        )}
      </Typography>
    </BannerContainer>
  );
};
