import {
  ClaimDeterminationNoticePromptQuery,
  ClaimDeterminationNoticePromptQueryVariables,
  DraftClaimDetermination,
  RecordClaimDeterminationNoticeMutation,
  RecordClaimDeterminationNoticeMutationVariables,
  SendClaimDeterminationNoticeMutation,
  SendClaimDeterminationNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimDeterminationNoticePromptQuery,
  recordClaimDeterminationNoticeMutation,
  sendClaimDeterminationNoticeMutation,
} from "./NotifyClaimDeterminationAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useOnNotifyClaimDeterminationUpdated } from "./useOnNotifyClaimDeterminationUpdated";

export const useNotifyClaimDeterminationAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { claim, isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const { onUpdated } = useOnNotifyClaimDeterminationUpdated();

  const {
    data: promptData,
    refetch,
    loading,
  } = useGraphQuery<
    ClaimDeterminationNoticePromptQuery,
    ClaimDeterminationNoticePromptQueryVariables
  >(claimDeterminationNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
    fetchPolicy: "no-cache",
  });

  const [
    sendClaimDeterminationNotice,
    { loading: sendClaimDeterminationNoticeLoading },
  ] = useGraphMutation<
    SendClaimDeterminationNoticeMutation,
    SendClaimDeterminationNoticeMutationVariables
  >(
    sendClaimDeterminationNoticeMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t(
          `Projects.Claims.claimDetermination${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordClaimDeterminationNotice,
    { loading: recordClaimDeterminationNoticeLoading },
  ] = useGraphMutation<
    RecordClaimDeterminationNoticeMutation,
    RecordClaimDeterminationNoticeMutationVariables
  >(
    recordClaimDeterminationNoticeMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t(
          `Projects.Claims.claimDetermination${
            isFIDIC99RedYellow ? "99RY" : ""
          }`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftClaimDeterminations = useMemo(
    () =>
      (promptData?.claimDeterminationNoticePrompt.draftClaimDeterminations ??
        []) as DraftClaimDetermination[],
    [promptData]
  );

  return {
    draftClaimDeterminations,
    loading,
    actionLoading:
      sendClaimDeterminationNoticeLoading ||
      recordClaimDeterminationNoticeLoading,
    refetchPrompt: refetch,
    sendClaimDeterminationNotice,
    recordClaimDeterminationNotice,
  };
};
