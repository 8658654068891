import {
  ClaimAgreementNoticePromptQuery,
  ClaimAgreementNoticePromptQueryVariables,
  DraftClaimAgreement,
  RecordClaimAgreementNoticeMutation,
  RecordClaimAgreementNoticeMutationVariables,
  SendClaimAgreementNoticeMutation,
  SendClaimAgreementNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimAgreementNoticePromptQuery,
  recordClaimAgreementNoticeMutation,
  sendClaimAgreementNoticeMutation,
} from "./NotifyAgreementClaimAction.query";
import { AuthorizationToastTransMessage } from "components/Authorization/AuthorizationToastTransMessage";
import { useOnNotifyAgreementClaimActionUpdated } from "./useOnNotifyAgreementClaimActionUpdated";

export const useNotifyAgreementClaimAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { claim, isFIDIC99RedYellow } = useContext(ClaimWidgetContext);

  const { onUpdated } = useOnNotifyAgreementClaimActionUpdated();

  const {
    data: promptData,
    refetch,
    loading,
  } = useGraphQuery<
    ClaimAgreementNoticePromptQuery,
    ClaimAgreementNoticePromptQueryVariables
  >(claimAgreementNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
    fetchPolicy: "no-cache",
  });

  const [
    sendClaimAgreementNotice,
    { loading: sendClaimAgreementNoticeLoading },
  ] = useGraphMutation<
    SendClaimAgreementNoticeMutation,
    SendClaimAgreementNoticeMutationVariables
  >(
    sendClaimAgreementNoticeMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entitySent", {
        entity: t(
          `Projects.Claims.claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const [
    recordClaimAgreementNotice,
    { loading: recordClaimAgreementNoticeLoading },
  ] = useGraphMutation<
    RecordClaimAgreementNoticeMutation,
    RecordClaimAgreementNoticeMutationVariables
  >(
    recordClaimAgreementNoticeMutation,
    {
      update: onUpdated,
    },
    triggersAuthWorkflow ? (
      <AuthorizationToastTransMessage />
    ) : (
      t("common.successMessages.entityRecorded", {
        entity: t(
          `Projects.Claims.claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
        ),
      })
    ),
    {
      persist: triggersAuthWorkflow,
    }
  );

  const draftClaimAgreements = useMemo(
    () =>
      (promptData?.claimAgreementNoticePrompt.draftClaimAgreements ??
        []) as DraftClaimAgreement[],
    [promptData]
  );

  return {
    draftClaimAgreements,
    loading,
    actionLoading:
      sendClaimAgreementNoticeLoading || recordClaimAgreementNoticeLoading,
    refetchPrompt: refetch,
    sendClaimAgreementNotice,
    recordClaimAgreementNotice,
  };
};
