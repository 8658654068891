import { SectionTitle } from "./SectionHeader";
import { useTranslation } from "react-i18next";
import { ContractDetailsSectionContainer } from "./ContractDetailsSectionContainer";
import {
  Divider,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { CalendarBlank, CalendarCheck, CalendarX, Info } from "phosphor-react";
import React from "react";
import { customShadows, unofficialThemeColors } from "theme/extendedTheme";
import moment from "moment";
import { dateISOFormat } from "constants/constants";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";

type TimeSectionProps = {
  isNec: boolean;
  isFidicRedYellow: boolean;
  isFidicWhite: boolean;
  endDate: string;
  confirmedEndDate: string;
  unconfirmedEndDate: string;
  confirmedTime: number;
  unconfirmedTime: number;
};

export const TimeSection: React.FC<TimeSectionProps> = ({
  isNec,
  isFidicRedYellow,
  isFidicWhite,
  endDate,
  confirmedEndDate,
  unconfirmedEndDate,
  confirmedTime,
  unconfirmedTime,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const originalCompletionTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ocTooltipNEC"
        : isFidicRedYellow
        ? "ocTooltipFIDIC_RY"
        : isFidicWhite
        ? "ocTooltipFIDIC_WHITE"
        : ""
    }`
  );

  const confirmedCompletionTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ccTooltipNEC"
        : isFidicRedYellow
        ? "ccTooltipFIDIC_RY"
        : isFidicWhite
        ? "ccTooltipFIDIC_WHITE"
        : ""
    }`
  );

  const unconfirmedCompletionTooltip = t(
    `Projects.ContractDetails.${
      isNec
        ? "ucTooltipNEC"
        : isFidicRedYellow
        ? "ucTooltipFIDIC_RY"
        : isFidicWhite
        ? "ucTooltipFIDIC_WHITE"
        : ""
    }`
  );

  return (
    <ContractDetailsSectionContainer>
      <SectionTitle mb={2}>
        {t("Projects.ContractDetails.completionDate")}
      </SectionTitle>
      <Stack direction="row" width="100%">
        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CalendarBlank size={16} color={theme.palette.grey[800]} />
            <Typography color="grey.800">
              {moment(endDate).format(dateISOFormat)}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.originalCompletion")}
            </Typography>
            <Tooltip title={originalCompletionTooltip}>
              <Info size={16} color={theme.palette.grey[600]} />
            </Tooltip>
          </Stack>
        </Stack>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: theme.palette.grey[200],
            mx: theme.spacing(2.5),
            my: theme.spacing(1),
          }}
        />

        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CalendarCheck size={16} color={theme.palette.success.main} />
            <LocalTooltip value={confirmedTime}>
              <Typography>
                {moment(confirmedEndDate).format(dateISOFormat)}
              </Typography>
            </LocalTooltip>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.confirmed")}
            </Typography>
            <Tooltip title={confirmedCompletionTooltip}>
              <Info size={16} />
            </Tooltip>
          </Stack>
        </Stack>

        <Stack direction="column" spacing={0.5} flex={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <CalendarX size={16} color={unofficialThemeColors.amberDarker} />
            <LocalTooltip value={unconfirmedTime}>
              <Typography>
                {moment(unconfirmedEndDate).format(dateISOFormat)}
              </Typography>
            </LocalTooltip>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="p2" color="grey.600">
              {t("Projects.ContractDetails.unconfirmed")}
            </Typography>
            <Tooltip title={unconfirmedCompletionTooltip}>
              <Info size={16} />
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </ContractDetailsSectionContainer>
  );
};

export const LocalTooltip: React.FC<
  Omit<TooltipProps, "title"> & { value: number }
> = ({ children, value, ...props }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.white",
            "& .MuiTooltip-arrow": {
              color: "common.white",
            },
            boxShadow: customShadows.popoverMedium,
          },
        },
      }}
      arrow
      placement="top"
      title={<TimeTag value={value} sx={{ width: "100%" }} noBackground />}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
