import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { dateISOFormat } from "constants/constants";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { ContractViewSection } from "generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useColumns = () => {
  const { t } = useTranslation();

  return useMemo((): GridColDef<ContractViewSection>[] => {
    return [
      {
        field: "number",
        flex: 0.1,
        headerName: t("Projects.ContractDetails.number"),
      },
      {
        field: "section",
        headerName: t("Projects.ContractDetails.section"),
        flex: 0.9,
        valueGetter: (_, row) => row.description,
      },
      {
        field: "original",
        headerName: t("Projects.ContractDetails.originalCompletion"),
        width: 150,
        valueGetter: (_, row) => row.originalCompletionDate,
        valueFormatter: (value: string) => moment(value).format(dateISOFormat),
      },
      {
        field: "confirmedChange",
        headerName: t("Projects.ContractDetails.confirmedChange"),
        width: 155,
        valueGetter: (_, row) => row.confirmedTime,
        renderCell: (
          params: GridRenderCellParams<ContractViewSection, number, any>
        ) => {
          return <TimeTag value={params.value} sx={{ width: "100%" }} />;
        },
      },
      {
        field: "confirmed",
        width: 110,
        headerName: t("Projects.ContractDetails.confirmed"),
        valueGetter: (_, row) => row.confirmedCompletionDate,
        valueFormatter: (value: string) => moment(value).format(dateISOFormat),
      },
      {
        field: "unconfirmedChange",
        headerName: t("Projects.ContractDetails.unconfirmedChange"),
        width: 155,
        valueGetter: (_, row) => row.unconfirmedTime,
        renderCell: (
          params: GridRenderCellParams<ContractViewSection, number, any>
        ) => {
          return <TimeTag value={params.value} sx={{ width: "100%" }} />;
        },
      },
      {
        field: "unconfirmed",
        width: 110,
        headerName: t("Projects.ContractDetails.unconfirmed"),
        valueGetter: (_, row) => row.unconfirmedCompletionDate,
        valueFormatter: (value: string) => moment(value).format(dateISOFormat),
      },
    ];
  }, [t]);
};
