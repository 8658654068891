import { Box, Typography, useTheme } from "@mui/material";
import { Info, Warning, WarningOctagon, CheckCircle } from "phosphor-react";
import React from "react";
import { WarningBannerContainer } from "./variants/WarningBannerContainer";
import { ErrorBannerContainer } from "./variants/ErrorBannerContainer";
import { InfoBannerContainer } from "./variants/InfoBannerContainer";
import { SuccessBannerContainer } from "./variants/SuccessBannerContainer";
import { unofficialThemeColors } from "theme/extendedTheme";

type BannerProps = {
  variant: "info" | "warning" | "error" | "success";
  content: React.ReactNode;
};

export const Banner: React.FC<BannerProps> = ({ content, variant }) => {
  const theme = useTheme();

  const Container =
    variant === "info"
      ? InfoBannerContainer
      : variant === "warning"
      ? WarningBannerContainer
      : variant === "success"
      ? SuccessBannerContainer
      : ErrorBannerContainer;

  const Icon =
    variant === "info"
      ? Info
      : variant === "warning"
      ? Warning
      : variant === "success"
      ? CheckCircle
      : WarningOctagon;

  const iconColor =
    variant === "info"
      ? theme.palette.info.main
      : variant === "warning"
      ? theme.palette.warning.main
      : variant === "success"
      ? theme.palette.success.main
      : unofficialThemeColors.red;

  return (
    <Container>
      <Box mr={2} display="flex" alignItems="center">
        <Icon size={24} color={iconColor} />
      </Box>
      {typeof content === "string" ? (
        <Typography variant="p2" color="grey.900">
          {content}
        </Typography>
      ) : (
        content
      )}
    </Container>
  );
};
