import { useTranslation } from "react-i18next";
import { ContractDetailsSectionContainer } from "../ContractDetailsSectionContainer";
import { SectionTitle } from "../SectionHeader";
import { ContractViewKeyDate } from "generated/graphql";
import React, { useCallback } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { DataGridLight } from "components/StyledDataGrid";
import { DownloadSimple } from "phosphor-react";
import { exportToExcel } from "helpers/exportToExcel";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { useColumns } from "./KeyDates.constants";

type KeyDatesSectionProps = {
  keyDates: ContractViewKeyDate[];
  contractFriendlyName: string;
};

export const KeyDatesSection: React.FC<KeyDatesSectionProps> = ({
  keyDates,
  contractFriendlyName,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = useColumns();
  const exportBtnDisabled = !keyDates.length;

  const handleExportToExcel = useCallback(() => {
    const columns = [
      { header: t("Projects.ContractDetails.number"), key: "number" },
      {
        header: t("Projects.ContractDetails.conditionToBeMet"),
        key: "conditionToBeMet",
      },
      {
        header: t("Projects.ContractDetails.originalCompletion"),
        key: "originalCompletion",
      },
      {
        header: t("Projects.ContractDetails.confirmedChange"),
        key: "confirmedChange",
      },
      { header: t("Projects.ContractDetails.confirmed"), key: "confirmed" },
      {
        header: t("Projects.ContractDetails.unconfirmedChange"),
        key: "unconfirmedChange",
      },
      { header: t("Projects.ContractDetails.unconfirmed"), key: "unconfirmed" },
    ];

    const rowsToExport = keyDates.map((keyDate) => ({
      number: keyDate.number,
      conditionToBeMet: keyDate.conditionToBeMet,
      originalCompletion: new Date(keyDate.originalKeyDate),
      confirmedChange:
        typeof keyDate.confirmedTime === "number" ? keyDate.confirmedTime : "-",
      confirmed: new Date(keyDate.confirmedKeyDate),
      unconfirmedChange:
        typeof keyDate.unconfirmedTime === "number"
          ? keyDate.unconfirmedTime
          : "-",
      unconfirmed: new Date(keyDate.unconfirmedKeyDate),
    }));

    exportToExcel(
      `${t(
        "Projects.ContractDetails.keyDatesNoCount"
      )} - ${contractFriendlyName}`,
      columns,
      rowsToExport
    );
  }, [keyDates, t, contractFriendlyName]);

  return (
    <ContractDetailsSectionContainer>
      <SectionTitle mb={2}>
        {t("Projects.ContractDetails.keyDates", {
          count: keyDates.length,
        })}
      </SectionTitle>
      <Stack direction="column" width="100%">
        <Box ml="auto" pb={1}>
          <Button
            size="small"
            sx={{
              height: "36px!important",
              px: theme.spacing(2),
              py: theme.spacing(2),
            }}
            variant="outlined"
            disabled={exportBtnDisabled}
            onClick={handleExportToExcel}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <DownloadSimple
                size={16}
                color={theme.palette.grey[exportBtnDisabled ? 500 : 800]}
              />
              <Typography
                variant="p2"
                color={exportBtnDisabled ? "grey.500" : "grey.800"}
                fontWeight="bold"
              >
                {t("common.labels.exportToExcel")}
              </Typography>
            </Stack>
          </Button>
        </Box>
        <DataGridLight
          rows={keyDates}
          columns={columns}
          getRowId={(rowData: ContractViewKeyDate) => rowData.number}
          loading={false}
          sortingMode="client"
          slots={{
            noRowsOverlay: () => <NoRowsOverlay />,
          }}
          rowSelection={false}
          disableRowSelectionOnClick
          hideFooter
          autoHeight
          stickyHeader
          // autosizeOnMount
          autosizeOnMount={false} // TODO: It seems MUI autosize not working well in "upper environemnts" - could be related to the build, node version, etc.
        />
      </Stack>
    </ContractDetailsSectionContainer>
  );
};
