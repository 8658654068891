import {
  ChangeCompanyLookupCollectionStatusMutation,
  ChangeCompanyLookupCollectionStatusMutationVariables,
  CompanyLookupCollection,
  CompanyLookupCollectionsQuery,
  CompanyLookupCollectionsQueryVariables,
} from "generated/graphql";
import { changeCompanyLookupCollectionStatusMutation } from "graphql/mutations/changeCompanyLookupCollectionStatus";
import { companyLookupCollectionsQuery } from "graphql/queries/companyLookupCollections.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash.uniqby";

const pageSize = 50;

export const useCustomSimpleLookups = () => {
  const { t } = useTranslation();
  const [fetchMoreToken, setFetchMoreToken] = useState<string>();

  const {
    data: companyLookupCollections,
    refetch: refetchCompanyLookupCollections,
    fetchMore,
    loading: companyLookupCollectionsLoading,
  } = useGraphQuery<
    CompanyLookupCollectionsQuery,
    CompanyLookupCollectionsQueryVariables
  >(companyLookupCollectionsQuery, {
    variables: { limit: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  const [
    changeCompanyLookupCollectionStatus,
    { loading: changeCompanyLookupCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeCompanyLookupCollectionStatusMutation,
    ChangeCompanyLookupCollectionStatusMutationVariables
  >(
    changeCompanyLookupCollectionStatusMutation,
    {
      update: () => {
        refetchCompanyLookupCollections();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Lookups.customLookupCollection"),
    })
  );

  const loadMore = useCallback(() => {
    fetchMore({
      variables: { limit: pageSize, nextToken: fetchMoreToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const newItems = [
          ...(prevResult.companyLookupCollections.items ?? []),
          ...(fetchMoreResult.companyLookupCollections.items ?? []),
        ];

        const uniqNewItems = uniqBy(newItems, "id");

        return {
          companyLookupCollections: {
            ...fetchMoreResult.companyLookupCollections,
            items: uniqNewItems,
          },
        };
      },
    });
  }, [fetchMore, fetchMoreToken]);

  useEffect(() => {
    setFetchMoreToken(
      companyLookupCollections?.companyLookupCollections.nextToken ?? undefined
    );
  }, [companyLookupCollections]);

  return {
    companyLookupCollections:
      (companyLookupCollections?.companyLookupCollections
        .items as CompanyLookupCollection[]) ?? [],
    loading:
      companyLookupCollectionsLoading ||
      changeCompanyLookupCollectionStatusLoading,
    hasMore: !!fetchMoreToken,
    loadMore,
    changeCompanyLookupCollectionStatus,
    refetchCompanyLookupCollections,
  };
};
