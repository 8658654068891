import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  debounce,
} from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CreateIcon } from "components/Icons/CreateIcon";
import { eventDebounceDuration } from "constants/constants";
import { EWInstructionAction } from "containers/Projects/Projects.decl";
import {
  AttachmentInput,
  AuthorizationWorkflow,
  EarlyWarningItemPreview,
  GenerateEarlyWarningPreviewMutation,
  GenerateInstructionPreviewMutation,
  InstructionItemPreview,
  ItemDataInput,
  ProductType,
} from "generated/graphql";
import { useRef, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContractBindingTypes } from "../../../../hooks/useContractBindingTypes";
import { useGenerateEWInstructionPreview } from "../../hooks/useGenerateEWPreview";
import { useRemoveEWInstructionPreview } from "../../hooks/useRemoveEWInstructionPreview";
import { FullScreenProductItemPreview } from "../../../../components/FullScreenProductItemPreview";
import {
  RecordEWInstructionConfirmModalContent,
  RecordEWInstructionConfirmModalContentProps,
} from "./RecordEWInstructionConfirmModalContent";
import {
  SendEWInstructionConfirmModalContent,
  SendEWInstructionConfirmModalContentProps,
} from "./SendEWInstructionConfirmModalContent";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import {
  InstructionProvideQuotationExtraDataForm,
  InstructionProvideQuotationExtraDataType,
} from "../../../../components/CompEvents/CompEventActionModal/components/NotifyAndRequestQuotationCEAction/InstructionProvideQuotationExtraDataForm/InstructionProvideQuotationExtraDataForm";

const TitleContent: React.FC<{
  action: EWInstructionAction;
  productType: ProductType;
}> = ({ action, productType }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const productItemName =
    productType === ProductType.EarlyWarnings
      ? t("Projects.EarlyWarnings.earlyWarning")
      : t("Projects.Instructions.instruction");

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="h3"
        fontWeight={600}
        color={theme.palette.grey[800]}
        mb={1}
      >
        {t(
          `Projects.EarlyWarnings.${
            action === EWInstructionAction.Send
              ? "SendEWInstDialog"
              : "RecordEWInstDialog"
          }.title`,
          { productName: productItemName }
        )}
      </Typography>
      <Typography variant="p1" color={theme.palette.grey[700]}>
        {t(
          `Projects.EarlyWarnings.${
            action === EWInstructionAction.Send
              ? "SendEWInstDialog"
              : "RecordEWInstDialog"
          }.subtitle`,
          { productName: productItemName }
        )}
      </Typography>
    </Box>
  );
};

// TODO: make productType accept only EW or Instructions
// TODO: split it between instruction and EW
export type NewEWInstructionItemConfirmModalProps = {
  action: EWInstructionAction;
  attachments?: AttachmentInput[];
  data: ItemDataInput;
  productInstanceId: string;
  contractId: string;
  isProvideQuotationType: boolean;
  isNEC4ECCContractType?: boolean;
  isNEC4PSCContractType?: boolean;
  onPrimaryClick: (
    recordExtraData?: RecordProductItemExtraData,
    instProvideQuotationExtraData?: InstructionProvideQuotationExtraDataType,
    authWorkflow?: AuthorizationWorkflow
  ) => void;
} & (
  | Omit<
      SendEWInstructionConfirmModalContentProps,
      "ewInstructionPreview" | "authorizationWorkflow"
    >
  | Omit<
      RecordEWInstructionConfirmModalContentProps,
      | "ewInstructionPreview"
      | "onRecordExtraDataChange"
      | "bindingTypes"
      | "onValidationChange"
    >
) &
  Omit<ActionsDialogProps, "onPrimaryClick">;

enum NewEWInstructionStep {
  InstructionProvideQuotation,
  PreviewProductItem,
}

export const NewEWInstructionItemConfirmModal: React.FC<
  NewEWInstructionItemConfirmModalProps
> = ({
  itemName,
  projectName,
  contractName,
  action,
  attachments,
  isProvideQuotationType,
  data: ewInstructionData,
  productInstanceId,
  contractId,
  isNEC4ECCContractType,
  isNEC4PSCContractType,
  productType = ProductType.EarlyWarnings,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const recordEWInstructionExtraDataRef = useRef<RecordProductItemExtraData>();
  const { bindingTypes, loading: bindingTypesLoading } =
    useContractBindingTypes(contractId);

  const [instrProvideQuotationExtraData, setInstrProvideQuotationExtraData] =
    useState<InstructionProvideQuotationExtraDataType>();
  const [step, setStep] = useState<NewEWInstructionStep>(
    isProvideQuotationType
      ? NewEWInstructionStep.InstructionProvideQuotation
      : NewEWInstructionStep.PreviewProductItem
  );

  const [ewInstructionPreview, setEwInstructionPreview] = useState<
    EarlyWarningItemPreview | InstructionItemPreview
  >();
  const [ewFullPreviewModalVisibility, setEWFullPreviewModalVisibility] =
    useState(false);
  const [recordDataValid, setRecordDataValid] = useState(false);
  const [
    instrProvideQuotationExtraDataValid,
    setInstrProvideQuotationExtraDataValid,
  ] = useState(false);

  const { generatePreview } = useGenerateEWInstructionPreview(productType);
  const { removePreview } = useRemoveEWInstructionPreview(productType);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            attachments,
            data: ewInstructionData,
            productInstanceId,
            ...(isProvideQuotationType ? instrProvideQuotationExtraData : {}),
          },
        },
      });

      if (data) {
        const castedData =
          ((data as GenerateEarlyWarningPreviewMutation | null | undefined)
            ?.generateEarlyWarningPreview as
            | EarlyWarningItemPreview
            | undefined) ??
          ((data as GenerateInstructionPreviewMutation | null | undefined)
            ?.generateInstructionPreview as InstructionItemPreview | undefined);

        setEwInstructionPreview(castedData);
      }
    }, [
      attachments,
      ewInstructionData,
      productInstanceId,
      generatePreview,
      isProvideQuotationType,
      instrProvideQuotationExtraData,
    ]),
    eventDebounceDuration
  );

  const handleRecordEWExtraDataChange = (
    recordEWInstructionExtraDataUpdated: RecordProductItemExtraData
  ) => {
    recordEWInstructionExtraDataRef.current =
      recordEWInstructionExtraDataUpdated;
  };

  const toggleFullPreviewModalVisibility = () => {
    setEWFullPreviewModalVisibility((state) => !state);
  };

  const clearModal = async () => {
    if (ewInstructionPreview?.id) {
      await removePreview({ variables: { id: ewInstructionPreview.id } });
    }
  };

  const handlePrimaryClick = () => {
    if (step === NewEWInstructionStep.InstructionProvideQuotation) {
      if (instrProvideQuotationExtraDataValid) {
        setStep(NewEWInstructionStep.PreviewProductItem);
      }
    } else {
      createProductItem();
    }
  };

  const createProductItem = () => {
    clearModal();
    if (action === EWInstructionAction.Send) {
      if (isProvideQuotationType) {
        onPrimaryClick(
          undefined,
          instrProvideQuotationExtraData,
          ewInstructionPreview?.actionMapping?.workflow
        );
      } else {
        onPrimaryClick(
          undefined,
          undefined,
          ewInstructionPreview?.actionMapping?.workflow
        );
      }
    } else {
      if (isProvideQuotationType) {
        onPrimaryClick(
          recordEWInstructionExtraDataRef.current,
          instrProvideQuotationExtraData,
          ewInstructionPreview?.actionMapping?.workflow
        );
      } else {
        onPrimaryClick(
          recordEWInstructionExtraDataRef.current,
          undefined,
          ewInstructionPreview?.actionMapping?.workflow
        );
      }
    }
  };

  const handleSecondaryClick = () => {
    if (isProvideQuotationType) {
      if (step === NewEWInstructionStep.InstructionProvideQuotation) {
        handleCancel();
      } else {
        setStep(NewEWInstructionStep.InstructionProvideQuotation);
      }
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    clearModal();
    onSecondaryClick();
  };

  const handleClose = async (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    clearModal();
    onClose?.(event, reason);
  };

  useEffect(() => {
    if (step === NewEWInstructionStep.PreviewProductItem) {
      loadPreview();
    }
    // TODO: right now we're generating a preview based on the first set of props the component receives. If it was to re-generate every time the props change
    // and knowing how React is working, it would've generated too many preview files which had to be deleted later. If previews turn out not to be accurate,
    // we should instead do a deep equality on the props and remove the old one and generate a new preview only if props are indeed different.

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [step]);

  const previewLoading =
    (step === NewEWInstructionStep.PreviewProductItem &&
      !ewInstructionPreview) ||
    bindingTypesLoading;

  const primaryBtnDisabled =
    (isProvideQuotationType &&
      step === NewEWInstructionStep.InstructionProvideQuotation &&
      !instrProvideQuotationExtraDataValid) ||
    (action === EWInstructionAction.Record && !recordDataValid) ||
    previewLoading;

  const showDateByWhichProposedInstructionMayBeGiven =
    isProvideQuotationType && (isNEC4ECCContractType || isNEC4PSCContractType);

  return (
    <>
      {step === NewEWInstructionStep.PreviewProductItem &&
        ewInstructionPreview && (
          <FullScreenProductItemPreview
            previewUrl={ewInstructionPreview?.fileUrl}
            open={ewFullPreviewModalVisibility}
            onClose={toggleFullPreviewModalVisibility}
          />
        )}
      <ActionsDialog
        iconsHeader={<CreateIcon />}
        onPrimaryClick={handlePrimaryClick}
        onSecondaryClick={handleSecondaryClick}
        onClose={handleClose}
        primaryBtnCaption={
          step === NewEWInstructionStep.InstructionProvideQuotation
            ? t("common.buttons.next")
            : t("common.buttons.send")
        }
        secondaryBtnCaption={
          step === NewEWInstructionStep.InstructionProvideQuotation
            ? t("common.buttons.cancel")
            : t("common.buttons.back")
        }
        primaryBtnDisabled={primaryBtnDisabled}
        title={<TitleContent productType={productType} action={action} />}
        content={
          previewLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {step === NewEWInstructionStep.InstructionProvideQuotation && (
                <InstructionProvideQuotationExtraDataForm
                  contractId={contractId}
                  onChange={setInstrProvideQuotationExtraData}
                  extraData={instrProvideQuotationExtraData}
                  assumptionsPlaceholder={t(
                    "Projects.CompEvents.issueInstructionProvideQuotationAssumptionPlaceholder"
                  )}
                  onFormValidationChange={
                    setInstrProvideQuotationExtraDataValid
                  }
                  showDateByWhichProposedInstructionMayBeGiven={
                    showDateByWhichProposedInstructionMayBeGiven
                  }
                />
              )}
              {step === NewEWInstructionStep.PreviewProductItem && (
                <>
                  {action === EWInstructionAction.Send ? (
                    <SendEWInstructionConfirmModalContent
                      itemName={itemName}
                      projectName={projectName}
                      contractName={contractName}
                      isProvideQuotationType={isProvideQuotationType}
                      productType={productType}
                      contractId={contractId}
                      authorizationWorkflow={
                        ewInstructionPreview?.actionMapping?.workflow
                      }
                      ewInstructionPreview={ewInstructionPreview!}
                      onPreviewClick={toggleFullPreviewModalVisibility}
                    />
                  ) : (
                    <RecordEWInstructionConfirmModalContent
                      itemName={itemName}
                      projectName={projectName}
                      contractName={contractName}
                      contractId={contractId}
                      productType={productType}
                      isProvideQuotationType={isProvideQuotationType}
                      ewInstructionPreview={ewInstructionPreview!}
                      onRecordExtraDataChange={handleRecordEWExtraDataChange}
                      onValidationChange={setRecordDataValid}
                      bindingTypes={bindingTypes}
                    />
                  )}
                </>
              )}
            </>
          )
        }
        maxWidth="sm"
        fullWidth
        {...restDialogProps}
      />
    </>
  );
};
