import { css, Link, styled } from "@mui/material";

export const NewThemeStyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "colorTone" && prop !== "noUnderline",
})<{ colorTone?: "lighter" | "darker"; noUnderline?: boolean }>(
  ({ theme, colorTone = "lighter", noUnderline = false }) => css`
    text-decoration: none;
    cursor: pointer;
    color: ${colorTone === "lighter"
      ? theme.palette.grey[500]
      : theme.palette.grey[900]};

    ${colorTone === "darker" &&
    css`
      font-weight: 600;
      ${!noUnderline &&
      css`
        text-decoration: underline;
      `};
    `}

    &:hover {
      color: ${colorTone === "lighter"
        ? theme.palette.grey[700]
        : theme.palette.grey[900]};
    }
  `
);

export const LinkV2 = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    color: ${theme.palette.primary.main};

    &:hover {
      color: ${theme.palette.primary.dark};
    }
  `
);
