import { Stack, Typography, css, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as WarningFilledSvg } from "../../../../../assets/icons/warning-filled.svg";
import { InfoBannerContainer } from "components/Banners";

// TODO: revisit if can reuse components/Banner.tsx (variant warning) cmp
const BannerContainer = styled(InfoBannerContainer)(
  ({ theme }) => css`
    background-color: ${theme.palette.warning.main};
    border: none;
  `
);

export const NotLatestRevisionBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BannerContainer>
      <WarningFilledSvg />
      <Stack direction="row" alignItems="center" ml={2}>
        <Typography variant="p2" color="grey.900">
          {t("Projects.DailyDiaries.notLatestRevisionBannerMessage")}
        </Typography>
      </Stack>
    </BannerContainer>
  );
};
