import { useApolloClient } from "@apollo/client";
import { useCallback, useContext } from "react";
import { CompEventWidgetContext } from "../../../CompEventWidget/CompEventWidget.context";

export const useOnAcceptQuotationCEActionUpdated = () => {
  const { cache } = useApolloClient();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const onUpdated = useCallback(() => {
    cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "compEventHistoryItems",
      args: { compEventId: compEvent!.id },
    });
    cache.gc();
    onChange?.();
  }, [onChange, compEvent, cache]);

  return {
    onUpdated,
  };
};
