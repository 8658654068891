import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import {
  useAttachments,
  AttachmentOperation,
} from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { NewAppPaths } from "helpers/paths/paths";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useHasAccess } from "hooks/useHasAccess";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { RiskEventDetailsHeader } from "../../components/Header/RiskEventDetailsHeader/RiskEventDetailsHeader";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";
import { useRiskDetails } from "./hooks/useRiskDetails";
import { useRiskDetailsTrackingEvents } from "./hooks/useRiskDetailsTrackingEvents";
import { IntercomEvents } from "constants/intercom";
import { useHeaderLinks } from "containers/Projects/hooks/useHeaderLinks";
import { ChangelogTitle } from "containers/Projects/components/Changelog/ChangelogTitle";

export const RiskItemDetails = () => {
  const { productInstanceId, riskItemId } = useParams();
  const visitedRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const handleNavigateToRisksRegister = useNavigateToRegisterPage();
  const location = useLocation();
  const explorerStateRef = useRef<ExplorerState | undefined>();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const {
    attachmentsContainerRef,
    commentsContainerRef,
    commentsCount,
    handleCommentsLoaded,
    hasMoreComments,
    handleAttachmentsClick,
    handleCommentsClick,
  } = useHeaderLinks();

  const { trackRiskDetailsEvent } = useRiskDetailsTrackingEvents();

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    changeRiskItemStatus,
    changeRiskItemStatusLoading,
    riskItem,
    doEditRisk,
    riskItemDataLoading,
    riskItemError,
  } = useRiskDetails(reloadChangelog);

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[],
    operation: AttachmentOperation
  ) => {
    if (!riskItem || !riskItemId) {
      return;
    }

    await doEditRisk({
      variables: {
        input: {
          id: riskItemId,
          data: riskItem.data,
          attachments: attachmentsUpdated ?? riskItem.attachments,
        },
      },
      optimisticResponse: {
        editRiskItem: {
          ...riskItem,
          __typename: "RiskItem",
          attachments:
            operation === AttachmentOperation.Delete && riskItem.attachments
              ? riskItem.attachments.filter((attach) =>
                  attachmentsUpdated.find(
                    (crtAttach) => crtAttach.id === attach.id
                  )
                )
              : riskItem.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((riskItem?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const triggerEditRiskFlow = () => {
    if (!riskItem) return;

    navigate(
      NewAppPaths.authorized.Projects.children.EditRisk.pathConstructor(
        riskItem.productInstanceId,
        riskItem.id
      ),
      {
        state: explorerStateRef.current,
      }
    );
  };

  const handleChangeRiskStatus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeRiskItemStatus({
      variables: {
        input: {
          id: riskItem!.id,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  const handleCommentAdded = (noOfMentions: number) => {
    reloadChangelog();

    riskItem &&
      trackRiskDetailsEvent(IntercomEvents.AddedComment, riskItem, {
        Product: riskItem.productInstance.product.name,
        Mentions: noOfMentions,
      });
  };

  useEffect(() => {
    if (riskItem) {
      const explorerState = {
        projectId: riskItem.productInstance.contract.project.id,
        contractId: riskItem.productInstance.contract.id,
        productId: riskItem.productInstance.product.id,
        productInstanceId: riskItem.productInstanceId,
      };
      explorerStateRef.current = explorerState;
      changeExplorerEntities(explorerState);
    }
  }, [changeExplorerEntities, riskItem]);

  useEffect(() => {
    if (location.state) {
      explorerStateRef.current = location.state as ExplorerState;
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (riskItem) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, riskItem]);

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (riskItemError && !riskItem) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [riskItemError, riskItem, navigate, clearExplorerData]);

  useEffect(() => {
    if (!visitedRef.current && riskItem) {
      trackRiskDetailsEvent(IntercomEvents.ViewedRisk, riskItem);
      visitedRef.current = true;
    }
  }, [riskItem, trackRiskDetailsEvent]);

  const isCurrentUserRiskOwner = riskItem?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserRiskOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserRiskOwner;

  const canAddComments =
    useHasAccess(undefined, [PermissionEnum.AddComment], productInstanceId!) ||
    isCurrentUserRiskOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        contractTimezone={riskItem?.productInstance.contract.timeZone}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <RiskEventDetailsHeader
            title={riskItem?.title || ""}
            itemNumber={riskItem?.number}
            statusOptionId={riskItem?.statusOptionId || ""}
            productType={ProductType.RisksRegister}
            dataLoading={riskItemDataLoading || changeRiskItemStatusLoading}
            statusOptions={
              (riskItem?.productInstance.statusCollection.statusOptions
                .items as ItemStatusOption[]) ?? []
            }
            isCurrentUserProductItemOwner={isCurrentUserRiskOwner}
            onStatusChange={
              canChangeStatus ? handleChangeRiskStatus : undefined
            }
            attachmentsCount={allAttachments.length}
            commentsCount={commentsCount}
            hasMoreComments={hasMoreComments}
            onAttachmentsClick={handleAttachmentsClick}
            onCommentsClick={handleCommentsClick}
            onEdit={triggerEditRiskFlow}
            onBack={handleNavigateToRisksRegister}
          />
          <Box mt={3}>
            {riskItemDataLoading || !riskItem ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.RisksRegister}
                schema={riskItem.productInstance.productSchema as ProductSchema}
                metadata={{
                  owner: riskItem.owner as User,
                  creator: riskItem.creator as User,
                  dateModified: riskItem.dateModified,
                  dateCreated: riskItem.dateCreated,
                  productItemId: riskItem.id,
                }}
                schemaValues={riskItem.data}
                contractCurrency={
                  riskItem.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  riskItem.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={riskItem.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgetsBottom={
                  <>
                    <Grid item xs={12} key={"attachments"} position="relative">
                      <SectionContainer ref={attachmentsContainerRef}>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          timezone={riskItem.productInstance.contract.timeZone}
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      key={"comments"}
                      position="relative"
                      ref={commentsContainerRef}
                    >
                      <SectionContainer>
                        <Comments
                          productType={ProductType.RisksRegister}
                          productItemId={riskItemId!}
                          productInstanceId={productInstanceId!}
                          timezone={riskItem.productInstance.contract.timeZone}
                          onCommentAdded={handleCommentAdded}
                          onCommentsLoaded={handleCommentsLoaded}
                          readOnly={!canAddComments}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title={<ChangelogTitle />}
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={riskItem.id}
                          productType={ProductType.RisksRegister}
                          productInstanceId={productInstanceId!}
                          contractTimezone={
                            riskItem.productInstance.contract.timeZone ?? ""
                          }
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                    {riskItem.notificationRecipients.length > 0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          initialCollapseState={true}
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                riskItem.notificationRecipients.length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={riskItem.notificationRecipients}
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
