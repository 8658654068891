import { useApolloClient } from "@apollo/client";
import { useCallback, useContext } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

export const useOnNotifyAgreementClaimActionUpdated = () => {
  const { cache } = useApolloClient();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const onUpdated = useCallback(() => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  }, [onChange, claim, cache]);

  return {
    onUpdated,
  };
};
