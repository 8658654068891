import { useGraphQuery } from "hooks/useGraphQuery";
import { contractViewQuery } from "../ContractDetails.query";
import {
  ContractViewQuery,
  ContractViewQueryVariables,
} from "generated/graphql";

export const useContractDetails = (contractId?: string) => {
  const { data: contractDetails, loading } = useGraphQuery<
    ContractViewQuery,
    ContractViewQueryVariables
  >(contractViewQuery, { variables: { id: contractId! }, skip: !contractId });

  return {
    contractDetails: contractDetails?.contractView,
    loading,
  };
};
