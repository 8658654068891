import { Box } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  AddDraftClaimAgreementInput,
  AttachmentInput,
  ClaimAgreementNoticePrompt,
  DetailedClaim,
  DraftClaimAgreement,
  EditDraftClaimAgreementInput,
} from "generated/graphql";
import { ClaimActionFormProps } from "../ClaimActionView";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import {
  ClaimResolveType,
  DetailedClaimsAgreementsDeterminationsTable,
} from "../components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { useDetailedClaimAgreementDeterminationModal } from "../../DetailedClaimAgreementClaimModal/useDetailedClaimAgreementDeterminationModal";
import {
  DetailedClaimAgreementDeterminationFormDataType,
  DetailedClaimAgreementDeterminationModal,
  ModalType,
} from "../../DetailedClaimAgreementClaimModal/DetailedClaimAgreementDeterminationModal";
import { useNotifyAgreementClaimAction } from "./useNotifyAgreementClaimAction";
import { SendAgreementClaimNoticeModal } from "./SendAgreementClaimNoticeModal";
import { RecordAgreementClaimNoticeModal } from "./RecordAgreementClaimNoticeModal";

const defaultFormData: ClaimAgreementNoticePrompt = {
  draftClaimAgreements: [], // Note: even though prompt accepts an array of ClaimAgreements, engineers will be able to add only one at a time
};

export const NotifyAgreementClaimAction: React.FC<
  ClaimActionFormProps & {
    finalDetailedClaim: DetailedClaim;
    triggersAuthWorkflow?: boolean;
  }
> = ({ apiRef, finalDetailedClaim, onClose, triggersAuthWorkflow }) => {
  const { claim, contract, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const [formData, setFormData] =
    useState<ClaimAgreementNoticePrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedDraftClaimAgreementId, setSelectedDraftClaimAgreementId] =
    useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    sendClaimAgreementNotice,
    recordClaimAgreementNotice,
    refetchPrompt,
    draftClaimAgreements: promptDraftClaimAgreements,
    loading: promptDraftClaimAgreementsLoading,
    actionLoading,
  } = useNotifyAgreementClaimAction(triggersAuthWorkflow);

  const {
    modalVisibility: claimAgreementModalVisibility,
    toggleModalVisibility: toggleClaimAgreementModalVisibility,
    loading: claimAgreementLoading,
    addDraftClaimAgreement,
    editDraftClaimAgreement,
    removeDraftClaimAgreement,
  } = useDetailedClaimAgreementDeterminationModal(refetchPrompt);

  const draftClaimAgreementIds = useMemo(
    () =>
      formData.draftClaimAgreements.map((draftAgreement) => draftAgreement.id),
    [formData]
  );

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendClaimAgreementNotice = async () => {
    await sendClaimAgreementNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          draftClaimAgreementId: draftClaimAgreementIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordClaimAgreementNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordClaimAgreementNotice({
      variables: {
        input: {
          claimId: claim?.id!,
          draftClaimAgreementId: draftClaimAgreementIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftClaimAgreements.length,
    [formData]
  );

  const handleCreateEditDraftClaimAgreement = useCallback(
    async (
      data: DetailedClaimAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftClaimAgreementData = data as
        | AddDraftClaimAgreementInput
        | EditDraftClaimAgreementInput;

      if (formData.draftClaimAgreements.length) {
        // edit mode
        const { data } = await editDraftClaimAgreement({
          variables: {
            input: draftClaimAgreementData as EditDraftClaimAgreementInput,
          },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftClaimAgreements: [
              data.editDraftClaimAgreement as DraftClaimAgreement,
            ],
          }));
        }
      } else {
        // create new draft detailed claim
        const { data } = await addDraftClaimAgreement({
          variables: { input: draftClaimAgreementData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftClaimAgreements: [
              data.addDraftClaimAgreement as DraftClaimAgreement,
            ],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleClaimAgreementModalVisibility();
      }
    },
    [
      formData,
      addDraftClaimAgreement,
      editDraftClaimAgreement,
      toggleClaimAgreementModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (formData: DetailedClaimAgreementDeterminationFormDataType) => {
      updatedAttachmentsRef.current = formData.attachments;
      const isEditMode = !!selectedDraftClaimAgreementId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftClaimAgreement(formData, true);
      }
    },
    [handleCreateEditDraftClaimAgreement, selectedDraftClaimAgreementId]
  );

  const handleClaimAgreementModalClose = () => {
    toggleClaimAgreementModalVisibility();
    setSelectedDraftClaimAgreementId(undefined);
  };

  const handleClaimAgreementRowClick = (
    draftClaimAgreement: ClaimResolveType
  ) => {
    setSelectedDraftClaimAgreementId(
      (draftClaimAgreement as DraftClaimAgreement).id
    );
    toggleClaimAgreementModalVisibility();
  };

  const handleDeleteDraftClaimAgreement = useCallback(
    (claimAgreementId: string) => {
      removeDraftClaimAgreement({ variables: { id: claimAgreementId } });
    },
    [removeDraftClaimAgreement]
  );

  const handleNewClaimAgreementClick = () => {
    setSelectedDraftClaimAgreementId(undefined);
    toggleClaimAgreementModalVisibility();
  };

  const selectedDraftClaimAgreement = useMemo(
    () =>
      formData.draftClaimAgreements.find(
        (draftClaimAgreement) =>
          draftClaimAgreement.id === selectedDraftClaimAgreementId
      ),
    [formData, selectedDraftClaimAgreementId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftClaimAgreements) {
      setFormData({
        draftClaimAgreements: promptDraftClaimAgreements,
      });
    }
  }, [promptDraftClaimAgreements]);

  return !selectedDraftClaimAgreementId &&
    (claimAgreementLoading || promptDraftClaimAgreementsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {claimAgreementModalVisibility && (
        <DetailedClaimAgreementDeterminationModal
          open={claimAgreementModalVisibility}
          type={ModalType.DraftClaimAgreement}
          draftClaimAgreement={selectedDraftClaimAgreement}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          finalDetailedClaim={finalDetailedClaim}
          maxWidth="lg"
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftClaimAgreement}
          onClose={handleClaimAgreementModalClose}
          onSecondaryClick={handleClaimAgreementModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendAgreementClaimNoticeModal
          open={sendModalVisibility}
          draftClaimAgreements={formData.draftClaimAgreements}
          onPrimaryClick={handleSendClaimAgreementNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordAgreementClaimNoticeModal
          open={recordModalVisibility}
          draftClaimAgreements={formData.draftClaimAgreements}
          onPrimaryClick={handleRecordClaimAgreementNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="ClaimAgreement"
          onAdd={handleNewClaimAgreementClick}
          disabled={!!formData.draftClaimAgreements.length}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={claimAgreementLoading}
          items={formData.draftClaimAgreements}
          type="ClaimAgreement"
          onRowClick={handleClaimAgreementRowClick}
          onDelete={handleDeleteDraftClaimAgreement}
        />
      </Box>
    </>
  );
};
