import { Box, css, styled } from "@mui/material";

export const BaseBannerContainer = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    border-radius: 12px;
    display: flex;
    align-items: center;
    min-height: 48px;
    width: 100%;
    box-sizing: border-box;
  `
);
