import { Box } from "@mui/material";
import { useSubmitInterimDetailedClaimAction } from "./useSubmitInterimDetailedClaimAction";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  AddDraftDetailedClaimInput,
  AttachmentInput,
  ClaimInterimClaimDetailsPrompt,
  DraftDetailedClaim,
  EditDraftDetailedClaimInput,
} from "generated/graphql";
import { ClaimActionFormProps } from "../ClaimActionView";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import {
  ClaimResolveType,
  DetailedClaimsAgreementsDeterminationsTable,
} from "../components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { useDetailedClaimAgreementDeterminationModal } from "../../DetailedClaimAgreementClaimModal/useDetailedClaimAgreementDeterminationModal";
import {
  DetailedClaimAgreementDeterminationFormDataType,
  DetailedClaimAgreementDeterminationModal,
  ModalType,
} from "../../DetailedClaimAgreementClaimModal/DetailedClaimAgreementDeterminationModal";
import { SendInterimDetailedClaimAction } from "./SendInterimDetailedClaimAction";
import { RecordInterimDetailedClaimAction } from "./RecordInterimDetailedClaimAction";

const defaultFormData: ClaimInterimClaimDetailsPrompt = {
  draftDetailedClaims: [], // Note: even though prompt accepts an array of DetailedClaims, users will be able to add only one at a time
};

export const SubmitInterimDetailedClaimAction: React.FC<
  ClaimActionFormProps
> = ({ apiRef, onClose }) => {
  const { claim, contract, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const [formData, setFormData] =
    useState<ClaimInterimClaimDetailsPrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedDraftClaimId, setSelectedDraftClaimId] = useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    sendInterimClaim,
    recordInterimClaim,
    refetchPrompt,
    draftDetailedClaims: promptDraftDetailedClaims,
    loading: promptDraftDetailedClaimsLoading,
    actionLoading,
  } = useSubmitInterimDetailedClaimAction();

  const {
    modalVisibility: detailedClaimModalVisibility,
    toggleModalVisibility: toggleDetailedClaimModalVisibility,
    loading: detailedClaimLoading,
    addDraftDetailedClaim,
    editDraftDetailedClaim,
    removeDraftDetailedClaim,
  } = useDetailedClaimAgreementDeterminationModal(refetchPrompt);

  const draftClaimIds = useMemo(
    () => formData.draftDetailedClaims.map((draftClaim) => draftClaim.id),
    [formData]
  );

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendInterimDetailedClaim = async () => {
    await sendInterimClaim({
      variables: {
        input: {
          claimId: claim?.id!,
          draftDetailedClaimId: draftClaimIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordInterimDetailedClaim = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordInterimClaim({
      variables: {
        input: {
          claimId: claim?.id!,
          draftDetailedClaimId: draftClaimIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftDetailedClaims.length,
    [formData]
  );

  const handleCreateEditDraftClaim = useCallback(
    async (
      data: DetailedClaimAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftClaimData = data as
        | AddDraftDetailedClaimInput
        | EditDraftDetailedClaimInput;

      if (formData.draftDetailedClaims.length) {
        // edit mode
        const { data } = await editDraftDetailedClaim({
          variables: { input: draftClaimData as EditDraftDetailedClaimInput },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftDetailedClaims: [
              data.editDraftDetailedClaim as DraftDetailedClaim,
            ],
          }));
        }
      } else {
        // create new draft detailed claim
        const { data } = await addDraftDetailedClaim({
          variables: { input: draftClaimData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftDetailedClaims: [
              data.addDraftDetailedClaim as DraftDetailedClaim,
            ],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleDetailedClaimModalVisibility();
      }
    },
    [
      formData,
      addDraftDetailedClaim,
      editDraftDetailedClaim,
      toggleDetailedClaimModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (
      detailedClaimFormData: DetailedClaimAgreementDeterminationFormDataType
    ) => {
      updatedAttachmentsRef.current = detailedClaimFormData.attachments;
      const isEditMode = !!selectedDraftClaimId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftClaim(detailedClaimFormData, true);
      }
    },
    [handleCreateEditDraftClaim, selectedDraftClaimId]
  );

  const handleDetailedClaimModalClose = () => {
    toggleDetailedClaimModalVisibility();
    setSelectedDraftClaimId(undefined);
  };

  const handleDetailedClaimRowClick = (
    draftDetailedClaim: ClaimResolveType
  ) => {
    setSelectedDraftClaimId((draftDetailedClaim as DraftDetailedClaim).id);
    toggleDetailedClaimModalVisibility();
  };

  const handleDeleteDraftDetailedClaim = useCallback(
    (detailedClaimId: string) => {
      removeDraftDetailedClaim({ variables: { id: detailedClaimId } });
    },
    [removeDraftDetailedClaim]
  );

  const handleNewDetailedClaimClick = () => {
    setSelectedDraftClaimId(undefined);
    toggleDetailedClaimModalVisibility();
  };

  const selectedDraftClaim = useMemo(
    () =>
      formData.draftDetailedClaims.find(
        (draftClaims) => draftClaims.id === selectedDraftClaimId
      ),
    [formData, selectedDraftClaimId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftDetailedClaims) {
      setFormData({
        draftDetailedClaims: promptDraftDetailedClaims,
      });
    }
  }, [promptDraftDetailedClaims]);

  return !selectedDraftClaimId &&
    (detailedClaimLoading || promptDraftDetailedClaimsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {detailedClaimModalVisibility && (
        <DetailedClaimAgreementDeterminationModal
          open={detailedClaimModalVisibility}
          type={ModalType.DraftDetailedClaim}
          draftDetailedClaim={selectedDraftClaim}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftClaim}
          onClose={handleDetailedClaimModalClose}
          onSecondaryClick={handleDetailedClaimModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendInterimDetailedClaimAction
          open={sendModalVisibility}
          draftDetailedClaims={formData.draftDetailedClaims}
          onPrimaryClick={handleSendInterimDetailedClaim}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordInterimDetailedClaimAction
          open={recordModalVisibility}
          draftDetailedClaims={formData.draftDetailedClaims}
          onPrimaryClick={handleRecordInterimDetailedClaim}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="DetailedClaim"
          onAdd={handleNewDetailedClaimClick}
          disabled={!!formData.draftDetailedClaims.length}
          isFIDIC99RedYellow={isFIDIC99RedYellow}
        />
        <DetailedClaimsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={detailedClaimLoading}
          items={formData.draftDetailedClaims}
          type="DetailedClaim"
          onRowClick={handleDetailedClaimRowClick}
          onDelete={handleDeleteDraftDetailedClaim}
        />
      </Box>
    </>
  );
};
