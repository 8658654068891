import {
  DailyDiaryPresetSection,
  DailyDiarySearchEquipmentRecord,
  DailyDiarySearchManpowerRecord,
  DailyDiarySearchWorkRecord,
} from "generated/graphql";
import { DataGridLight } from "components/StyledDataGrid";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import {
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { NoRowsOverlay } from "containers/Projects/containers/DailyDiary/components/NoRowsOverlay";
import { useTranslation } from "react-i18next";
import { useColumns } from "./ResourceSectionSearchResults.constants";
import { useResourceSectionCommon } from "containers/Projects/containers/DailyDiary/components/ManpowerSection/hooks/useResourceSectionCommon";
import { useRecordActiveAttachments } from "../../../hooks/useRecordActiveAttachments";
import { DDSection } from "containers/Projects/containers/DailyDiary/components/DailyDiaryInterpretor/DDSectionsInterpretor";
import { DailyDiaryRecordAttachmentsModal } from "containers/Projects/containers/DailyDiary/components/DailyDiaryRecordAttachmentsModal/DailyDiaryRecordAttachmentsModal";
import { toRecordJSON } from "./ResourceSectionSearchResults.utils";
import { ResultsSectionPublicApi } from "../../../DailyDiariesSearch.decl";
import { timeStripMinutes } from "containers/Projects/containers/DailyDiary/utilts";
import { datetimeToHoursAndMinutes } from "containers/Projects/containers/DailyDiary/components/ManpowerSection/ResourceRecordModal/ResourceRecordForm.utils";
import { exportToExcel } from "helpers/exportToExcel";

export type DailyDiarySearchResourceRecord =
  | DailyDiarySearchManpowerRecord
  | DailyDiarySearchEquipmentRecord;

type ResourceSectionSearchResultsProps = {
  section: DailyDiaryPresetSection;
  records: DailyDiarySearchResourceRecord[];
  sectionType: DDSection;
  loading?: boolean;
  onFetchMore: () => void;
  apiRef?: React.Ref<ResultsSectionPublicApi>;
  canExportChange: (state: boolean) => void;
};

export const ResourceSectionSearchResults: React.FC<
  ResourceSectionSearchResultsProps
> = ({
  records,
  section,
  sectionType,
  loading,
  onFetchMore,
  apiRef,
  canExportChange,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] =
    useState<DailyDiarySearchResourceRecord>();

  const {
    attachments: selectedRowAttachments,
    loading: selectedRowAttachmentsLoading,
  } = useRecordActiveAttachments(
    selectedRow?.recordId,
    selectedRow?.diaryId,
    sectionType,
    selectedRow?.diaryRevisionId
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const { withCompanyColumn, withShiftColumn, withTeamColumn } =
    useResourceSectionCommon(section);

  const handleAttachmentsClick = (rowId: GridRowId) => {
    setSelectedRow(records.find((record) => record.recordId === rowId));
  };

  const columns = useColumns({
    withShiftColumn,
    withCompanyColumn,
    withTeamColumn,
    onAttachmentsClick: handleAttachmentsClick,
  });

  const handleExportToExcel = useCallback(() => {
    const columns = [
      { header: t("common.labels.status"), key: "status" },
      { header: t("common.labels.date"), key: "date" },
      {
        header: t("Projects.DailyDiaries.Resource.resource"),
        key: "resource",
      },
      ...(withShiftColumn
        ? [{ header: t("Projects.DailyDiaries.Resource.shift"), key: "shift" }]
        : []),
      ...(withCompanyColumn
        ? [
            {
              header: t("Projects.DailyDiaries.Resource.company"),
              key: "company",
            },
          ]
        : []),
      { header: t("Projects.DailyDiaries.Resource.quantity"), key: "quantity" },
      { header: t("Projects.DailyDiaries.Resource.hours"), key: "hours" },
      {
        header: t("Projects.DailyDiaries.Resource.totalHours"),
        key: "totalHours",
      },
      { header: t("Projects.DailyDiaries.Resource.remarks"), key: "remarks" },
      ...(withTeamColumn
        ? [{ header: t("Projects.DailyDiaries.Resource.team"), key: "team" }]
        : []),
      {
        header: t("common.labels.createdBy"),
        key: "creator",
      },
      // {
      //   header: "",
      //   key: "attachments",
      // },
    ];

    const rowsToExport = records
      .filter((record) => (selectionModel || []).indexOf(record.recordId) >= 0)
      .map((record) => ({
        ...record,
        status: record.diaryStatus,
        date: record.diaryDate,
        resource: record.resource ?? "",
        shift: record.shift ?? "",
        company: record.company ?? "",
        quantity: record.quantity,
        hours: timeStripMinutes(record.hours),
        totalHours: datetimeToHoursAndMinutes(
          new Date(`2024-01-01 ${record.hours}`).toString(),
          record.quantity ?? 1
        ),
        remarks: record.remarks ?? "",
        team: record.team ?? "",
        // attachments: record.attachmentCount,
      }));

    exportToExcel(
      `${t("Projects.DailyDiariesSearch.title")}-${t(
        `Projects.DailyDiaries.sections.${sectionType}`
      )}`,
      columns,
      rowsToExport
    );
  }, [
    records,
    selectionModel,
    sectionType,
    t,
    withCompanyColumn,
    withShiftColumn,
    withTeamColumn,
  ]);

  useEffect(() => {
    canExportChange(!!selectionModel?.length);
  }, [selectionModel, canExportChange]);

  useImperativeHandle(
    apiRef,
    () => ({
      exportRecords: handleExportToExcel,
    }),
    [handleExportToExcel]
  );

  return (
    <>
      {selectedRow && (
        <DailyDiaryRecordAttachmentsModal
          open={!!selectedRow}
          loading={selectedRowAttachmentsLoading}
          attachments={selectedRowAttachments ?? []}
          onClose={() => setSelectedRow(undefined)}
          recordJSON={toRecordJSON({
            record: selectedRow,
            withCompanyColumn,
            withTeamColumn,
            withShiftColumn,
            t,
          })}
          title={t(
            `Projects.DailyDiaries.sections.${
              sectionType === DDSection.Manpower ? "manpower" : "equipment"
            }`
          )}
        />
      )}
      <DataGridLight
        rows={records}
        columns={columns}
        getRowId={(rowData: DailyDiarySearchWorkRecord) => rowData.recordId}
        loading={loading}
        sortingMode="client"
        filterMode="client"
        filterModel={filterModel}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              title={t("Projects.DailyDiariesSearch.noResults")}
              hideSubtitle
            />
          ),
        }}
        onFilterModelChange={setFilterModel}
        rowSelectionModel={selectionModel}
        autosizeOptions={{
          includeHeaders: true,
          includeOutliers: false,
        }}
        checkboxSelection
        onRowSelectionModelChange={setSelectionModel}
        onRowsScrollEnd={onFetchMore}
        rowSelection
        disableRowSelectionOnClick
        hideFooter
      />
    </>
  );
};
