import { SectionContainer } from "components/miscellaneous/SectionContainer";
import React, { ComponentProps } from "react";

export const ContractDetailsSectionContainer: React.FC<
  ComponentProps<typeof SectionContainer>
> = ({ children, ...props }) => {
  return (
    <SectionContainer sx={{ py: 2, px: 3 }} liteVariant {...props}>
      {children}
    </SectionContainer>
  );
};
