import { Grid } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import {
  CompEventItem,
  CompEventQuotationAssessment,
  CompEventRegardingType,
  CompEventStatus,
  Contract,
  EventItem,
  InstructionItem,
  ProductType,
} from "generated/graphql";
import { useCallback, useContext, useEffect, useMemo } from "react";
import {
  CompEventWidgetContext,
  CompEventWidgetContextType,
} from "./CompEventWidget.context";
import { CEWidgetLastStepSection } from "./components/CEWidgetLastStepSection/CEWidgetLastStepSection";
import { CEWidgetNextStepSection } from "./components/CEWidgetNextStepSection/CEWidgetNextStepSection";
import { ContractPriceTimeChangeSection } from "../../Widget/ContractPriceTimeChangeSection";
import { useCompEventWidget } from "./hooks/useCompEventWidget";
import { WidgetHeader } from "../../Widget/WidgetHeader";
import { CEWidgetSectionType, WidgetSection } from "../../Widget/WidgetSection";
import { WidgetEwAwSection } from "../../Widget/WidgetEwAwSection";
import { useCompEvent } from "./hooks/useCompEvent/useCompEvent";

type CompEventWidgetInnerProps = {
  previewMode?: boolean;
  compEvent?: CompEventItem;
  compEventQuotationAssessments: CompEventQuotationAssessment[];
  compEventQuotationAssessmentsLoading: boolean;
};

export type CompEventWidgetProps = {
  contract: Contract;
  compEventId?: string;
  loading?: boolean;
  projectId?: string;
  projectName?: string;
  eventItem?: EventItem;
  instructionItem?: InstructionItem;
  regardingId: string;
  onCompEventChange?: (compEvent: CompEventItem) => void;
  onChange?: () => void; // TODO: subject to name change - this callback is solely used to refetch instruction/event when CE is created. Maybe "onProductItemReloadNeeded"
} & Omit<
  CompEventWidgetInnerProps,
  | "compEventQuotationAssessments"
  | "compEventQuotationAssessmentsLoading"
  | "compEvent"
>;

const CompEventWidgetInner: React.FC<CompEventWidgetInnerProps> = ({
  previewMode,
  compEvent,
  compEventQuotationAssessments,
  compEventQuotationAssessmentsLoading,
}) => {
  const compEventSortedOut =
    compEvent &&
    [
      CompEventStatus.Rejected,
      CompEventStatus.Abandoned,
      CompEventStatus.Implemented,
    ].indexOf(compEvent.status) >= 0;
  const shallDisplayNextStepSection = !compEvent || !compEventSortedOut;

  const mostRecentQuotationAssassment = useMemo(() => {
    const assessment = compEventQuotationAssessments.find(
      (quotationAssessment) =>
        quotationAssessment.__typename === "CompEventAssessment"
    );

    // return first and only assassment or most recent quotation
    return (
      assessment ??
      compEventQuotationAssessments
        .slice()
        .filter(
          (quotationAssessment) =>
            quotationAssessment.__typename === "CompEventQuotation"
        )
        .sort((q1, q2) => q2.revision - q1.revision)[0]
    );
  }, [compEventQuotationAssessments]);

  const { contract } = useContext(CompEventWidgetContext);

  return (
    <Grid
      container
      spacing={previewMode ? 1.5 : 2}
      data-testid="ce-widget-container"
    >
      {!previewMode && compEvent && (
        <Grid item xs={12}>
          <WidgetHeader
            productType={ProductType.CompEvents}
            productItem={compEvent}
          />
        </Grid>
      )}
      {shallDisplayNextStepSection && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.NextStep}>
            <CEWidgetNextStepSection
              compEvent={compEvent}
              previewMode={previewMode}
            />
          </WidgetSection>
        </Grid>
      )}
      {compEvent && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.LastAction}>
            <CEWidgetLastStepSection
              previewMode={previewMode}
              compEvent={compEvent}
            />
          </WidgetSection>
        </Grid>
      )}
      {compEvent && compEventQuotationAssessments.length > 0 && !previewMode && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.Quotation}>
            <ContractPriceTimeChangeSection
              time={mostRecentQuotationAssassment.time}
              price={mostRecentQuotationAssassment.price}
              currency={mostRecentQuotationAssassment.currency}
              reference={mostRecentQuotationAssassment.reference}
              loading={compEventQuotationAssessmentsLoading}
              contract={contract}
              regardingItemNumber={compEvent.number}
              productType={ProductType.CompEvents}
              explorerItems={compEventQuotationAssessments}
              explorerItemsLoading={compEventQuotationAssessmentsLoading}
            />
          </WidgetSection>
        </Grid>
      )}
      {compEvent && compEvent.earlyWarningId && !previewMode && (
        <Grid item xs={12}>
          <WidgetSection type={CEWidgetSectionType.EarlyWarning}>
            <WidgetEwAwSection
              productItem={compEvent}
              contractTimezone={contract.timeZone}
            />
          </WidgetSection>
        </Grid>
      )}
    </Grid>
  );
};

export const CompEventWidget: React.FC<CompEventWidgetProps> = ({
  compEventId,
  loading,
  eventItem,
  instructionItem,
  projectId,
  projectName,
  contract,
  previewMode,
  regardingId,
  onCompEventChange,
  onChange,
}) => {
  const { compEvent, compEventLoading, refetchCompEvent } =
    useCompEvent(compEventId);
  const {
    compEventQuotationAssessments,
    compEventProductInstance,
    loading: compEventQuotationsLoading,
  } = useCompEventWidget(contract, compEventId);

  const refetchCompEventItem = useCallback(() => {
    if (compEventId) {
      refetchCompEvent({ id: compEventId });
    } else {
      onChange?.();
    }
  }, [refetchCompEvent, onChange, compEventId]);

  const compEventContextValue = useMemo(
    (): CompEventWidgetContextType => ({
      productInstanceId: compEventProductInstance?.id ?? "",
      productInstanceNumberingFormat:
        compEventProductInstance?.numberingFormat ?? "",
      recordSupported: !!compEventProductInstance?.soloModeSupported,
      contract: contract!,
      projectId: projectId ?? "",
      projectName: projectName ?? "",
      regardingType: eventItem
        ? CompEventRegardingType.Event
        : CompEventRegardingType.Instruction,
      regardingId,
      instructionItem,
      eventItem,
      compEvent,
      onChange: refetchCompEventItem,
    }),
    [
      projectId,
      projectName,
      eventItem,
      instructionItem,
      contract,
      compEventProductInstance,
      compEvent,
      regardingId,
      refetchCompEventItem,
    ]
  );

  useEffect(() => {
    if (compEvent && onCompEventChange) {
      onCompEventChange(compEvent);
    }
  }, [onCompEventChange, compEvent]);

  const showLoadingState =
    loading ||
    compEventLoading ||
    !compEventContextValue.productInstanceId ||
    !contract;

  return showLoadingState ? (
    <CenteredLoadingIndicator />
  ) : (
    <CompEventWidgetContext.Provider value={compEventContextValue}>
      <CompEventWidgetInner
        previewMode={previewMode}
        compEventQuotationAssessments={compEventQuotationAssessments}
        compEventQuotationAssessmentsLoading={compEventQuotationsLoading}
        compEvent={compEvent}
      />
    </CompEventWidgetContext.Provider>
  );
};
